import { t } from 'i18next'
import {useEffect, useState} from 'react'
import BottomNavigation from '../components/BottomNavigation'
import VideoStack from './VideoStack'
import { signOut } from '../sideEffects'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import { getFeedVideos } from '../sideEffects';
import InitialLoadingScreen from './InitialLoadingScreen'
import { getVideoInfo } from '../sideEffects'
import { setGlobalVideoStack } from '../redux/userSlice'
import store from '../redux/store'

function Entry(props) {
    const user = useSelector(state => state.user.user)
    const subscribedVideos = useSelector(state => state.user.subscribedVideos)
    const videoStack = useSelector(state => state.user.globalVideoStack)
    console.log("videoStack", videoStack)
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(0);

    const notifyEmailConfirmed = () => toast.success('E-Mail-Adresse bestätigt.', {  position: 'bottom-center',duration: 5000,});
    const { emailConfirmed } = useParams();
    useEffect(() => {
        if (emailConfirmed==="emailConfirmed") {
                notifyEmailConfirmed()
        }
    }, [ emailConfirmed ])


    // useEffect(() => {
    //     // Set up a timer that increments the "seconds" state every second (1000 milliseconds)
    //     console.log("videostack has been send")
    //   }, [videoStack]);

    useEffect(() => {
        const intialVideoAmount = 5
      async function getInitialVideos() {
        console.log("getInitialVideos called")
       const feedVideos = await getFeedVideos(intialVideoAmount)
       store.dispatch(setGlobalVideoStack(feedVideos))

       if (feedVideos.length === 0 && !user) {
        navigate("/register")
        return
       } else if (feedVideos.length === 0 && user) {
        navigate("/menu")
        return
       }

       //console.log(feedVideos)
    //    const userSubscribed = user.userSubscribed
    //    console.log(userSubscribed)
   

        //setVideoStack([...videoStack, ...feedVideos])

        // setTimeout(() => {
        //     setSeconds(1);
        //   }, 1000);
      }
      if (videoStack.length === 0) {

        getInitialVideos()
      }
      
    }, [])

    return (
        <div style={{width: "100%", height: "100%", overflow: "hidden"}}>
           {seconds === 0 && videoStack.length === 0 && <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex:999}}>
            <InitialLoadingScreen/>
            </div>}
            <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}>
            <VideoStack videoStack={videoStack} type={"feed"}/>
            </div>
        </div>
    )
}

export default Entry
