import { auth } from "./firebase"
import store from "./redux/store"
import { getOwnUser } from "./sideEffects"
import { updateScrollCounter, setCurrentVideos, setFirebaseUser, setUser, setSubscribedVideos } from "./redux/userSlice"
auth.onAuthStateChanged(function (user) {
  
  if (user) {
    
    if(user.uid) {
      console.log("user is logged in", user)
      store.dispatch(setFirebaseUser({email: user.email, uid: user.uid}))
      store.dispatch(setSubscribedVideos([]))
      getOwnUser()
      
    }
  } else {
    store.dispatch(setUser(null))
    store.dispatch(updateScrollCounter(0)) //Je nach Bedarf auskommentieren
    store.dispatch(setCurrentVideos([])) //Je nach Bedarf auskommentieren
  }
}); 