import { auth, functions, storage } from './firebase';
import { getDownloadURL, ref as ref_storage, uploadBytesResumable, uploadString } from "firebase/storage";
import { removeUser, setVideos, addVideo, updateCoins, setBoughtVideos, setUser, setCurrentVideos } from './redux/userSlice'
import { createUserWithEmailAndPassword, updateEmail, updatePassword, sendPasswordResetEmail } from "firebase/auth";
import { setStatistics } from './redux/creatorSlice';
import { setStatisticSyncDate } from './redux/creatorSlice';
import { httpsCallable } from "firebase/functions";
import moment from 'moment';
import store from "./redux/store"
import { v4 as uuidv4 } from 'uuid';
import { customHistory } from './CustomBrowserRouter';
import { addSubscribedCreator, setSubscribedVideos } from './redux/userSlice';


export function searchFor(phrase){
    return new Promise ((resolve, reject) => {
        //Search for creator
        const searchForCreator = httpsCallable(functions, 'base-searchForCreator');
        searchForCreator({phrase: phrase})
        .then((result) => {
            resolve(result)
        })
        .catch((error) => {
            reject(error)
        })
    })
}
export function registerUser(email, password) {
    return new Promise ((resolve, reject) => {

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        var userLang = navigator.language || navigator.userLanguage; 
        const sendMailToUser = httpsCallable(functions, 'emailServices-sendMailToUser');
        sendMailToUser({language: userLang, template: 'confirmationEmailTemplate', extraData: {}})
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            const sanitizedMessage = data.text;
            resolve(sanitizedMessage)
          })
        //setLoading(false)
    })  
    .catch((error) => {
        console.log("register error:", error)
        reject(error)
    });
    })
}

export function videoWatchActions(videoId) {
    // console.log(videoId)
    const reduxVideoList = store.getState().user.currentVideos;
    // console.log(reduxVideoList)
    let videoList = [...reduxVideoList]
    // console.log(videoList)
    if (!videoList.includes(videoId)) {
        videoList.push(videoId);
        store.dispatch(setCurrentVideos(videoList))
        //Sende die videoID an Firebase in watchedVideos
        const videoWatchActionsCloud = httpsCallable(functions, 'base-videoWatchActions');
        videoWatchActionsCloud({videoId: videoId, updateWatchedVideos: true})
        .then((result) => {
            // console.log("happy")
        })
    }else{
        const videoWatchActionsCloud = httpsCallable(functions, 'base-videoWatchActions');
        videoWatchActionsCloud({videoId: videoId, updateWatchedVideos: false})
        .then((result) => {
            // console.log("happy")
        })
    }
}

export function submitEmailChange(email){
    return new Promise(async (resolve, reject) => {
        updateEmail(auth.currentUser, email)
        .then(() => {
            resolve("Email updated!");
        })
        .catch((error) => {
            reject(error)
        });
    })
}

export function resetPassword(email){
    return new Promise(async (resolve, reject) => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            resolve("sent!");
        })
        .catch((error) => {
            reject(error)
        });
    })
}

export function submitPasswordChange(password){
    return new Promise(async (resolve, reject) => {
        updatePassword(auth.currentUser, password)
        .then(() => {
            resolve("Password updated!")
        }).catch((error) => {
            reject(error)
        });
    })
}

export function signOut() {
    auth.signOut()
    store.dispatch(removeUser())
}

export function uploadImage(imageURI) {
    return new Promise(async (resolve, reject) => {
        let newImageId = uuidv4()
        const storageRef = ref_storage(storage, `images/${newImageId}`);
        try {
            await uploadString(storageRef, imageURI, 'data_url')
            const downloadURL = await getThisDownloadURL(newImageId, "images")
            resolve({id: newImageId, url: downloadURL})
        }
        catch (e) {
          console.log(e); reject(e) }
      })
}

export function resendRegistrationMail() {
    return new Promise(async (resolve, reject) => {
        const extraData = {
        }
        const resendMailToUser = httpsCallable(functions, 'emailServices-resendMailToUser');
        resendMailToUser({template: 'confirmationEmailTemplate', extraData: extraData})
            .then((result) => {
                const data = result.data;
                const sanitizedMessage = data.text;
                console.log("sanitizedMessage: ", result)
                resolve(sanitizedMessage)
            })
            .catch((error) => {
                console.log("resendRegistrationMail error:", error)
                reject(error)
            });
    })
}

export async function uploadVideo(videoURI, text, previewImage, setProgress, videoInfo) {
    return new Promise(async (resolve, reject) => {
        const previewImageResult = await uploadImage(previewImage)
        const previewImageFileId = previewImageResult.id
        const previewImageDownloadURL = previewImageResult.url
        let videoFileId = uuidv4()
        const storageRef = ref_storage(storage, `videos/${videoFileId}`);
        const uploadTask = uploadBytesResumable(storageRef, videoURI);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            // Handle upload progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
            setProgress(progress)
            },
            (error) => {
            // Handle error
            console.error(error);
            },
            async () => {
            // Handle successful upload
            const downloadURL = await getThisDownloadURL(videoFileId, "videos")
            const newVideo = {videoFileId, previewImageFileId, previewImageDownloadURL, text, downloadURL, videoInfo}
            store.dispatch(addVideo(newVideo))
            const uploadVideoCloudFunction = httpsCallable(functions, 'base-uploadVideo');
            uploadVideoCloudFunction(newVideo)
                .then((result) => {
                    resolve(result.data)
                }
                )
            }
        );
    })
}

export async function deleteVideo(videoID){
    return new Promise(async (resolve, reject) => {
        console.log("videoID", videoID)
        const setVideoStatusDeleted = httpsCallable(functions, 'base-setVideoStatusDeleted');
        await setVideoStatusDeleted({videoID})
            .then((result) => {
                //REDUX
                    let videos = store.getState().user.videos;
                    let foundVideoIndex = -1;
                    let updatedVideos = [...videos];
                    
                    for (let i = 0; i < updatedVideos.length; i++) {
                        const video = updatedVideos[i];
                        if (video.id === videoID) {
                            console.log("gefunden!");
                            foundVideoIndex = i;
                        }
                    }

                    if (foundVideoIndex !== -1) {
                        const updatedVideo = { ...updatedVideos[foundVideoIndex] };
                        updatedVideo.status = 'deleted';
                        updatedVideos[foundVideoIndex] = updatedVideo;
                        store.dispatch(setVideos(updatedVideos))
                    } else {
                        console.log("Video nicht gefunden.");
                    }
                //REDUX
                resolve(result)
            })
        resolve(false)
    })
}

export async function updateVideoInfo(setProgress, videoID, videoInfo) {
    return new Promise(async (resolve, reject) => {
        let videoFileId = uuidv4()
        const storageRef = ref_storage(storage, `videos/${videoFileId}`);
        const uploadTask = uploadBytesResumable(storageRef);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            // Handle upload progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
            setProgress(progress)
            },
            (error) => {
            // Handle error
            console.error(error);
            },
            async () => {
                // Handle successful upload
                const newVideo = {videoInfo, videoID}
                // store.dispatch(addVideo(newVideo))
                const updateVideoCloudFunction = httpsCallable(functions, 'base-updateVideo');
                updateVideoCloudFunction(newVideo)
                .then((result) => {
                    //REDUX
                    let videos = store.getState().user.videos;
                    let foundVideoIndex = -1;
                    let updatedVideos = [...videos];
                    
                    for (let i = 0; i < updatedVideos.length; i++) {
                        const video = updatedVideos[i];
                        if (video.id === videoID) {
                            console.log("gefunden!");
                            foundVideoIndex = i;
                        }
                    }
                    
                    if (foundVideoIndex !== -1) {
                        const updatedVideo = { ...updatedVideos[foundVideoIndex] };
                        updatedVideo.videoInfo = videoInfo;
                        updatedVideos[foundVideoIndex] = updatedVideo;
                        store.dispatch(setVideos(updatedVideos))
                        // console.log("Geändertes Video:", updatedVideo);
                    } else {
                        console.log("Video nicht gefunden.");
                    }
                    //REDUX
                    resolve(result.data)
                }
                )
            }
        );
    })
}

export async function uploadProfileImage(imageURI) {
    //takes base 64 image and uploads it to firebase storage

    const imageId = await uploadImage(imageURI)
}

export async function uploadVideoImage(imageURI) {
    const imageId = await uploadVideoImage(imageURI)
}


export async function shareVideo(videoURI) {
    //takes video id and video info and ads to database
    const videoId = await uploadVideo(videoURI)
    //uploadsvideo
    //uploadsvideoimage
    //takes video id, imageid and video info and ads to database
    //ads video to user my videos in redux
    //die profile seite wird ganz aus dem dem user redux gespeisst
}

export function updateProfile(newUser) {
    return new Promise(async (resolve, reject) => {
         
    const updateProfileCloudFunction = httpsCallable(functions, 'base-updateProfile');
    updateProfileCloudFunction(newUser)
        .then((result) => {
        //    if (reduxUser.emailVerified && !result.data.user.userName) {
        //     history.push('/updateProfile');
        //     //navigate('/updateProfile');
        //    }
        //   
        const reduxUser = store.getState().user.user
        store.dispatch(setUser({...reduxUser, ...newUser}))          
        resolve()
        })
        .catch((error) => {
            console.log("getUserError", error)
            reject(error)
        });
    //getprofiledata updates on firestore and puts in to redux store
    })
}

export default async function getThisDownloadURL(imageId, type) {
    return new Promise(async (resolve, reject) => {
    const imageRef = ref_storage(storage, `${type}/${imageId}`);
    try {
        const imageURL = await getDownloadURL(imageRef)
        resolve(imageURL)
    } catch (e) {
        console.log(e)
        reject(e)
    }
})
}

export async function getOwnVideos() {
    const getOwnVideosCloudFunction = httpsCallable(functions, 'base-getOwnVideos');
    getOwnVideosCloudFunction()
        .then(async (result) => {
            let videos = result.data
            let reversedVideos = videos.slice().reverse()
            store.dispatch(setVideos(reversedVideos))
        })
        .catch((error) => {
            console.log("getOwnVideosCloudFunction", error)
        });
}

export async function getVideos(userId) {
    return new Promise(async (resolve, reject) => {
        const getVideosCloudFunction = httpsCallable(functions, 'base-getVideos');
        getVideosCloudFunction({userId})
            .then(async (result) => {
                let videos = result.data
                let reversedVideos = videos.slice().reverse()
                getVideoInfo(reversedVideos)
                resolve(reversedVideos)
            })
            .catch((error) => {
                console.log("getVideosCloudFunction", error)
                reject(error)
            });
        })
}

export function getOwnUser() {
    return new Promise(async (resolve, reject) => {
    //const reduxUser = store.getState().user.user
    //const navigate = useNavigate();
    //history.push('/updateProfile');

    // testCreatorFundsTransfer()
    // testPayout()

    //takes user id
    //and calls firebase callable function getUser and puts it in redux
    const getOwnUserCloudFunction = httpsCallable(functions, 'base-getOwnUser');

    getOwnUserCloudFunction()
        .then(async (result) => {
            let user = result.data.user
            store.dispatch(setUser(user))
            if (user?.emailConfirmed && user?.displayName) {
                if (user?.videos?.length > 0) {
                    getOwnVideos()
                    getBoughtVideos()
                } else {
                    store.dispatch(setVideos([]))
                }
            }
            resolve(user)
        })
        .catch((error) => {
            console.log("getUserError", error)
            reject(error)
        });
    });
}

export function getUser(userId) {
    return new Promise(async (resolve, reject) => {

    //const reduxUser = store.getState().user.user
    //const navigate = useNavigate();
    //history.push('/updateProfile');
    //takes user id
    //and calls firebase callable function getUser and puts it in redux
    const getUserCloudFunction = httpsCallable(functions, 'base-getUser');
    getUserCloudFunction({userId})
        .then(async (result) => {

            let user = result.data.user

             resolve(user)
            // store.dispatch(setUser(user))

            // getOwnVideos()

        //    console.log("getUserResult", result)
        //    if (reduxUser.emailVerified && !result.data.user.userName) {
        //     console.log("no username")
        //     history.push('/updateProfile');
        //    }
        })
        .catch((error) => {
            console.log("getUserError", error)
            reject(error)
        });
    })
}

export function getInfo() {
    //checks in my user in redux if i have enough coins //will also be checked on the server
    //takes videoid //checks if i dont already have bought this video
    //if enough coins buy function
    //result von buy sind die video infos die in das redux gespeichert werden
    //vielleicht sogar im user
    //if not enough coins getCoins function
}

export function getCoins() {
    //gets to getCoinsscreen
    //if payment is active pay()
    //addCoinsandgoback to getInfo()
    //if payment is not active go to add paymentmethod()

}

export function addPaymentMethod() {
    //ads payment method and gets back to getCoins()
}

export function increaseViewCount() {
    //if video is watched more than x seconds
    //takes video id
    //ad video to my watched videos and update redux
}

export function searchResults() {
    //take querystring und give back results
}

export function getNextFeedVideos() {
    //gives back next videos for the user and stores them in import { connect } from 'react-redux'
    //feedVideos
}

export function checkIfUsernameIsUnique() {
    //takes username
    //checks if username is unique
    //returns true or false
}

export function getFeedVideos(videoAmount) {
    let feedVideos = []
    return new Promise(async (resolve, reject) => {
    const getFeedVideosCloudFunction = httpsCallable(functions, 'base-getFeedVideos');
    getFeedVideosCloudFunction({videoAmount})
    .then(async (result) => {
        //console.log("getFeedVideosCloudFunction", result)
        let videos = result.data
        console.log("feedVideos", videos)
        // for (let i = 0; i < videos.length; i++) {
        //     const video = videos[i];
        //    const user = await getUser(video.user)
        //    video.user = user
        // }
        feedVideos = videos
        getVideoInfo(feedVideos)
        resolve(feedVideos)
        //console.log("videos", videos)
    })
    .catch((error) => { console.log("getFeedVideosCloudFunction", error); reject(error) });
})
}

export async function setPaymentMethodAsDefault(setup_intent_id) {
    const getPaymentMethodAfterSetup = httpsCallable(functions, 'base-getPaymentMethodAfterSetup');
    await getPaymentMethodAfterSetup({setup_intent_id: setup_intent_id}).then(result => {
        // console.log("IDDD", result.data.paymentMethod_id)
    }).catch(error => {
        console.error("Error:", error);
    });
}

export function setUpPaymentMethod() {
    return new Promise(async (resolve, reject) => {
    const addPaymentMethodCloudFunction = httpsCallable(functions, 'base-setUpPaymentMethod');
    addPaymentMethodCloudFunction()
    .then(async (result) => {
        const clientSecret = result.data.clientSecret
        if (clientSecret) {
            resolve({clientSecret})
        }
    }).catch((error) => reject(error))
})
}

export function buyCoins(packetId) {
    return new Promise(async (resolve, reject) => {
    const buyCoinsCloudFunction = httpsCallable(functions, 'base-buyCoins');
    buyCoinsCloudFunction({packetId})
    .then(async (result) => {
        
        console.log(result)

        if (result.data.status === "noPaymentMethod") {
            const clientSecret = result.data.clientSecret
                if (clientSecret) {
                    resolve({clientSecret})
                }
        } else if (result.data.status === "bought"){
            if (result.data.newCoins) {
                store.dispatch(updateCoins(result.data.newCoins))
            }
            resolve({status: "bought"})
        } else {
            reject(result.data)
        }
        
    }).catch((error) => console.log(error))
})
}

export function buyInfo(video, itemId) {
    return new Promise(async (resolve, reject) => {
    // console.log("CREATOR ID", video.user.id)
    const creatorId = video.user.id
    const buyInfoCloudFunction = httpsCallable(functions, 'base-buyInfo');
    buyInfoCloudFunction({videoId: video.id, itemId, creatorId})

    .then(async (result) => {
        //console.log("getFeedVideosCloudFunction", result)
        
        const newVideoInfo = result.data.videoInfo
        const boughtVideos = store.getState().user.boughtVideos
        let newBoughtVideos = []
        const videoAlreadyExistingInBoughVideosIndex = boughtVideos.findIndex((_boughtVideo) => _boughtVideo.id === video.id)
        let videoAlreadyExistingInBoughVideos = boughtVideos[videoAlreadyExistingInBoughVideosIndex]
        if (!videoAlreadyExistingInBoughVideos) {
            newBoughtVideos = [...boughtVideos, {...video, videoInfo: newVideoInfo}]
        } else {
            let _newVideoInfo = [...videoAlreadyExistingInBoughVideos.videoInfo, ...newVideoInfo]
            let _videoAlreadyExistingInBoughVideos = {...videoAlreadyExistingInBoughVideos, videoInfo: _newVideoInfo}

            newBoughtVideos = [...boughtVideos]
            newBoughtVideos[videoAlreadyExistingInBoughVideosIndex] = _videoAlreadyExistingInBoughVideos
        }
        store.dispatch(setBoughtVideos(newBoughtVideos))
        // console.log(result)
        // resolve(result.data.videoInfo)
        resolve(result.data.updatedCoins)
    }).catch((error) => console.log(error))
    })
}

export function getVideoInfo(videos) {
    return new Promise(async (resolve, reject) => {
        const user = store.getState().user.user
        if(user!=null){
            const subscribedVideos = store.getState().user.subscribedVideos

            let videoIds = []
            videos.map((x) => {
                    if (user.userSubscribed) {
                        if (x.user.id in user.userSubscribed || x.user in user.userSubscribed) {
                            // console.log(`${x.user.id} is a key in the userSubscribed object.`);
                        
                            const existingSubscribedVideo = subscribedVideos.find((_video)=> _video.id === x.id)

                            if (!existingSubscribedVideo) {
                                videoIds.push(x.id)
                            }
                            // videoIds.push(x.id)
                        } else {
                            console.log(`${x.user.id} is NOT a key in the userSubscribed object.`);
                        }
                    } else {
                        console.log("user.userSubscribed nicht vorhanden")
                    }
                })

            // console.log("CREATOR ID", video.user.id)
            // const creatorId = video.user.id
            const getVideoInfoCloudFunction = httpsCallable(functions, 'base-getVideoInfo');
            getVideoInfoCloudFunction({videoIds})

            .then(async (result) => {
                const loadedVideos = result.data
                const oldVideos = store.getState().user.subscribedVideos
                store.dispatch(setSubscribedVideos([...oldVideos, ...loadedVideos]))
                // store.dispatch(setSubscribedVideos([...loadedVideos]))


            }).catch((error) => console.log(error))
        } else {
            // NICHTS
        }
    })
}

export function createConnectedAccount() {
    return new Promise(async (resolve, reject) => {
    const createConnectedAccountCloudFunction = httpsCallable(functions, 'base-createConnectedAccount');
    createConnectedAccountCloudFunction()
    .then(async (result) => {
        resolve(result.data)

    }).catch((error) => console.log(error))
})
}

//a function which calls the firebase cloud function base-manageVideoInfo which takes the videoId and the itemId and returns the videoInfo
export function manageVideoInfo(videoId, info, action) {
    return new Promise(async (resolve, reject) => {
        const manageVideoInfoCloudFunction = httpsCallable(functions, 'base-manageVideoInfo');
        manageVideoInfoCloudFunction({videoId, action, info})
        .then(async (result) => {
            resolve(result.data.url)
        }).catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

//a function which calls the firebase cloud function base-checkAccountStatus
export function checkAccountStatus() {
    return new Promise(async (resolve, reject) => {
        const checkAccountStatusCloudFunction = httpsCallable(functions, 'base-checkAccountStatus');
        checkAccountStatusCloudFunction()
        .then(async (result) => {
            resolve(result)
    
        }).catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

export function checkIfUserProfileComplete() {
    const user = store.getState().user
    if (!user.userName || user.userName === "") {
        return false
    }
    if (!user.displayName || user.displayName === "") {
        return false
    }
    if (!user.profileImageDownloadURL || user.profileImageDownloadURL === "") {
        return false
    }
    if (!user.backgroundImageDownloadURL || user.backgroundImageDownloadURL === "") {
        return false
    }
    if (!user.dateOfBirth || user.dateOfBirth === "") {
        return false
    }
    if (!user.gender || user.gender === "") {
        return false
    }
    return true
}

export function testCreatorFundsTransfer() {
    const creatorFundsTransferCloudFunction = httpsCallable(functions, 'base-creatorFundsTransfer');
    creatorFundsTransferCloudFunction()
}

export function testPayout() {
    const testPayoutCloudFunction = httpsCallable(functions, 'base-testPayout');
    testPayoutCloudFunction()
}

export function loadAllProducts() {
    const loadAllProductsCloudFunctions = httpsCallable(functions, 'base-loadAllProducts');
    loadAllProductsCloudFunctions()
  .then((result) => {
    console.log('All products:', result.data);
  })
  .catch((error) => {
    console.error('Error loading products:', error);
  });
}

export function getPaymentMethods() {
    return new Promise(async (resolve, reject) => {
    const getPaymentMethodsCloudFunction = httpsCallable(functions, 'base-getPaymentMethods');
    getPaymentMethodsCloudFunction()
    .then(async (result) => {
        resolve(result.data)
    }).catch((error) => console.log(error))
})
}

export function detachPaymentMethod(paymentMethodId) {
    return new Promise(async (resolve, reject) => {
    const detachPaymentMethodCloudFunction = httpsCallable(functions, 'base-detachPaymentMethod');
    detachPaymentMethodCloudFunction({paymentMethodId})
    .then(async (result) => {
        resolve(result.data)

    }).catch((error) => console.log(error))
})
}

export function getBoughtVideos() {
    return new Promise(async (resolve, reject) => {
    const getBoughtVideosCloudFunction = httpsCallable(functions, 'base-getBoughtVideos');
    getBoughtVideosCloudFunction()
    .then(async (result) => {
        const boughtVideos = result.data
        console.log("boughtVideos from server", boughtVideos)
        store.dispatch(setBoughtVideos(boughtVideos))
        resolve()
    }).catch((error) => console.log(error))
})
}

export function submitCreatorApplication(application) {
    return new Promise(async (resolve, reject) => {
        const submitCreatorApplicationCloudFunction = httpsCallable(functions, 'creator-submitCreatorApplication');
        submitCreatorApplicationCloudFunction({application})
        .then(async (result) => {
            const user = store.getState().user.user
            let newUser = {...user}
            newUser.creatorStatus = "pending"
            store.dispatch(setUser(newUser))
            customHistory.replace("/creatorapplicationwaiting")
            resolve(result)
        }).catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

export function subscribeToCreator(creatorID) {
    return new Promise(async (resolve, reject) => {
        console.log("subscribeToCreator called", creatorID)
        const subscribeToCreatorCloudFunction = httpsCallable(functions, 'base-subscribeToCreator');
        subscribeToCreatorCloudFunction({
            creatorID
        })
        .then(async (result) => {
            store.dispatch(addSubscribedCreator({creatorId: creatorID, subId: result.data.id}))
            // customHistory.push("/SubscriptionThankYou")
            customHistory.replace(`/subscriptionthankyou?id=${creatorID}`);
            resolve(result)

        }).catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

export function checkUsernameAvailability(username) {
    return new Promise(async (resolve, reject) => {
        const checkUsernameAvailabilityCloudFunction = httpsCallable(functions, 'base-checkUsernameAvailability');
        checkUsernameAvailabilityCloudFunction({username})
        .then(async (result) => {
            
            resolve(result)

        }).catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

export function checkSubscriptionStatus(creator){
    return new Promise(async (resolve, reject) => {
        const userUid = auth.currentUser.uid
        const creatorUid = creator
        const getSubscriptionStatus = httpsCallable(functions, 'base-getSubscriptionStatus');
        getSubscriptionStatus({userUid, creatorUid})
        .then( async (result) => {
            resolve(result)
        }).catch((error) => {
            console.log(error)
            reject(error)
        })
    })
}

export function getCreatorName(creator){
    return new Promise(async (resolve, reject) => {
        const creatorUid = creator
        const getCreatorNameByUid = httpsCallable(functions, 'base-getCreatorNameByUid');
        getCreatorNameByUid({creatorUid})
        .then( async (result) => {
            resolve (result.data)
        }).catch((error) => {
            reject (error)
        })
    })
}

export function getSubscriptionDetails(sub){
    return new Promise(async (resolve, reject) => {
        const subId = sub
        const retrieveSubsciption = httpsCallable(functions, 'base-retrieveSubsciption');
        retrieveSubsciption({subId})
        .then( async (result) => {
            resolve (result)
        }).catch((error) => {
            reject (error)
        })
    })
}

export function deleteSubscription(sub){
    return new Promise(async (resolve, reject) => {
        const subId = sub
        const userUid = auth.currentUser.uid
        const cancelSubscription = httpsCallable(functions, 'base-cancelSubscription');
        cancelSubscription({subId, userUid})
        .then( async (result) => {
            resolve (result)
        }).catch((error) => {
            reject (error)
        })
    })
}

export function getCreatorProfile(creator) {
    return new Promise(async (resolve, reject) => {
        const creatorUid = creator;
        const getCreatorProfile = httpsCallable(functions, 'base-getCreatorProfile');
        console.log("getCreatorProfile sideffects", creatorUid)
        getCreatorProfile({ creatorUid })
            .then(async (result) => {
                console.log("getCreatorProfile", result)
                resolve(result);
            })
            .catch((error) => {
                console.log("getCreatorProfile error", error)
                reject(error);
            });
    });
}

export function getCreatorVideos(creator, lastDocId = null) {
    return new Promise(async (resolve, reject) => {
        const creatorUid = creator;
        const getCreatorVideos = httpsCallable(functions, 'base-getCreatorVideos');
        getCreatorVideos({ creatorUid, lastDocId })
            .then(async (result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getAffiliateEvents() {
    return new Promise(async (resolve, reject) => {
        const statisticSyncDate = store.getState().creator.statisticSyncDate;
        const today = moment().startOf('day');
        if (statisticSyncDate && moment(statisticSyncDate).isSame(today, 'day')) {
            console.log("getAffiliateEvents: already up to date");
            resolve();
            return;
        }
        const getAffiliateEvents = httpsCallable(functions, 'creator-getAffiliateEvents');
        getAffiliateEvents()
            .then(async (result) => {
                console.log("getAffiliateEvents", result)
                store.dispatch(setStatistics(result.data))
                store.dispatch(setStatisticSyncDate(today.format('YYYY-MM-DD')))
                resolve(result);

            })
            .catch((error) => {
                console.log("getAffiliateEvents error", error)
                reject(error);
            });
    });
}