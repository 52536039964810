import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme/Colors'
import { SpinnerCircularFixed } from 'spinners-react';

const Button = styled.button`
    background-color: ${colors.primary};
    background: conic-gradient(from 105deg at 50% 110%, ${colors.gradientStart}, ${colors.gradientEnd});
    border-radius: 50px;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.06em;
    margin: 4px 2px;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    width: 290px;
    height: 57px !important;
    user-select: none;
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
`

function StandartButton({ title, action, loading, style = {}, image=null }) {
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        { action ? <>
            <Button onClick={() => action()} disabled={loading} style={style}>
                {
                    loading ? 
                    <SpinnerCircularFixed size={27} thickness={180} speed={180} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    :
                    title
                }
                {image && <img src={image} style={{marginLeft: 12, marginBottom: 3, width: 22, transform: "rotate(0deg)"}}></img>}
            </Button>
        </> :
        <>
            <Button disabled={loading} style={style}>
                {
                    loading ? 
                    <SpinnerCircularFixed size={27} thickness={180} speed={180} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    :
                    title
                }
                {image && <img src={image} style={{marginLeft: 12, marginBottom: 3, width: 26, transform: "rotate(0)"}}></img>}
            </Button>
        </>
        }
        </div>
    )
}

export default StandartButton
