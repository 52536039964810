import React from 'react'
import Back from '../components/Back'
import { useState } from 'react';
import store from '../redux/store';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { useEffect } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { t } from 'i18next';
import StandartHeader from '../components/StandartHeader';

export default function Invoices() {
    const [invoices, setInvoices] = useState();
    const [invoicesList, setInvoicesList] = useState(false);
    const [charges, setCharges] = useState();
    const [chargesList, setChargesList] = useState();

    const custId = store.getState().user.user.stripeCustomerId
    
    useEffect(() => {
        async function getInvoices(customer_id){
            const getCustomersInvoices = httpsCallable(functions, 'base-getCustomersInvoices');
            const result_invoices = await getCustomersInvoices({customer_id});
            if(result_invoices.data){
                setInvoices(result_invoices.data.invoices)
                setInvoicesList(result_invoices.data.invoices.data)
            }else{
                setInvoices("empty")
                setInvoicesList("empty")
            }
        }
        async function getCharges(customer_id){
            const getCustomersCharges = httpsCallable(functions, 'base-getCustomersCharges');
            const result_charges = await getCustomersCharges({customer_id});
            console.log(result_charges)
            if(result_charges.data){
                setCharges(result_charges.data.charges)
                setChargesList(result_charges.data.charges.data)
            }else{
                setCharges("empty")
                setChargesList("empty")
            }
        }
        getInvoices(custId);
        getCharges(custId);
    }, [custId]);

    const convertUnixTimestampToGermanDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const day = date.getDate();
        const month = date.toLocaleString('de-DE', { month: 'long' });
        const year = date.getFullYear();
        return `${day}. ${month} ${year}`;
    }

    function formatAmount(amount) {
        const euros = (amount / 100).toFixed(2);
        const formattedAmount = euros + ' €';
        return formattedAmount;
    }

    return (
        <div style={{height: "100%"}}>
           <StandartHeader title={t("invoices")} />
            <div style={{width: "100%", display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 50}}>
                <>
                <h2>Subscriptions</h2>
                {invoicesList ? (
                    <>
                        {invoicesList==="empty" || invoicesList.length<1 ? (
                        <>
                            <p>Es wurden keine Rechnungen gefunden.</p>
                        </>
                        ):(
                        <>
                            {invoicesList.map((invoice, index) => (
                                    <a key={invoice.id} href={invoice.invoice_pdf} target="_blank">
                                        <p>
                                            {convertUnixTimestampToGermanDate(invoice.created)} |{' '}
                                            {formatAmount(invoice.amount_paid)}
                                        </p>
                                    </a>
                            ))}
                        </>
                        )}
                    </>
                ) : (
                    <>
                        <SpinnerCircularFixed size={27} thickness={180} speed={180} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </>
                )
                }
                <h2>Einzelkäufe</h2>
                {chargesList ? (
                    <>
                        {chargesList==="empty" || chargesList.length<1 ? (
                        <>
                            <p>Es wurden keine Rechnungen gefunden.</p>
                        </>
                        ):(
                        <>
                            {chargesList.some(charge => charge.invoice == null) ? (
                                chargesList.map((charge, index) => {
                                    if (charge.invoice == null) {
                                        return (
                                            <a key={charge.id} href={charge.receipt_url} target="_blank">
                                                <p>
                                                    {convertUnixTimestampToGermanDate(charge.created)} |{' '}
                                                    {formatAmount(charge.amount)}
                                                </p>
                                            </a>
                                        );
                                    }
                                    return null;
                                })
                                ) : (
                                <p>Es wurden keine Rechnungen gefunden.</p>
                            )}
                        </>
                        )}
                    </>
                ) : (
                    <>
                        <SpinnerCircularFixed size={27} thickness={180} speed={180} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </>
                )
                }
                </>
            </div>
        </div>
    )
}
