import styled from "styled-components";

export const ErrorMessage = styled.p`
    color: red;
`;

export const InputIcon = styled.div`
height: 100%;
width: 1.5rem;
display: flex;
justify-content: center;
align-items: center;
padding: 4px;
position: absolute;
box-sizing:border-box;
top:50%;
left:5px;
transform: translateY(-50%);
`
export const InputWithIconWrapper = styled.div`
position: relative;
width: 100%;
margin: 0 auto;
`
