import React, {useState, useEffect} from 'react'
import Back from '../components/Back';
import { useTranslation } from 'react-i18next';
import { getPaymentMethods, detachPaymentMethod, setUpPaymentMethod } from '../sideEffects';

import a_pay_icon from '../assets/payIcons/a_pay.png';
import amex_icon from '../assets/payIcons/amex.png';
import g_pay_icon from '../assets/payIcons/g_pay.png';
import mastercard_icon from '../assets/payIcons/mastercard.png';
import visa_icon from '../assets/payIcons/visa.png';
import paypal_icon from '../assets/payIcons/paypal.png';
import default_card_icon from '../assets/payIcons/default_card.png';
import {RxBorderDotted} from "react-icons/rx"
import FloatingButton from '../components/FloatingButton';
import { useNavigate } from 'react-router-dom'
import { SpinnerCircularFixed } from 'spinners-react';
import { FaTrashAlt } from 'react-icons/fa';
import { colors } from '../theme/Colors'; 
import StandartHeader from '../components/StandartHeader';

function PaymentMethods() {
    const { t } = useTranslation();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const addPaymentMethodClicked = () => {
      setLoading(true)
      setUpPaymentMethod().then((result) => {
        setLoading(false)
        const clientSecret = result.clientSecret
        if (clientSecret) {
          navigate('/paymentsetup', {state: {clientSecret}, returnTo: "paymentmethods"})
        }
      }).catch((error) => {
        setLoading(false)
      })

    }

    const _getPaymentMethods = async () => {
      try {
        setLoading(true);
        const result = await getPaymentMethods();
        setPaymentMethods(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
        setLoading(false);
      }
    };

    useEffect(() => {
    
       _getPaymentMethods();
      }, []);

      const handleDetachPaymentMethod = async (paymentMethodId) => {
        try {
          setLoading(true);
          const result = await detachPaymentMethod(paymentMethodId);
          _getPaymentMethods()
          
        } catch (error) {
          console.error("Error detaching payment method:", error);
          setLoading(false);
        }
      };

  
      const getCardIcon = (brand) => {
        switch (brand.toLowerCase()) {
          case "visa":
            return visa_icon;
          case "mastercard":
            return mastercard_icon;
          case "amex":
            return amex_icon;
          case "apple pay":
            return a_pay_icon;
          case "google pay":
            return g_pay_icon;
          default:
            return default_card_icon;
        }
      };

    return (
        <div style={{height: "100%"}}>
          <StandartHeader title={t("paymentMethods")} />

        {loading ?  
        
        <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <SpinnerCircularFixed color="#000000" size={50} thickness={100} speed={200} />
        </div>
          
        :

    <>
       <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: 30}}>
        {paymentMethods.map((method) => (
        <div key={method.id} style={{display: "flex", alignItems: "center", padding: 22, borderRadius: 20, boxShadow: "0 4px 60px 0 rgba(4, 6, 15, 0.05)", fontWeight: "bold", width: "80%"}}>
        <img className="paymentIcon" src={getCardIcon(method.card?.brand || method.type)} />
          {(method.card?.last4 || method.sepa_debit?.last4) &&
          <div style={{display: "flex", alignItems: "center"}}>
            <div className="cardNumberSection cardNumerDots">
            <RxBorderDotted/>
            </div>
            <div className="cardNumberSection cardNumerDots">
            <RxBorderDotted/>
            </div>
            <div className="cardNumberSection cardNumerDots">
            <RxBorderDotted/>
            </div>
            <div className="cardNumberSection">
            {method.card?.last4 || method.sepa_debit?.last4}
            </div>
          </div>
          }
          <FaTrashAlt onClick={() => handleDetachPaymentMethod(method.id)} style={{marginLeft: "auto", cursor: "pointer"}} color={colors.primary} size={16}/>
       </div>
                 ))}
              

                    {/* <>
                        <button onClick={() => detachPaymentMethod(method.id)}>Delete</button>
                    </> */}
      
        </div>
        
        {paymentMethods.length == 0 &&
                   <div style={{   display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100%', width: '100%' }} >
                   <p style={{  fontSize: '15px', fontWeight: 600, color: "gray", margin: 0, marginTop: 2 }}>{t("noPaymentMethods")}</p>
               </div>
                 }
                    <FloatingButton action={() => addPaymentMethodClicked()} />
                   
                    
                    
                 </>
                }
                </div>
    )
}
export default PaymentMethods