import React from 'react'
import { t } from 'i18next'
import StandartButton from '../components/StandartButton'
import CreatorApplicationImg2 from '../assets/creatorApplication2.png'
import BottomButtonContainer from '../components/BottomButtonContainer'
import { useNavigate } from 'react-router-dom'
// import { useLocation } from 'react-router-dom';

export default function CancelSubscriptionSuccess() {
    const navigate = useNavigate()
    // const location = useLocation();
    // const creatorId = new URLSearchParams(location.search).get("id");

    return (
        <div style={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center", padding:""}}>
            <div style={{width:"90%", height:"60%"}}>
                <img src={CreatorApplicationImg2} style={{width: 160}}/>
                <h1>Bestätigung</h1>
                <p>Dein Abo wurde gekündigt.</p>
                <BottomButtonContainer>
                    <StandartButton title={"Menu"} action={()=>navigate(`/menu`)}></StandartButton>
                </BottomButtonContainer>
            </div>
        </div>
    )
}