import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: null,
    firebaseUser: null,
    videos: [],
    globalVideoStack: [],
    currentVideos: [],
    boughtVideos: [],
    subscribedVideos : [],
    scrollCounter: 0
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { payload } = action;
            state.user = payload
        },
        setFirebaseUser: (state, action) => {
            const { payload } = action;
            state.firebaseUser = payload
        },
        removeUser: (state, action) => {
            state.user = null
            state.videos = []
            state.boughtVideos = []
            state.subscribedVideos = []
        },
        setVideos: (state, action) => {
            const { payload } = action;
            state.videos = payload
        },
        addVideo: (state, action) => {
            const { payload } = action;
            //add video to beginning of array
            let stateVideos = [...state.videos]
            stateVideos.unshift(payload)
            state.videos = stateVideos
        },
        setGlobalVideoStack: (state, action) => {
            const { payload } = action;
            state.globalVideoStack = payload
        },
        addToGlobalVideoStack: (state, action) => {
            const { payload } = action;
            state.globalVideoStack.push(payload)
        },
        setBoughtVideos: (state, action) => {
            const { payload } = action;
            state.boughtVideos = payload;
        },
        setSubscribedVideos: (state, action) => {
            const { payload } = action;
            state.subscribedVideos = payload;
        },
        updateCoins: (state, action) => {
            const { payload } = action;
            state.user.coins = payload
        },
        addSubscribedCreator: (state, action) => {
            const { payload } = action;
            const creatorId = payload.creatorId
            const subId = payload.subId
            let newUserSubscribed = {...state.user.userSubscribed}
            newUserSubscribed[creatorId] = subId
            state.user = {...state.user, userSubscribed: newUserSubscribed}
        },
        updateScrollCounter: (state, action) => {
            const { payload } = action;
            state.scrollCounter = payload;
        },
        setCurrentVideos: (state, action) => {
            const { payload } = action;
            state.currentVideos = payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setUser, setFirebaseUser, removeUser, setVideos, addVideo, setGlobalVideoStack, addToGlobalVideoStack, setBoughtVideos, setSubscribedVideos, updateCoins, addSubscribedCreator, updateScrollCounter, setCurrentVideos} = userSlice.actions

export default userSlice.reducer

//write a function 
