import React, {useState} from 'react'
import { t } from 'i18next'
import StandartButton from '../components/StandartButton'
import { subscribeToCreator } from '../sideEffects'
import BottomButtonContainer from '../components/BottomButtonContainer'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import { getCreatorName } from '../sideEffects'
import { ProfileContainerTopUserImageContainer } from './UserProfile/Style'
import { ProfileContainerTopUserImage } from './UserProfile/Style'
import { urlParser } from '../utils'
import { getUser } from '../sideEffects'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function SubscriptionIntro() {
    const [loading, setLoading] = useState(false)
    const [creatorID, setCreatorID] = useState(false)
    const [creatorName, setCreatorName] = useState(false)
    const [user, setUser] = useState(null)
    const location = useLocation();
    const navigate = useNavigate()
    const myUser = useSelector(state => state.user.user)

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        async function creatorName(creator){
            console.log("TEST1")
            const thisUser = await getUser(creator)
            setUser(thisUser)
            setCreatorName(thisUser.displayName)
            console.log("USERR", thisUser)
        }
    
        if (id) {
        //   console.log('Die ID ist:', id);
          setCreatorID(id)
          creatorName(id)
        }
    }, [location.search, creatorID]);

    async function subscribeButtonClicked() {

        setLoading(true)
        
        const result = await subscribeToCreator(creatorID)
        // const clientSecret = result.clientSecret
        // if (clientSecret) {
        //     navigate('/paymentsetup', {state: {clientSecret}, returnTo: "subscriptionintro"})
        // }
    }

    return (
        <div>
            <div style={{justifyContent:"center", width:"100%", display:"flex", height:"150px", alignItems:"end", marginBottom:"50px"}}>
                        <ProfileContainerTopUserImageContainer style={{border: '5px solid white'}} >
                            {user &&
                                <ProfileContainerTopUserImage src={urlParser(user.profileImageDownloadURL)} />
                            }
                        </ProfileContainerTopUserImageContainer>
            </div>
            <p style={{color: "#543ACC", textAlign: "center", fontFamily:"Urbanist", fontSize:"20px", fontStyle:"normal", fontWeight:"600", lineHeight:"0%"}}>Get the style of</p>
            <p style={{color: "#543ACC", fontFamily:"Urbanist", fontSize:"40px", fontWeight:"800", lineHeight:"0%"}}>{creatorName}</p>
            <div style={{width:"100%", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
                <p style={{color: "000", fontFamily:"Urbanist", fontSize:"18px", fontStyle:"normal", fontWeight:"700", lineHeight:"200%", padding: "5%"}}>Boost your style with regular persoanl tips and hidden gems from {creatorName}.</p>
            </div>
            

            <ul style={{color:"#543ACC", fontFamily:"Urbanist", fontSize:"17px", fontStyle:"normal", fontWeight:"800", lineHeight:"293%", textAlign:"start", padding: "5%", marginLeft:"5%"}}>
                <li>All Outfits included (past & future)</li>
                <li>Detailed Newsletter on every new drop</li>
                <li>Support {creatorName}</li>
                <li>Cancel any time</li>
            </ul>

            <BottomButtonContainer>
                {myUser==null ? <><StandartButton title={"Subscribe"} action={() => {navigate("/register")}} loading={loading} ></StandartButton></>:<><StandartButton title={"Subscribe"} action={() => {subscribeButtonClicked()}} loading={loading} ></StandartButton></>}
                
            </BottomButtonContainer>
        </div>
    )
}

export default SubscriptionIntro
