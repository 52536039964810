import i18next from "i18next";
import { en, de } from "./translations"

const resources = {
    en: {
        translation: en,
    },
    de: {
        translation: de,
    }
};
i18next.init({
    resources,

    //language to use if translations in user language are not available
    lng: "de",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
});

export default i18next