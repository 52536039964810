import React from 'react';
import { useLocation } from 'react-router-dom';
import { getSubscriptionDetails } from '../sideEffects';
import { useEffect } from 'react';
import { useState } from 'react';
import { deleteSubscription } from '../sideEffects';
import { useNavigate } from 'react-router-dom';
import Back from '../components/Back';
export default function SubscriptionInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sub = searchParams.get('sub');
  const name = searchParams.get('name');

  const [nextPaymentDay, setNextPaymentDay] = useState(false)
  const [cancleSubday, setCancleSubday] = useState(false)
  const [subscription, setSubscription] = useState(false)

  useEffect(() => {
    if(sub){
        async function getSubscription(){
            const result = await getSubscriptionDetails(sub)
            console.log(result.data)
            setSubscription(result)
            if(result.data.cancel_at_period_end){
              setCancleSubday(unixZeitstempelInDeutschesDatum(result.data.cancel_at))
            } else {
              setNextPaymentDay(unixZeitstempelInDeutschesDatum(result.data.current_period_end))
            }
        }
        getSubscription()
    }
    }, [sub])

    function unixZeitstempelInDeutschesDatum(unixZeitstempel) {
        // Erstelle ein neues Date-Objekt mit dem Unix-Zeitstempel
        const datum = new Date(unixZeitstempel * 1000); // Unix-Zeitstempel ist in Sekunden, Date erwartet Millisekunden
      
        // Holen Tag, Monat und Jahr aus dem Datum
        const tag = datum.getDate();
        const monat = datum.getMonth() + 1; // Monate werden von 0 bis 11 gezählt, daher +1
        const jahr = datum.getFullYear();
      
        // Gib das Datum im deutschen Format zurück (TT/MM/JJJJ)
        const deutschesDatum = `${tag}.${monat}.${jahr}`;
      
        return deutschesDatum;
      }

      async function cancelSub(sub){
        await deleteSubscription(sub)
        .then( async (result) => {
          console.log("GELÖSCHT 2")
          navigate("/cancel-subscription-success")
      }).catch((error) => {
          console.log("FEHLER", error)
      })
      }

  return (
    <>
      <div style={{ height: "100%", display: "flex", width: "100%", flexDirection: "column", justifyContent:"center", alignItems:"center"}}>
      <Back/>
        <h1>Subscription {name}</h1>
        <div style={{height:"100%", width:"80%", justifyContent:"center"}}>
          <div style={{color:"var(--greyscale-900, #212121);", fontFamily:"Urbanist", fontSize:"20px", fontStyle:"normal", fontWeight:"700", lineHeight:"120%", display:"flex", justifyContent:"start"}}>
            {cancleSubday ? (
              <p>ends on {cancleSubday}</p>
            ):(
              <p>renews on {nextPaymentDay}</p>
            )
            }
          </div>
          <div style={{color:"var(--greyscale-900, #212121);", fontFamily:"Urbanist", fontSize:"20px", fontStyle:"normal", fontWeight:"600", lineHeight:"120%", display:"flex", justifyContent:"center"}}>
            {cancleSubday ? (
                <p></p>
              ):(
                <p onClick={() => cancelSub(sub)}>Cancel subscription </p>
              )
              }
            </div>
        </div>
      </div>
    </>
  );
}