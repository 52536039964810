
import React, {useState} from 'react'
import { FaAngleRight } from 'react-icons/fa';
import BuyButton from './BuyButton';
import { STANDARD_ITEM_PRICE } from '../constants';

const InnerOutfitItem = ({item, video, foundBoughtVideoInfo, setShowAllBuyButton}) => {

    // const [purchaseState, setPurchaseState] = useState(item.name || item.name === ""  ? true : false)
    // const [extraInfo, setExtraInfo] = useState(_extraInfo)
    const [loading, setLoading] = useState(true)
    return (
      //  <a href={foundBoughtVideoInfo?.link ? foundBoughtVideoInfo.link : ""} target="_blank" style={{textDecoration: "none", color: "black", width: "100%"}}>
        <div style={{width: "100%", paddingLeft: 10, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <div>
            <div style={{width: "100%"}}>
            {/* <img src={h_and_m} style={{width: 20, height: 20, objectFit: "contain", marginRight: 10}}/> */}
            {item.typeOfCloth && <p style={{fontWeight: "bold", textAlign: "left", marginBottom: 6}}>{item.typeOfCloth}</p>}
            <p style={!foundBoughtVideoInfo ? {filter: "blur(4px)", margin: 0} : {color: "gray", margin: 0}}>{!foundBoughtVideoInfo ? "akd234zl34 " : foundBoughtVideoInfo.codeNumber + " | " + foundBoughtVideoInfo.brand + " | " + foundBoughtVideoInfo.size }</p>
          </div>
        </div>
        {!foundBoughtVideoInfo ?
          /* onResolve={(_data)=>{setExtraInfo(_data); setPurchaseState(true); setShowAllBuyButton(false)}} */
          <div style={{width: 100}}>
            <BuyButton price={STANDARD_ITEM_PRICE} title={`${STANDARD_ITEM_PRICE} Coin`} style={{padding: 0}} video={video} videoInfoItemId={item.id}  /> 
          </div> :
          <FaAngleRight color='black' size={16} />
        }  
        </div>
      // </a>
    )
}



const OutfitItem = (props) => {

    // const [purchaseState, setPurchaseState] = useState(item.name || item.name === ""  ? true : false)
    // const [extraInfo, setExtraInfo] = useState(_extraInfo)
    const [loading, setLoading] = useState(true)
    const foundBoughtVideoInfo = props.foundBoughtVideoInfo



    return (
      <>
      {foundBoughtVideoInfo?.shopLink ?        
        <a href={foundBoughtVideoInfo.shopLink} target="_blank" style={{textDecoration: "none", color: "black", width: "100%"}}>
          <InnerOutfitItem {...props}/>
        </a>
        :
        <InnerOutfitItem {...props}/>
      }
      </>
    )
}

export default OutfitItem