import { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { setUser } from '../redux/userSlice'
import { useTranslation } from "react-i18next";
import StandartButton from '../components/StandartButton';
import StandartEmailInput from '../components/StandartEmailInput';
import StandartPasswortInput from '../components/StandartPasswortInput';
import { ErrorMessage } from '../components/StandartStyles';
import { getOwnUser } from '../sideEffects';

function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)  
    const [loading, setLoading] = useState(false)

    const input2Ref = useRef(null);

    async function login() {
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in 
                const user = userCredential.user;
                
                try {
                    await getOwnUser()
                    setLoading(false)
                    navigate("/")
                } catch (error) {
                    setLoading(false)
                    console.log(error)
                }
                //setUser(user.email)

                //navigate("/") 
            })
            .catch((error) => {
                setLoading(false)
                setErrorMessage(t(error.code))
            });
    }

    const handleSubmit = (e, inputIndex) => {
        e.preventDefault();
    
        switch (inputIndex) {
          case 1:
            input2Ref.current.focus();
            break;
          case 2:
            login()
            break;
          default:
            break;
        }
      };

    return (
        <div style={{position: "fixed", zIndex:"1001", backgroundColor:"white", zIndex:"9001"}} className="loginContainer">
            <div className="loginCenter">
                <h2>{t("login")}</h2>
                <div>
                    <StandartEmailInput handleChange={setEmail} value={email} tabIndex="0" onKeyDown={(e) => ['Enter', 'Tab'].includes(e.key) && handleSubmit(e, 1)}/>
                </div>
                <div>
                    <StandartPasswortInput handleChange={setPassword} value={password}   tabIndex="1" onKeyDown={(e) => ['Enter'].includes(e.key) && handleSubmit(e, 2)} ref={input2Ref} enterKeyHint={"done"}/>
                </div>
                <div>
                    <p> <a onClick={()=>navigate("/reset-password")} className="registerLoginLink">{t("forgotPassword")}</a> </p>
                </div>
                <div>
                    <StandartButton title={t("signIn")} action={login} loading={loading} />
                </div>
                <div>
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </div>
                <p>{t("dontHaveAnAccount")} <a onClick={()=>navigate("/register")} className="registerLoginLink">{t("signUp")}</a> </p>
            </div>
        </div>
    )

}

export default Login
