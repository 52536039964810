import React, { useState, useEffect, useRef, useCallback } from 'react'
import { createRoot } from 'react-dom/client';
import { urlParser } from '../utils'
import store from '../redux/store'
import VideoCard from '../components/VideoCard';
//import { useNavigate, useLocation } from 'react-router-dom'
import StandartButton from '../components/StandartButton';

import { Provider } from 'react-redux'
import { useSelector } from 'react-redux';
import { updateScrollCounter } from '../redux/userSlice';
import { videoWatchActions } from '../sideEffects';

function getTranslateY(myElement) {
  var style = window.getComputedStyle(myElement);
  var matrix = new DOMMatrix(style.transform);
  return matrix.m42;
}

const slideTime = 0.4

let dragStartY = 0;
let dragging = false;
let currentOffset = 0;

let direction = null

let preparedWindows = 2

let scrollLevel = preparedWindows * (-100)

let animationInProgress = false

let contentWindowsAmount = 2 * preparedWindows + 1

const targetWindow = preparedWindows + 1

let contentPosition = 0

let videosPaused = false

//let setUpComplete = false

function renderVideoReactComponent(parent, video, type) {
  createRoot(parent).render(
    <Provider store={store}>
  <VideoCard inFous={false} video={video} type={type}/>
  </Provider>
  );
}

function VideoStack(props) {
  const [firstCounterSet, setFirstCounterSet] = useState(true)

  const increaseScrollCounter = useSelector((state) => {
    const scrollCount = state.user.scrollCounter

    const newScrollCount = scrollCount + (1)
    return newScrollCount;
  });

      const videoStack = props.videoStack
    if (props.contentPosition) {
      contentPosition = props.contentPosition
    }

    //const [setUpComplete, setSetUpComplete] = useState(false)

    //const navigate = useNavigate()
   // const currentLocation = useLocation()

    // useEffect(() => {
    //   // const unlisten = currentLocation.listen((location, action) => {
    //   //   console.log("History changed:", action, location.pathname, location.state);
    //   // });
  
    //   // // Clean up when the component is unmounted or when history changes
    //   // return () => {
    //   //   unlisten();
    //   // };
    //   console.log("currentLocation", currentLocation)
    // }, [currentLocation]);
  

    const boxContainerRef = useRef(null);

  //   function testNavigation() {
  //     navigation.navigate("/menu")
  //  }

    useEffect(() => {
      //Updated scrollCounter +1 (redux) at page reload or new visit.
      if(firstCounterSet){
        setFirstCounterSet(false)
        const result =  increaseScrollCounter
        store.dispatch(updateScrollCounter(result))
      }

    //  if (setUpComplete) {
    //   return
    //  }
    if (document.querySelector('.boxContainer').children.length > 0) {
      return
    }

    if (videoStack.length > 0 && boxContainerRef) {
      const boxContainer = boxContainerRef.current

      boxContainer.style.transition = `none`
      boxContainer.style.transform = `translateY(${scrollLevel}%)`
      boxContainer.style.transition = `transform 0.5s;`

      for (let i = 0; i < contentWindowsAmount; i++) {
        //inFocus={i === currentIndex}
        //let videoCard = <VideoCard video={videoStack[i]} muted={true || preparedWindows ? true : false} videoId={videoStack[i].id} url={videoStack[i].downloadURL} index={i} user={videoStack[i].user} key={"videoKey"+i} />;
        //console.log("!!!---videoCard after", videoCard)
        const newDiv = document.createElement("div");
        newDiv.classList.add("box")

        //const video = document.createElement('video');
        const videoIndex = contentPosition + i-preparedWindows
        // console.log("videoIndex 🏆", videoIndex)
        // video.src = urlParser(videoStack[videoIndex].downloadURL);
        // video.autoplay = true;
        // //video.preload = "auto"
        // video.loop = true;
        // if (i == preparedWindows) {
        //   video.muted = true;
        // }
        // video.muted = i == preparedWindows ? true : false;
        // video.playsInline = true;
        // video.classList.add("box-video")

        // //newDiv.innerHTML = `<button onclick="navigateTo('/menu')">🔊</button>`
        // newDiv.appendChild(video)
       // 
        //videoCards.push(videoCard)

        // const _container = document.createElement('div');
        // _container.classList.add("box")
        if (videoIndex>=0) {
        //createRoot(newDiv).render(<VideoCard inFous={false} video={videoStack[videoIndex]} type={props.type}/>);
        renderVideoReactComponent(newDiv, videoStack[videoIndex], props.type)
        //newDiv.appendChild(newDiv)
        }

        boxContainer.appendChild(newDiv)
        //videoRefs.current = Array(videoCards.length).fill().map((_, i) => videoRefs.current[i] || React.createRef());

        //setVideoCards(videoCards)

      }
      //setSetUpComplete(true)
     // setUpComplete = true
    }

    }, [videoStack])



    // useEffect(() => {
    //     if (videoStack.length > 0) {
    //         setShownVideos(videoStack.slice(0, 5))
    //     }
    // }, [videoStack])


    function unMuteNextVideos() {

      const boxes = document.getElementsByClassName('box');
      
      let nextBox = boxes[preparedWindows+1]
      let nextVideo = nextBox.getElementsByTagName('video')[0];
      let currentBox = boxes[preparedWindows]

      if (nextVideo) {
        nextVideo.muted = false;
      }
     
      let currentVideo = currentBox.getElementsByTagName('video')[0];
      let currentVideoId = currentVideo.getAttribute('data-id')

  
      videoWatchActions(currentVideoId)



      if (nextVideo) {
          nextVideo.play().then(() => { 
          nextVideo.pause();
          
          nextVideo.autoplay = true;
        }).catch((error) => { console.log("error", error) })
      }
     
      nextBox = boxes[preparedWindows-1]
      nextVideo = nextBox.getElementsByTagName('video')[0];
      if (nextVideo) {
        nextVideo.muted = false;
      }
     
      //nextVideo.play()

      if (nextVideo) {
          nextVideo.play().then(() => { 
          nextVideo.pause();
          
          nextVideo.autoplay = true;
        }).catch((error) => { console.log("error", error) })
      }
    



      //nextVideo.muted = false;
     // nextVideo.pause();

    //   for (let i = 0; i < videos.length; i++) {
    //     if (i == preparedWindows) {
    //       continue
    //     }
    //     //videos[i].play();
    //     //videos[i].autoplay = true;
    //     videos[i].play();
    //     videos[i].muted = false;
    //     videos[i].pause();
    //     //videos[i].play();
    // }
    }

    function pauseAllVideos() {
      const videos = document.getElementsByTagName('video');
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
    }
    }

    function pauseLastVideo() {
      const videos = document.getElementsByTagName('video');
      //console.log("videos[targetWindow-1]", videos[targetWindow-1])
      if (direction == "next") {

        videos[targetWindow-1].pause();
      } else if (direction == "previous") {
        videos[targetWindow+1].pause();
      }
    }

    // function unMuteNextVideo(){
    //   const videos = document.getElementsByTagName('video');
    //   //console.log("videos[targetWindow-1]", videos[targetWindow-1])
    //   if (direction == "next") {

    //     videos[targetWindow-1].muted = false;
    //     videos[targetWindow-1].play().then(() => { 
    //       videos[targetWindow-1].pause();
                
    //             nextVideo.autoplay = true;
    //           }).catch((error) => { console.log("error", error) })
    //     videos[targetWindow-1].pause();
    //   } else if (direction == "previous") {
    //     videos[targetWindow+1].muted = false;
    //     videos[targetWindow+1].play().then(() => { 
    //       videos[targetWindow+1].pause();
          
    //       nextVideo.autoplay = true;
    //     }).catch((error) => { console.log("error", error) })
    //   }
    // }

    function playCurrentVideo(sound) {
      const boxContainer = boxContainerRef.current
      let currentlyShownBox = boxContainer.children[targetWindow-1];
      const currentlyShownVideo = currentlyShownBox.querySelector("video")
   
      currentlyShownVideo.play()
      currentlyShownVideo.muted = false
    return


    // //   const videos = document.getElementsByTagName('video');
    // //   for (let i = 0; i < videos.length; i++) {
    // //     videos[i].pause();
    // // }

    //   const boxContainer = boxContainerRef.current

    //   let currentlyShownBox
    //   // Get the third child using the children property
    //   let videoIndex 
    //   if (sound) {
    //     videoIndex = preparedWindows
    //   } else {
    //     if (direction == "next") {
    //       videoIndex = preparedWindows+1
    //     } else if (direction == "previous") {
    //       videoIndex = preparedWindows-1
          
    //     }
    //   }

    //   console.log("videoIndex 🎃", videoIndex)

    //   if (videoIndex < 0 || videoIndex > videoStack.length-1) {
    //     return
    //   }

    //  currentlyShownBox = boxContainer.children[videoIndex];
    //  const currentlyShownVideo = currentlyShownBox.querySelector("video")

    //  if (!currentlyShownVideo) {
    //     return
    //   }
    //  // console.log("currentlyShownVideo 🎃", currentlyShownVideo)
      
    //  //currentlyShownVideo.click()


    //   currentlyShownVideo.muted = false
    //   //currentlyShownVideo.autoplay = true
      
    //   // if (sound) {
    //   //   currentlyShownVideo.muted = false
    //   // } else {
    //   //   if (!muted) {
    //   //     //currentlyShownVideo.autoplay = false
    //   //     currentlyShownVideo.muted = false
    //   //     console.log("i am not muted")
    //   //   } else {
    //   //     currentlyShownVideo.muted = muted

    //   //   }
    //   // }
    //   //currentlyShownVideo.muted = false



    //   currentlyShownVideo.play()

    }

    const handleTransitionEnd = (e) => {
      const target = e.target
      if (!(target && target.tagName === 'DIV' && target.classList.contains('boxContainer'))) {
        return
      }
      // Your transition end logic here
      animationInProgress = false

  if (direction != "next" && direction != "previous") {
    return
  }

let videoUrl
let preparedVideoIndex
let video

if (direction == "next") {
      contentPosition += 1
    scrollLevel += 100
    preparedVideoIndex = contentPosition + preparedWindows
  }
  
else if (direction == "previous") {
   contentPosition -= 1
    preparedVideoIndex = contentPosition - preparedWindows
    
    scrollLevel -= 100
}

// const newDiv = document.createElement("div");
// newDiv.classList.add("box", "dssdfsd")

const _container = document.createElement('div');
  _container.classList.add("box")

if (preparedVideoIndex >= 0 && preparedVideoIndex < videoStack.length) {
  renderVideoReactComponent(_container, videoStack[preparedVideoIndex], props.type)

}


if (direction == "next") {
  const parent = document.querySelector(".boxContainer");
  const firstBox = parent.querySelector(".box");  
  

    parent.removeChild(firstBox);
    parent.appendChild(_container)
    //parent.appendChild(newDiv)
    //setVideoCards([...videoCards, video])
    //console.log("videoStackstackstack", videoStack)
    //let videoCard = <VideoCard video={urlParser(videoUrl)} muted={true} videoId={videoStack[1].id} url={videoStack[1].downloadURL} index={1} user={videoStack[1].user} key={"videoKey"+1} />;
    //let newVideos = videoCards.slice(1)

    //setVideoCards(newVideos)
} else if (direction == "previous") {
   const parent = document.querySelector(".boxContainer");
   const lastBox = parent.querySelector(".box:last-child");

  parent.removeChild(lastBox);
  parent.insertBefore(_container, parent.firstChild);
}

boxContainerRef.current.style.transition = "none";
boxContainerRef.current.style.transform = `translateY(${scrollLevel}%)`

//pauseLastVideo()
pauseAllVideos()
playCurrentVideo()
    }

    function handleDragStart(e) {
     
      //videosPaused = false
      if (animationInProgress) {
        return
      }
       dragging = true;
       //dragStartY = e.clientY;
        if (e.type === "touchstart") {
            dragStartY = e.touches[0].clientY;
        } else if (e.type === "mousedown") {
            dragStartY = e.clientY;
        } else {
            return;
        }

        currentOffset = 0;
    
      boxContainerRef.current.style.transition = "none";
    
      currentOffset = getTranslateY(boxContainerRef.current);
    
      // slides.forEach((slide) => {
      //     slide.style.transition = "none";
          
      //     currentOffsets.push(getTranslateY(slide));
      //   });
     unMuteNextVideos()
    // unMuteNextVideo()
    }

    function handleDragMove(e) {
      if (animationInProgress) {
        return
      }
      
      //unMuteAllVideos()

      // if (!videosPaused) {
      //   pauseAllVideos()
      // }
     
        if (dragging) {
            const dragDelta = e.type === "touchmove" ? e.touches[0].clientY - dragStartY : e.clientY - dragStartY;
    
            if (dragDelta > 0 && !(contentPosition > 0) ) {
              return
            } else if (dragDelta < 0 && !(contentPosition < videoStack.length - 1)) {
              return
            }

            const currentTranslateY = currentOffset;
            const newTranslationY = currentTranslateY + dragDelta;
            boxContainerRef.current.style.transform = `translateY(${newTranslationY}px)`;
            //console.log(dragDelta);
        }
        
    }

    function handleDragEnd(e) {
     // unMuteNextVideos()
     // playCurrentVideo()
      if (animationInProgress) {
        return
      }
        dragging = false;
        const dragDelta = e.type === "touchend" ? e.changedTouches[0].clientY - dragStartY : e.clientY - dragStartY;
        
        if (dragDelta > 0) {
            scrollToPreviousVideo()
        } else if (dragDelta < 0) {
           scrollToNextVideo()
        }
       
        //playCurrentVideo()
    }

    async function scrollToNextVideo() {
      if (contentPosition < videoStack.length - 1) {
        if (animationInProgress) {
          return
        }
        animationInProgress = true
        direction = "next"
        boxContainerRef.current.style.transition = `transform ${slideTime}s`;
        scrollLevel -= 100
        boxContainerRef.current.style.transform = `translateY(${scrollLevel}%)`
        // console.log("SCROLL")
        const result = await increaseScrollCounter;
        store.dispatch(updateScrollCounter(result));
      } else {
        console.log("end reached")
      }
    }
    
    function scrollToPreviousVideo() {
      if (contentPosition > 0 ) {
        if (animationInProgress) {
    return
    }
    animationInProgress = true
    direction = "previous"
    
      boxContainerRef.current.style.transition = `transform ${slideTime}s`;
      scrollLevel += 100
      boxContainerRef.current.style.transform = `translateY(${scrollLevel}%)`
      } else {
        console.log("top reached")
      }
    }

    return (
      <>
        <div className="videoSliderwrapper"
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}

        onMouseMove={handleDragMove}
        onTouchMove={handleDragMove}

        onMouseUp={handleDragEnd}
        onTouchEnd={handleDragEnd}

        onTransitionEnd={handleTransitionEnd}

        >
            <div className="boxContainer"
              ref={boxContainerRef}
            >
            </div>
        </div>
      
      {/* <button id="playButton" onClick={() => {muted = false; playCurrentVideo(true)}} style={{position: "absolute", bottom: 20, right: 20}}>Play Sound</button> */}
      </>
    )


}

export default VideoStack
