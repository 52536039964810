import React from 'react'
import Back from './Back'

function StandartHeader({children, ...props}) {

    const {back=true, title} = props

    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", position: "relative"}}>
           {back && <Back/>}
              <h2 style={{fontSize: "1.8rem"}}>{title}</h2>
                {children}
          
        </div>
    )
}

export default StandartHeader




