import React from 'react'
import StandartInput from '../components/StandartInput';
import { TextInput, CloseButton } from'@mantine/core';
import { useEffect } from 'react';
import { useState } from 'react';
import '@mantine/core/styles.css';
import Back from '../components/Back';
import { searchFor } from '../sideEffects';
import { Navigate, useNavigate } from 'react-router-dom';

export default function SearchPage() {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [loading, setLoading] = useState(null)
  const [searchResult, setSearchResult] = useState(null)

  const navigate = useNavigate()
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (searchPhrase) {
        setLoading(true)
        //call sideFunction to search
        //display result
        const result = await searchFor(searchPhrase)
        setSearchResult(result.data)
        setLoading(false)
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchPhrase])

  function clearSearch(){
    setSearchPhrase('')
    setSearchResult(null)
  }

  const handleDivClick = (userId) => {
    navigate(`/userProfile/${userId}`);
  };
  
  return (
    <>
    <div style={{height: "100%", display: "flex", width: "100%", flexDirection: "column"}}>
      <div style={{display: "flex", flexDirection: "row"}}>
        <div style={{width:"70px", justifyContent:"center", alignItems:"center"}}>
          <Back/>
        </div>
          <TextInput placeholder="Search" style={{width: "75%", marginTop: 23, marginBottom:"20px"}} 
          value={searchPhrase} onChange={(event) => setSearchPhrase(event.currentTarget.value)}
          radius="lg"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => clearSearch()}
              style={{ display: searchPhrase ? '' : 'none' }}
            />
          }
          />
      </div>
      
      { searchResult == null ? <></>
      :
      <>
        {searchResult.length === 0 ? (
          <p>Keine Benutzer gefunden.</p>
        ) : (
            <>
            <div style={{display: "flex", flexDirection:"column", gap:"10px", width:"100%", justifyContent:"center"}}>
              {searchResult.map((user) => (
                <>
                <div key={user.userId} style={{display: "flex", flexDirection:"row", height:"60px", width:"100%", justifyContent:"center"}} onClick={() => handleDivClick(user.userId)}>
                  <div style={{display: "flex", width:"90%", height:"100%"}}>
                    <div>
                      <img
                        src={user.profileImageDownloadURL}
                        alt={user.displayName + "'s Profile Image"}
                        style={{ borderRadius: '50%', width: '60px', height: '60px', objectFit:"cover", position:"relative" }}
                      />
                    </div>
                    <div style={{display:"flex", flexDirection:"column", marginLeft:"20px", height:"100%", justifyContent:"center"}}>
                      <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start", color:"#212121", fontFamily:"Urbanist", fontStyle:"normal", fontWeight:"700", fontSize:"17px"}}>
                        {user.userName}
                      </div>
                      <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start", color:"#212121", fontFamily:"Urbanist", fontStyle:"normal", fontWeight:"500", fontSize:"15px"}}>
                        {user.displayName}
                      </div>
                    </div>
                  </div>
                </div>
                </>
              ))}
            </div>
            </>
        )}
      </>}
    </div>
    </>
  )
}