import styled from "styled-components"
import { colors } from "../../theme/Colors"

export const SettingContainerHeader = styled.div`
 display: flex;
 align-items: center;
`
export const SettingContainerHeaderRight = styled.div`
  margin-left:10px;
  font-weight:bold
`
export const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center 
`
export const SettingContainerTop = styled.div`
 display: flex;
 align-items: center;
 margin-top: 20px
`
export const LinkStyle = styled.p`
 cursor:pointer;
 font-weight: 700;
 color: #1A96F0;
 padding-top:20px
`
export const LightButton = styled.button`
    background-color: #F0EDFF;
    border-radius: 50px;
    border: none;
    color: #6949FF;
    padding: 0 32px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 45%;
`
export const DarkButton = styled.button`
    background-color: ${colors.primary};
    border-radius: 50px;
    border: none;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    width: 50%;
`
