import React, { forwardRef } from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors } from '../theme/Colors'
import { Input } from './StandartInput'
import { FiLock } from "react-icons/fi";
import { InputIcon, InputWithIconWrapper } from './StandartStyles';

const PasswortInput = styled(Input)`
width: 100%;
height: 45px;
border-radius: 100px;
padding-left: 30px;
font-size: inherit;
::placeholder {
    padding: 0;
}
`

const StandartPasswortInput = forwardRef(({ handleChange, value, mode = "normal", tabIndex, onKeyDown, enterKeyHint }, ref) => {
   
    const { t } = useTranslation()
    return (
        <InputWithIconWrapper>
            <InputIcon>
                <FiLock color={colors.inputPlaceHolder} />
            </InputIcon>
            <PasswortInput ref={ref} placeholder={mode === "repeat" ? t("passwordRepeat") : t("password")} onChange={(event) => handleChange(event.target.value)} value={value} type="password" tabIndex={tabIndex} onKeyDown={onKeyDown} enterKeyHint={enterKeyHint}>
            </PasswortInput>
        </InputWithIconWrapper>
    )
})

export default StandartPasswortInput
