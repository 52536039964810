import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CreatorApplicationImg1 from '../assets/creatorApplication1.png'
import StandartButton from '../components/StandartButton'
import Back from '../components/Back'
import { t } from 'i18next'

function CreatorApplicationIntro() {
    const navigate = useNavigate()



   const user = useSelector(state => state.user.user)

useEffect(() => {
    if (user.creatorStatus === "pending") {
        navigate("/creatorapplicationwaiting")
    } else if (user.creatorStatus === "active") {
        navigate("/userProfile/me")
    } else if (user.creatorStatus === "approved") {
        navigate("/connect-account-refresh")
    }

}, [user])

    return (
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <Back/>
            <div>
                <img src={CreatorApplicationImg1} style={{width: 160}}/> 

                <div style={{width: "100%", padding: 20}}>
                    <h1>{t("creatorApplicationIntroHeading")}</h1>
                    <p className="infoText">
                   {t("creatorApplicationIntroText")}
                    </p>
                </div>
            </div>  
            <div style={{position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{width: "80%", height: 80}} >
                    <StandartButton title="Jetzt Creator werden" action={()=>navigate("/creatorapplicationform")} />
                </div>
            </div>
        </div>
    )
}

export default CreatorApplicationIntro
