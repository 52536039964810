import React, { useEffect, useState } from 'react'
import { checkAccountStatus, getOwnUser } from '../sideEffects'
import { SpinnerCircularFixed } from 'spinners-react';
import StandartButton from '../components/StandartButton'
import { useNavigate } from 'react-router-dom'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import StandartContainer from '../components/StandartContainer'
import { t } from 'i18next'

function ConnectAccountReturn() {
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(null)
    const navigate = useNavigate()
    const { width, height } = useWindowSize()

    useEffect(() => {
        async function _checkAccountStatus() {
            const result = await checkAccountStatus()
            setStatus(result.data.accountStatus)
            if (result.data.accountStatus === "active") {
                setLoading(false)
                getOwnUser()
            } else {
                navigate("/connect-account-refresh")
            }
        }
        _checkAccountStatus()
    }, [])

    if (loading) {
        return (
            <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <SpinnerCircularFixed color="#000000" size={50} thickness={100} speed={200} />
            </div>
        )}

    if (status === "active") {
        return (
            <StandartContainer>
            <Confetti
                width={width}
                height={height}
                recycle={false}
                />
                <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                    <div></div>
                    <h1>{t("creatorSetupCompleteText")}</h1>
                    <div style={{width: "90%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <StandartButton action={() => navigate("/userProfile/me")} title={"Zum Profil"}></StandartButton>
                    </div>
                    </div> 
            </StandartContainer>
        )
    }
       
    return (
        <div>
            <h1>Stripe Connect Return URL</h1>
        </div>
    )
}

export default ConnectAccountReturn