import React, { useState } from 'react';
import StandartButton from '../components/StandartButton';
import { manageVideoInfo } from '../sideEffects';
import { useLocation } from 'react-router-dom';

function ItemCard({ item, onInputChange, onRemove }) {

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      onInputChange(name, value);
    };
  
    return (
      <div style={{margin: 20}}>
    
        <div>
        <label htmlFor="name">
          Name</label>
          <input placeholder="Zara" type="text" name="name"  onChange={handleInputChange} />
        </div>

        <div>
            <label htmlFor="brand">
            Brand </label>
            <input placeHolder={"Straight High Jeans"} type="text" name="brand" value={item.brand} onChange={handleInputChange} />
        </div>

        <div>
        <label htmlFor="codeNumber">
          Code Number </label>
          <input placeHolder={"97895465"} type="text" name="codeNumber" value={item.codeNumber} onChange={handleInputChange} />
        </div>

        <div>
        <label htmlFor="size">
            Size </label>

          <input placeHolder={"M"} type="text" name="size" value={item.size} onChange={handleInputChange} />
          </div>
        
        <div>
        <label htmlFor="price">
          Price</label>
          <input type="text" name="price" value={item.price} onChange={handleInputChange} />
        </div>

        <button onClick={onRemove}>Delete</button>
      </div>
    );
  }
  
  function AddVideoInfo() {
    const [items, setItems] = useState([{ brand: '', name: '', codeNumber: '', size: '', price: '' }]);
    const {state} = useLocation();
  
    const videoId = state.videoId

    const handleItemInputChange = (index, name, value) => {
      const newItems = [...items];
      newItems[index][name] = value;
      setItems(newItems);
    };
  
    const handleAddItem = () => {
      setItems([...items, { brand: '', name: '', codeNumber: '', size: '', price: '' }]);
    };
  
    const handleRemoveItem = (index) => {
      const newItems = [...items];
      newItems.splice(index, 1);
      setItems(newItems);
    };

    const handleSubmit = () => {
        manageVideoInfo(videoId, items, "add")
    }

  
    return (
      <div>
        <h1>Add video info</h1>
        {items.map((item, index) => (
          <ItemCard
            key={index}
            item={item}
            onInputChange={(name, value) => handleItemInputChange(index, name, value)}
            onRemove={() => handleRemoveItem(index)}
          />
        ))}
        <button onClick={handleAddItem}>Add Item</button>
        <StandartButton title={"Fertig"} action={handleSubmit}></StandartButton>
      </div>
    );
  }
  


export default AddVideoInfo
