import React from 'react'

function BottomButtonContainer({children}) {
    return (
        <div style={{width: "100%", position: "absolute", bottom: 0, left: 0, display: "flex", justifyContent: "center"}}>
            <div style={{width: "90%", marginBottom: 20}}>
            {children}
            </div>
          
        </div>
    )
}

export default BottomButtonContainer
