import React, {useState, useEffect} from 'react'
import Back from '../components/Back'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { getBoughtVideos } from '../sideEffects'
import { SpinnerCircularFixed } from 'spinners-react';
import shirtFull from "../assets/shirtFull.png"
import shirtEmpty from "../assets/shirtEmpty.png"
import { colors } from '../theme/Colors'
import { useSelector } from 'react-redux'
import StandartHeader from '../components/StandartHeader'

function OutfitLibrary() {

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const boughtVideos = useSelector(state => state.user.boughtVideos)
    console.log("boughtVideos", boughtVideos)

    // useEffect(() => {
    //     async function _getBoughtVideos() {
    //         await getBoughtVideos()
    //         setLoading(false)
    //     }
    //     _getBoughtVideos()
    // }, [])

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "100%"}}>
            <StandartHeader title={t("outfitLibrary")} />
            {loading &&
            <div style={{   display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100%', width: '100%' }} >
            <SpinnerCircularFixed color="#000000" size={50} thickness={100} speed={200} />
        </div>}
        {loading && boughtVideos?.length === 0 &&
            <div style={{   display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100%', width: '100%' }} >
                <p style={{  fontSize: '15px', fontWeight: 600, color: "gray", margin: 0, marginTop: 2 }}>{t("noOutfitsBought")}</p>
            </div>}

            <div style={{display: "flex", marginTop: 50, flexWrap: "wrap", justifyContent: "center"}}>
            {boughtVideos?.length > 0 && boughtVideos?.map((video, index) => {
                return (
                    <div key={index} style={{width: 110, height: 210, borderRadius: 25, margin: 4, overflow: "hidden", position: "relative"}} onClick={() => navigate('/outfitlibrary/videos', {state: {contentPosition: index, videos: boughtVideos}})}>
                        <img src={video.previewImageDownloadURL} style={{width: "100%", height: "100%", objectFit: "cover", transition: "ease-in"}}/>
                        {/* <div style={{position: "absolute", bottom: 10, right: 8, width: 20, height: 20, borderRadius: "50%", backgroundColor: colors.primary, display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <img src={true ? shirtFull : shirtEmpty} style={{width: "70%", height: "auto", objectFit: "contain"}}/>
                        </div>     */}
                    </div>
                )
            })}
            </div>
           
        </div>
    )
}

export default OutfitLibrary
