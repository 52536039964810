import { Provider } from 'react-redux'
import store from './redux/store'
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n/i18n.config"
import NavigationCentral from './NavigationCentral'
import './App.css'
import { MantineProvider, createTheme } from '@mantine/core';

const theme = createTheme();

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <MantineProvider theme={theme}>
          <NavigationCentral />
        </MantineProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;