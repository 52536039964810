import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '350px',
        borderRadius: '20px',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.3)'
    }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

function ReactModal({ closeModal, modalIsOpen, children }) {

    return (
        <div>
            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {children}
            </Modal>
        </div>
    );
}

export default ReactModal