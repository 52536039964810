import React from 'react'

function Imprint() {
    return (
        <div>
            
            <h1>Impressum</h1>
            <p>
                Angaben gemäß § 5 TMG
    </p>

    <p>
        <strong>Firmenname:</strong> GetReadyCode UG (haftungsbeschränkt)<br />
        <strong>Adresse:</strong> Campusallee 10, 51379 Leverkusen<br />
        <strong>E-Mail:</strong> mail@getreadycode.com<br/>
    </p>

    <p>
        <strong>Vertreten durch:</strong> Théophile Atohoun und Philipp Riedel<br/>
        <strong>Registereintrag:</strong> Köln HRB 118991<br/>
        {/* <strong>Umsatzsteuer-ID:</strong> Umsatzsteuer-Identifikationsnummer<br/> */}
    </p>
        </div>
    )
}

export default Imprint
