import React from 'react'
import { colors } from '../../theme/Colors'
import { BsArrowLeft } from 'react-icons/bs'
import { ShareVideoBottom, ShareVideoContainerHeader, ShareVideoTopContainer, ShareVideoTopRightContainer, VideoPlayer } from './Style'
import StandardButton from '../../components/StandartButton'
import ProgressBar from "@ramonak/react-progress-bar";

const ShareVideo = ({ selectedVideo, setSelectedVideo }) => {

    const removeImage = () => {
        setSelectedVideo(null)
    }

    return (
        <>
            <ShareVideoContainerHeader >
                <div style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }} >
                    <BsArrowLeft onClick={removeImage} size={25} />
                    <p style={{ paddingLeft: '10px' }} >{"Share Video"}</p>
                </div>
            </ShareVideoContainerHeader>

            <ShareVideoTopContainer>
                <div style={{ height: '200px' }} >
                    {selectedVideo && <VideoPlayer src={URL.createObjectURL(selectedVideo)}></VideoPlayer>}
                </div>

                <ShareVideoTopRightContainer>
                    <p style={{ fontWeight: 'light', fontSize: '14px' }} >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <p style={{ fontWeight: 'light', fontSize: '14px', paddingTop: '20px' }}> #man #guy #handsome #teen #adventure #coolman #vintage #guys #style #love #toktok #germany #unitedstates #forest
                    </p>
                </ShareVideoTopRightContainer>
            </ShareVideoTopContainer>

            <ShareVideoBottom >
                <p onClick={removeImage} style={{ paddingBottom: '20px', textAlign: 'center', color: '#1A96F0' }} >Cancel</p>
                <StandardButton title={'Share'} />
            </ShareVideoBottom>

            <div style={{ padding: '20px' ,borderBottom:'1px solid #EEEEEEw'}} >

                <ProgressBar bgColor={colors.primary} customLabelStyles={{display:'none'}} completed={60} />
            </div>

        </>
    )
}

export default ShareVideo