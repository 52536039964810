import React, {useState, useEffect} from 'react'
import { createConnectedAccount } from '../sideEffects'
import { useNavigate } from 'react-router-dom'
import { SpinnerCircularFixed } from 'spinners-react';
import StandartButton from '../components/StandartButton'
import BottomButtonContainer from '../components/BottomButtonContainer';
import StandartContainer from '../components/StandartContainer';

function ConnectAccountReturn() {
    const [loading, setLoading] = useState(true)
    const [continueLoading, setContinueLoading] = useState(false)
    const [refreshURL, setRefreshURL] = useState(null)
    const navigate = useNavigate()

    async function startCreateConnectedAccount() {
        const result = await createConnectedAccount()
        if (result.status === "alreadyConnected") {
            //navigate to userProfile/me

            navigate("/userProfile/me")

        } else if (result.status === "success") {
            setRefreshURL(result.url)
            setLoading(false)
        }
    }

    useEffect(() => {
        startCreateConnectedAccount()
    }, [])

    if (loading) {
    return (
        <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <SpinnerCircularFixed color="#000000" size={50} thickness={100} speed={200} />
        </div>
    )}
    return (
        <StandartContainer>
            <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                <div>
                <h1>Verfolgständige deinen Creator Account</h1>
                </div>
               <BottomButtonContainer>
                <a href={refreshURL} style={{width: "90%"}}>
                    <StandartButton action={() =>  setContinueLoading(true)} loading={continueLoading} title={"Weiter"}></StandartButton></a>
                </BottomButtonContainer>
                </div> 
        </StandartContainer>
    )
}

export default ConnectAccountReturn
