import { t } from 'i18next'
import {useEffect, useState} from 'react'
import BottomNavigation from '../components/BottomNavigation'
import VideoStack from './VideoStack'
import { signOut } from '../sideEffects'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import { getFeedVideos } from '../sideEffects';
import { useLocation } from "react-router-dom";

// const videoStack = [
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F0e61e1f2-f2f5-4513-aef0-0b228669f9d5?alt=media&token=c018c12a-2fa0-4f80-96f6-69632c979382",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F0e64a720-8c97-4007-a2af-ebffcf85b7cc?alt=media&token=db293b95-a2b8-4673-ad89-50f4437ff9d2",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F14bbd9e1-2fd3-41c0-be92-e2a8274ba348?alt=media&token=7af49f80-ea4d-4ab5-a204-13ce0d467006",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F1586cc25-d4b4-4c39-bdef-b6f6aebd166a?alt=media&token=1722a5c4-6340-40c0-846a-702098c8178d",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F1c0c2b34-6e3d-4707-8c5a-b45eaf765fc2?alt=media&token=10d04984-cc5c-4344-8773-4f799fb125c0",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F2648c635-bfe6-479e-963e-8769be9d013a?alt=media&token=a94756fe-6e0a-400b-b14a-8b230d3af24b",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F2c949fc8-6666-47b6-99d1-e29380edf3fb?alt=media&token=aa2129ae-7be7-4c6c-8fcd-f7d9e32dd705",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F40aae68b-d40d-4cc4-93bf-6c6c2d356fed?alt=media&token=75cd9069-629c-4fa8-a590-db4af6db3577",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F40bb2c05-307e-473c-9e82-3d659afa791d?alt=media&token=b5e7da2c-df52-480b-b157-7600e18007af",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F452a9e09-07d1-4b28-ad63-55e47c1bf650?alt=media&token=67deb980-8a70-4f61-a70a-3dae38a2025b",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F4706cc3a-55cc-4d6b-8022-4cb8383a5b76?alt=media&token=dbf980d7-6d36-4ee8-a533-7b1b1132237a",
//   "http://localhost:9199/v0/b/outfitcheck-staging.appspot.com/o/videos%2F4a9d7a0c-f5b1-4757-924d-e62c4aa0765e?alt=media&token=fa3baf29-a6ff-428e-aa1f-6f019165efe6"
// ]

function ProfileVideoPlayer(props) {
    //get user from redux
    const user = useSelector(state => state.user.user)
    const navigate = useNavigate();
    // const [videoStack, setVideoStack] = useState([])
    // const [contentPosition, setContentPosition] = useState(0)
    const {state} = useLocation();
    //const [videoStack, setVideoStack] = useState([])

 

    // useEffect(() => {
    //   async function getInitialVideos() {
    //    const feedVideos = await getFeedVideos()
    //     // setVideoStack([...videoStack, ...feedVideos])
    //     console.log("feedVideos 123", feedVideos)
    //   }
    //   getInitialVideos()
    // }, [])

    // useEffect(() => {
    //   setVideoStack(state.videoStack)
    //   setContentPosition(state.contentPosition)
    // }, [state])
  // useEffect(() => {
  //   if (user && (!user.userName || user.userName === "")) {
  //       navigate('/updateProfile')
  //   }
  // }, [user])

    // useEffect(() => {
    //     async function getInitialVideos() {
    //      const feedVideos = await getFeedVideos()
    //       setVideoStack([...videoStack, ...feedVideos])
    //       console.log("feedVideos", feedVideos)
    //     }
    //     getInitialVideos()
    //   }, [])

    return (
        <div className="App">
            {/* <h1>{t("youAreLoggedIn")}</h1>
            <button onClick={() => signOut()}>{t("signOut")}</button> */}
          
            {state && <VideoStack videoStack={state.videos} contentPosition={state.contentPosition} type={"profile"}/>}
            {/* <h1>Hallo</h1> */}
            {/* <BottomNavigation/> */}
            <Toaster />        
        </div>
    )
}

export default ProfileVideoPlayer
