import React from 'react';
import { useState, useRef } from 'react'
import { setUser } from '../redux/userSlice'
import { useTranslation } from "react-i18next";
import StandartButton from '../components/StandartButton';
import StandartEmailInput from '../components/StandartEmailInput';
import StandartPasswortInput from '../components/StandartPasswortInput';
import { ErrorMessage } from '../components/StandartStyles';
import { registerUser } from '../sideEffects';
import { useNavigate } from 'react-router-dom';

function Register() {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const input2Ref = useRef(null);
    const input3Ref = useRef(null);

    const [checked1, setChecked1] = React.useState(false);
    const [checked2, setChecked2] = React.useState(false);

    function clientSideValidation() {
        if (password !== passwordRepeat) {
            setErrorMessage(t("auth/passwords-not-matching"))
            return false
        }
        if (email === "") {
            setErrorMessage(t("auth/missing-email"))
            return false
        }
        if (password === "") {
            setErrorMessage(t("auth/missing-password"))
            return false
        }
        if (checked1 === false) {
            setErrorMessage(t("auth/missing-temsAndConditions"))
            return false
        }
        if (checked2 === false) {
            setErrorMessage(t("auth/missing-privacyPolicy"))
            return false
        }
        return true
    }


    const handleSubmit = (e, inputIndex) => {
        e.preventDefault();
    
        switch (inputIndex) {
          case 1:
            input2Ref.current.focus();
            break;
          case 2:
            input3Ref.current.focus();
            break;
          case 3:
            register()
            break;
          default:
            break;
        }
      };
    
    async function register() {
        
        if (!clientSideValidation()) {
            return
        }        

        setLoading(true)

        try {
            await registerUser(email, password)
            setLoading(false)
        } 
        catch (error) {
            setLoading(false)
            setErrorMessage(t(error.code))
        }
    }

    

    const handleChange1 = () => {
        setChecked1(!checked1);
    };
    const handleChange2 = () => {
        setChecked2(!checked2);
    };

    const Checkbox = ({ label, value, onChange }) => {
        return (
          <label style={{display: "flex", justifyContent: "row", alignItems: "center"}}>
            <input type="checkbox" checked={value} onChange={onChange}/>
            <p style={{paddingTop: 2, paddingLeft: 3}}>{label}</p>
          </label>
        );
      };
      

    return (
        <div style={{position: "fixed", zIndex:"1001", backgroundColor:"white", zIndex:"9001"}} className="loginContainer">
            <div className="loginCenter">
                <h2>{t("createNewAccount")}</h2>
                <div>
                    <StandartEmailInput handleChange={setEmail} value={email} tabIndex="0" onKeyDown={(e) => ['Enter', 'Tab'].includes(e.key) && handleSubmit(e, 1)} enterKeyHint={"next"}/>
                </div>
                <div><StandartPasswortInput handleChange={setPassword} value={password}  tabIndex="1" onKeyDown={(e) => ['Enter', 'Tab'].includes(e.key) && handleSubmit(e, 2)} ref={input2Ref} enterKeyHint={"next"}/>
                </div>
                <div><StandartPasswortInput handleChange={setPasswordRepeat} value={passwordRepeat} mode={"repeat"} tabIndex="2" onKeyDown={(e) => ['Enter'].includes(e.key) && handleSubmit(e, 3)} ref={input3Ref} enterKeyHint={"done"}/>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: 12}}>
                    <div style={{marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center"}} >
                        <Checkbox label={t("iAccept")} value={checked1} onChange={handleChange1}/>
                        <a style={{marginLeft: 5}} onClick={()=>navigate("/terms-of-service")} className="registerLoginLink">{t("termsAndConditions")}</a>
                    </div>
                    <div style={{marginTop: -10,  display: "flex", flexDirection: "row", alignItems: "center", fontSize: 12}}>
                        <Checkbox label={t("iAccept")} value={checked2} onChange={handleChange2} />
                        <a style={{marginLeft: 5}} onClick={()=>navigate("/privacy-policy")} className="registerLoginLink">{t("privacyPolicy2")}</a>
                    </div>
                </div>
               
                <div style={{marginTop: 50}}>
                    <StandartButton title={t("signUp")} action={register} loading={loading} />
                </div>
                <div>
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </div>
                <p style={{marginTop: 40}}>{t("alreadyHaveAnAccount")} <a onClick={()=>navigate("/login")} className="registerLoginLink">{t("signIn")}</a> </p>
            </div>
        </div>
    )
}

export default Register
