import { t } from 'i18next'
import React, { useRef, useState, useEffect } from 'react'
import './MyEarnings.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAffiliateEvents } from '../sideEffects';
import StandartHeader from '../components/StandartHeader';
import StandartCard from '../components/StandartCard';


function MyEarnings() {
    const statistics = useSelector((state) => state.creator.statistics);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAffiliateEvents)
    }
    , [])

    if (!statistics) {
        return <div>No data available</div>;
    }

    return (
      <div style={{paddingBottom: 30}}>
        <StandartHeader title={t("yourEarnings")} />
        <StandartCard>
          <h2>{t("approvedAndReadyToPayOut")}</h2>
          <p className="mainStatisticNumber">{statistics.approved.amount} EUR</p>
          <p>{t("paymentInProcess")}</p>
        </StandartCard>

        <StandartCard>
          <h2>{t("tracked")}</h2>
          <p className="mainStatisticNumber">{statistics.tracked.amount} EUR</p>
          
          <p>{t("pendingPayment")}</p>
        </StandartCard>

        <StandartCard>
          <h2>{t("clicks")}</h2>
          <p className="mainStatisticNumber">{statistics.clicks}</p>
          <p>{t("clicksOnLinks")}</p>
        </StandartCard>
      </div>
    )

    return (
    <div className="app">
      <div className="card">
        <div className="card-content">
          <p>{t("approvedAndReady")}</p>
          <h2>{statistics.approved.amount} EUR<span role="img" aria-label="smiley">😌</span></h2>
          <p>{statistics.approved.itmes} Items <span className="info-icon">ℹ️</span></p>
          <div className="euro-icon">
            <span role="img" aria-label="euro">💶</span>
          </div>
        </div>
      </div>

      {/* <div className="date-range">
        <span role="img" aria-label="calendar">📅</span> Jan, 2019 - Feb, 2019
      </div> */}

      <p className="note">Dein Zahlen werden Täglich Aktualisiert!</p>

      <div className="tracked">
        <h2>Tracked</h2>
        <h2>{statistics.tracked.amount} EUR<span role="img" aria-label="money">💸</span></h2>
        <p>{statistics.tracked.items} Items</p>
      </div>

      <div className="clicks">
        <h2>Totale Klicks</h2>
        <h1>{statistics.clicks}</h1>
      </div>
    </div>
    )
}

export default MyEarnings
