import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    statistics: {
        approved: {
            amount: 0,
            items: 0,
        },
        tracked: {
            amount: 0,
            items: 0,
        },
        clicks: 0,
    
    },
    statisticSyncDate: null
}

export const creatorSlice = createSlice({
    name: 'creator',
    initialState,
    reducers: {
        setStatistics: (state, action) => {
            const { payload } = action;
            state.statistics = payload
        },
        setStatisticSyncDate: (state, action) => {
            const { payload } = action;
            state.statisticSyncDate = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStatistics, setStatisticSyncDate } = creatorSlice.actions

export default creatorSlice.reducer

//write a function 
