import { t } from 'i18next'
import {useEffect, useState} from 'react'
import BottomNavigation from '../components/BottomNavigation'
import VideoEntry from './VideoEntry'
import { signOut } from '../sideEffects'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { getFeedVideos } from '../sideEffects';


function VideoPlayer(props) {
    //get user from redux
    const user = useSelector(state => state.user.user)
    const navigate = useNavigate();
    const [videoStack, setVideoStack] = useState([])
    const params = useParams();
    const {state} = useLocation();

    const notifyEmailConfirmed = () => toast.success('E-Mail-Adresse bestätigt.', {  position: 'bottom-center',duration: 5000,});
    const { emailConfirmed } = useParams();
    useEffect(() => {
        if (emailConfirmed==="emailConfirmed") {
                notifyEmailConfirmed()
        }
    }, [ emailConfirmed ])

  useEffect(() => {
    if (user && (!user.userName || user.userName === "")) {
        navigate('/updateProfile')
    }
  }, [user])

    useEffect(() => {
        async function getInitialVideos() {
         const feedVideos = await getFeedVideos()
          setVideoStack([...videoStack, ...feedVideos])
        }
        getInitialVideos()
      }, [])

      useEffect(() => {
      }, [state])

    return (
        <div className="App">
            {/* <h1>{t("youAreLoggedIn")}</h1>
            <button onClick={() => signOut()}>{t("signOut")}</button> */}
          
            <VideoEntry videoStack={videoStack}/>
            {/* <h1>Hallo</h1> */}
            <BottomNavigation/>
            <Toaster />        
        </div>
    )
}

export default VideoPlayer
