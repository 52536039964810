import styled from 'styled-components'

export const EmailContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:100%;
    text-align:center;
`

export const EmailContainerTop = styled.div`

 justify-content: space-around;
 display: flex;
 flex-direction: column;
 height: 70%;
 margin-top: 50px
`
export const EmailContainerBottom = styled.div`

 align-items: center;
 justify-content: center;
 display: flex;
 flex-direction: column;
 height: 30% 
`

export const EmailContainerBottomTextContainer = styled.div`
 display: flex;
 align-items: center;
 margin-bottom: 10px
`
export const EmailContainerBottomText = styled.p`
color: #9E9E9E;
 font-size: 14px
`
export const EmailContainerBottomSpan = styled.span`
color: #6949FF;
 font-size: 14px;
 margin-left: 5px
`

export const ConfirmEmailText = styled.p`
font-weight: 700;
 font-size: 2rem
`