import { auth } from "./firebase"
import React from "react"
import { useSelector } from 'react-redux'
import store from "./redux/store"
export function urlParser(url) {
    if (url && window.location.hostname === "mac.local") {
        const newURL =  url.replace("localhost", "mac.local")
     return newURL
    } else {
     return url
    }
}

export function checkIfUserIsLogin(){
    if(auth.currentUser && auth.currentUser.uid){
        return true;
    }
    return false

}

export function navigateBack() {
    const history = store.getState().navigation.history;
    const currentPage = history[0]
    console.log("history", history);

    const blacklist = [
        "/paymentsetup", 
        "/add-paymentmethod-success",
        //"/creatorapplicationintro",
        "/updateProfile",
        "/creatorapplication",
        "https://connect.stripe.com/*",
        "/connect-account-return",
        "/connect-account-refresh"

    ];

    let backCount = -1;
    
    for(let i = 1; i<9; i++){
        if (blacklist.includes(history[i]) || currentPage===history[i]) {
            backCount = backCount - 1;
        }
        else if(i==8){
            console.log("Keine Geeignete Seite gefunde. Also zum Menu")
            return "/";
        } 
        else {
            console.log("Navigiere zu: ", history[Math.abs(backCount)], "mit: ", backCount)
            return backCount;
        }
    }
}

export function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }