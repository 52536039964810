import React from 'react'

function InitialLoadingScreen() {
    return (
        <div style={{width: "100%", height: "100%", overflow: "hidden"}}>
           <video src="./loadingIntro.mp4" 
             autoPlay={true}
             muted={true}
    
             playsInline={true}
             loop={true}
            style={{width: "100%", height: "100%", "objectFit": "cover"}}></video>
        </div>
    )
}

export default InitialLoadingScreen
