import React, {useState, useEffect, useRef} from 'react'
import { buyCoins } from '../sideEffects'
import { useNavigate } from 'react-router-dom'
import { SpinnerCircularFixed } from 'spinners-react';
import Back from '../components/Back';
import { useSelector } from 'react-redux';
import Lottie, { useLottie } from "lottie-react";
import coinAnimation from "../assets/coin-animation.json";
import { useTranslation } from 'react-i18next';
import { loadAllProducts } from '../sideEffects';
import StandartHeader from '../components/StandartHeader';
import { t } from 'i18next';

import coin1Img from "../assets/coins/1_coin.png";
import coin2Img from "../assets/coins/2_coin.png";
import coin3Img from "../assets/coins/3_coin.png";
import coin4Img from "../assets/coins/4_coin.png";
import { customHistory } from '../CustomBrowserRouter';

const coinPackets = [{
    id: 1,
    amount: 5,
    price: 1.42
},
{
    id: 2,
    amount: 10,
    price: 2.42
},
{
    id: 3,
    amount: 20,
    price: 3.24
},
{
    id: 4,
    amount: 100,
    price: 8.24
}]

function CoinShopBuyButton(props) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const callBack = props.callBack
    const packet = props.packet
    const [buyButtonClicked, setBuyButtonClicked] = useState(false)
    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     loadAllProducts()
    // }, [])

    async function handleBuyCoins(packetId) {
        setLoading(true)
        const result = await buyCoins(packetId)
        setLoading(false)
        setBuyButtonClicked(false)
        const clientSecret = result.clientSecret
        if (clientSecret) {
            navigate('/paymentsetup', {state: {clientSecret}, returnTo: "coinshop"})
        }
      
        if (result.status === "bought") {
            callBack()
        }
    }
    return (
        <button onClick={!buyButtonClicked ? ()=>setBuyButtonClicked(true) : ()=>handleBuyCoins(packet.id)} style={{minWidth: 80, backgroundColor: "#6E6464", padding: "6px 11px", borderRadius: 20, color: "white", boxShadow: "0 4px 4px 0 #BFBFBF", borderWidth: 0, fontSize: "inherit", lineHeight:"inherit"}}>
         {
                loading ? 
                <SpinnerCircularFixed size={16} thickness={180} speed={180} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                :
                <>{!buyButtonClicked ? `${packet.price} €`
        :
       t("buyNow").toUpperCase()
        }</>
            }
        </button>
    )
}

function CoinShop() {
    const user = useSelector(state => state.user.user)
    const [isPlaying, setIsPlaying] = useState(false);

      const animationRef = useRef();

      const handleBuy = () => {
        if (animationRef.current) {
            setIsPlaying(true);
        //   animationRef.current.play();
        //   animationRef.current.reset()
            animationRef.current.setDirection(1); // Ensure the direction is set to forward
            animationRef.current.goToAndPlay(0);
        }
      };

      const handleComplete = () => {
        setIsPlaying(false);
      };
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <StandartHeader title={t("coinShop")} />
            <div style={{
                    position: "absolute",
                    top: -45,
                    maxWidth: 351,
                    display: isPlaying ? 'block' : 'none'
            }}>
 <Lottie
        animationData={coinAnimation}
        loop={false}
        autoplay={false}
        lottieRef={animationRef}
        onComplete={handleComplete}
      />
            </div>
           
            <h2>Du hast {user.coins ?? 0} Coins</h2>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
            {coinPackets.map((packet, index) => {
                return (
                    <div key={"coinpacket_"+index} style={{width: 150, height: 150, backgroundColor: "#E6DCDC", borderRadius: 9, overflow:"hidden", margin: 15, display: "flex", flexDirection: "column", alignItems: "center"}}>
                        {/* <p style={{width: "100%", paddingTop: 10, backgroundColor: "#B423C1", margin: 0, height: 50}}></p> */}
                        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#B423C1", margin: 0, height: 40}}>
                        <img src={index === 0 ? coin1Img : index === 1 ? coin2Img : index === 2 ? coin3Img : coin4Img} style={{width: 100, height: "80%", objectFit: "contain"}}/>

                        </div>
                        <p style={{fontSize: 22, fontWeight: "bold", height: 10, lineHeight: "10px"}}>{packet.amount} Coins</p>
                        <CoinShopBuyButton packet={packet} callBack={() => handleBuy()}/>
                    </div>
                )
            })
        }
            </div>
        </div>
    )
}

export default CoinShop
