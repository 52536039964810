import React, {useState, useEffect} from 'react'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useLocation} from 'react-router-dom'
import SetupForm from '../components/SetupForm'
import Back from '../components/Back';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe('pk_test_51PFM0Q2K2DChFjmDY9eECZZapyUZNLyaDeLMYOlAYLzMFMcVqW57lbdOlbdZLMVTIiqvEaUDIuyDFyFhxd0y3yfw00ZA9MjCeo');

function PaymentSetup() {
    const { t } = useTranslation();
    const {state, returnTo} = useLocation();
    const [options, setOptions] = useState(null)

    useEffect(() => {
        if (state?.clientSecret) {
           
        setOptions({
            clientSecret: state.clientSecret,
            // Fully customizable with appearance API.
            appearance: {/*...*/},
        });
    }
    }, [state])

    if (!options) {
        return <div>Loading...</div>
    }

    return (
        <>
        <h2 style={{whiteSpace: "pre-line"}}>{t("addPaymentMethod")}</h2>
        <Back/>
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <div style={{width: "90%"}}>
                <Elements stripe={stripePromise} options={options}>
                    <SetupForm returnTo={returnTo}/>
                </Elements>
            </div>
        </div>
        </>
    )
}

export default PaymentSetup
