import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    history: [],
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setNavigation: (state, action) => {
            const { payload } = action;
            state.history = payload
        }
    },
})

export const {setNavigation} = navigationSlice.actions

export default navigationSlice.reducer