import React from 'react'

function StandartCard({children}) {
    return (
        <div style={{
            
            padding: 20,
            backgroundColor: "white",
            borderRadius: 10,
            margin: 20,
            marginTop: 35,
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}>
            {children}
        </div>
    )
}

export default StandartCard
