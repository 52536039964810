import React from 'react';
import '../App.css'

function ReactModal({ children, openModal, modalIsOpen }) {

    return (
        <div>
            <div className={`Modal ${modalIsOpen ? "Show" : ""}`}>
                {children}
            </div>
            <div
                className={`Overlay ${modalIsOpen ? "Show" : ""}`}
                onClick={() => openModal()}
            />
        </div>
    );
}

export default ReactModal