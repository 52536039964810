import React, { forwardRef } from 'react';
import styled from 'styled-components'
import { colors } from '../theme/Colors'
import { Input } from './StandartInput'
import { FaEnvelope } from 'react-icons/fa';
import { InputIcon, InputWithIconWrapper } from './StandartStyles';
import { useTranslation } from 'react-i18next'

const EmailInput = styled(Input)`
width: 100%;
height: 45px;
border-radius: 100px;
padding-left: 30px;
font-size: inherit;
::placeholder {
    padding: 0;
}
`

const StandartEmailInput = forwardRef(({ handleChange, value, tabIndex, onKeyDown }, ref) => {
    
    const { t } = useTranslation()

    return (
        <InputWithIconWrapper>
            <InputIcon>
                <FaEnvelope color={colors.inputPlaceHolder} />
            </InputIcon>
            <EmailInput ref={ref} placeholder={t("email")} onChange={(event) => handleChange(event.target.value)} value={value} type="email" tabIndex={tabIndex} onKeyDown={onKeyDown} enterKeyHint="next">
            </EmailInput>
        </InputWithIconWrapper>
    )
})

export default StandartEmailInput
