import React, { useState } from 'react'
import { RiHome2Fill, RiHome2Line } from 'react-icons/ri';
import { MdPersonOutline, MdPerson } from 'react-icons/md';
import { colors } from '../theme/Colors';
import { useNavigate } from "react-router-dom";

function BottomNavigation() {
    const [active, setActive] = useState("home")
    const navigate = useNavigate();

    return (
        <div style={{backgroundColor: "#1A1B22", color: "#979797", paddingBottom: 30, paddingTop: 12,position: "fixed", bottom: 0, width: "100%", display: "flex", justifyContent: "space-around", borderTopLeftRadius: 16, borderTopRightRadius: 16}}>
            <div onClick={() => {setActive("home"); navigate("/")}}>
                <div>{active === "home" ? <RiHome2Fill color={"#979797"} size={24}/> : <RiHome2Line color={"#979797"}  size={24}/>}</div>
                <div style={{fontSize: 10}}>Home</div>
            </div>
            <div onClick={() => navigate('/addvideo')} style={{fontSize:25, width: 40, height: 40, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 99, backgroundColor: colors.primary, color: "white"}}>+</div>
            <div onClick={() => {setActive("profile"); navigate("/userProfile/me")}}>
            <div>{active === "profile" ? <MdPerson color={"#979797"} size={24}/> : <MdPersonOutline color={"#979797"}  size={24}/>}</div>
            <div  style={{fontSize: 10}}>Profile</div>
            </div>
        </div>
    )
}

export default BottomNavigation
