import React from 'react'

function StandartContainer({children}) {
    return (
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{width: "90%", height: "100%"}}>{/* maxWidth: 500 */}
                {children}
            </div>
        </div>
    )
}

export default StandartContainer
