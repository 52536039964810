import React, {useState, useRef, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import StandartButton from '../components/StandartButton'
import StandartInput from '../components/StandartInput'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { submitCreatorApplication } from '../sideEffects'
import { ErrorMessage } from '../components/StandartStyles';
import Back from '../components/Back'
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import './test.css'


function CreatorApplicationForm() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const user = useSelector(state => state.user.user)

    useEffect(() => {
        if (user.creatorStatus === "pending") {
            navigate("/creatorapplicationwaiting")
        } else if (user.creatorStatus === "active") {
            navigate("/userProfile/me")
        } else if (user.creatorStatus === "approved") {
            navigate("/connect-account-refresh")
        }
    
    }, [user])

    const [prename, setPrename] = useState("")
    const [surnameLow, setSurnameUp] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [country, setCountry] = useState("")
    const [gender, setGender] = useState("female")
    const [phone, setPhone] = useState("")
    const [socialAccount, setSocialAccount] = useState("")
    const [errorMessages, setErrorMessages] = useState([])

    const [buttonLoading, setButtonLoading] = useState(false)

    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);

    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        if (user.creatorStatus === "pending") {
           // navigate("/creatorapplicationwaiting")
        }
    }, [user])

    function validate() {
        if (prename === "") {
            setErrorMessages(["prename"])
            return false
        }
        if (surnameLow === "") {
            setErrorMessages(["surname"])
            return false
        }
        if (dateOfBirth === "") {
            setErrorMessages(["dateOfBirth"])
            return false
        }
        if (country === "") {
            setErrorMessages(["country"])
            return false
        }
        if (gender === "") {
            setErrorMessages(["gender"])
            return false
        }
        if (phone === "") {
            setErrorMessages(["phone"])
            return false
        }
        if (socialAccount === "") {
            setErrorMessages(["socialAccount"])
            return false
        }
        return true
    }

    function submitForm() {
        if (!validate()) {
            return
        }
        setButtonLoading(true)
        console.log("submitCreatorApplication")
        const surname = surnameLow.toLowerCase()
        let creatorApplicationData = {
            prename,
            surname,
            dateOfBirth,
            country,
            gender,
            phone,
            socialAccount
        }
        submitCreatorApplication(creatorApplicationData)
    }

    const handleSubmit = (e, inputIndex) => {
        e.preventDefault();
    
        switch (inputIndex) {
          case 1:
            input2Ref.current.focus();
            break;
          case 2:
            input3Ref.current.focus();
            break;
          case 3:
            input4Ref.current.focus();
            break;
          case 4:
            input5Ref.current.focus();
            break;
          case 5:
            input6Ref.current.focus();
            break; 
         case 6:
            input7Ref.current.focus();
            break;
          case 7:
            submitForm()
            break;
          default:
            break;
        }
      };

      const handleGenderChange = (event) => {
        // Update the state variable with the selected value
        setGender(event.target.value);
      };
    

    return (
        <div style={{overflowY: "scroll", position: "relative"}}>
            <h1>{t("creatorApplication")}</h1>
            <Back/>
            <div style={{width: "100%", display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 50}}>
                <div className='formLine'>
                    <label style={{display: "block"}}>{t("prename")}</label>
                    <StandartInput value={prename} onChange={(e)=>{console.log("234", e.target.value); setPrename(e.target.value)}} placeholder={t("prename")} tabIndex="0" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 1)} enterKeyHint={"next"}/>
                    {errorMessages.includes("prename") && <ErrorMessage>{t("prenameErrorMessage")}</ErrorMessage>}
                </div>
                <div className='formLine'>
                    <label style={{display: "block"}}>{t("surname")}</label>
                    <StandartInput value={surnameLow} onChange={(e)=>setSurnameUp(e.target.value)} placeholder={t("surname")} tabIndex="1" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 2)} ref={input2Ref} enterKeyHint={"next"}/>
                    {errorMessages.includes("surname") && <ErrorMessage>{t("surnameErrorMessage")}</ErrorMessage>}
                </div>
                <div className='formLine'>
                    
                    <label style={{display: "block"}}>{t("dateOfBirth")}</label>
                    <DatePicker 
                        onChange={setDateOfBirth} 
                        value={dateOfBirth}
                    />
                    {/* <StandartInput value={dateOfBirth} onChange={(e)=>setDateOfBirth(e.target.value)} placeholder={t("dateOfBirth")} tabIndex="2" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 3)} ref={input3Ref} enterKeyHint={"next"} /> */}
                    {/* <DatePicker selected={dateOfBirth} onChange={(date) => setDateOfBirth(date)} /> */}
                    {/* {errorMessages.includes("dateOfBirth") && <ErrorMessage>{t("dateOfBirthErrorMessage")}</ErrorMessage>} */}
               
                </div>
                <div className='formLine'>
                    <label style={{display: "block"}}>{t("country")}</label>
                    <CountryDropdown
                        value={country}
                        onChange={(val) => setCountry(val)}
                        style={{ 
                            backgroundColor: 'rgb(250, 249, 255)',
                            border: 'none',
                            width: '100%',
                            height: '45px',
                            borderRadius: '100px',
                            outline: 'none',
                            boxSizing: 'border-box',
                            paddingLeft: '19px',
                            fontSize: 'inherit',
                            // color: "gray"
                        }}
                    />
                    {/* <StandartInput value={country} onChange={(e)=>setCountry(e.target.value)} placeholder={t("country")} tabIndex="3" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 4)} ref={input4Ref} enterKeyHint={"next"} />
                    {errorMessages.includes("country") && <ErrorMessage>{t("countryErrorMessage")}</ErrorMessage>} */}
                </div>
                <div className='formLine'>
                    <label style={{display: "block"}}>{t("gender")}</label>
                    {/* <StandartInput value={gender} onChange={(e)=>setGender(e.target.value)} placeholder={t("gender")}  tabIndex="4" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 5)} ref={input5Ref} enterKeyHint={"next"} /> */}
                    <select className="gender_picker" value={gender} onChange={handleGenderChange}>
                        <option value="female">Weiblich</option>
                        <option value="male">Männlich</option>
                        <option value="other">Divers</option>
                    </select>
                    {errorMessages.includes("gender") && <ErrorMessage>{t("genderErrorMessage")}</ErrorMessage>}
                </div>
                <div className='formLine'>
                    <label style={{display: "block"}}>{t("phone")}</label>
                    {/* <StandartInput value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder={t("phone")}  tabIndex="5" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 6)} ref={input6Ref} enterKeyHint={"next"} />
                    {errorMessages.includes("phone") && <ErrorMessage>{t("phoneErrorMessage")}</ErrorMessage>} */}
                    <PhoneInput
                            country={"de"}
                            enableSearch={false}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            containerStyle={{
                                // color:"red"
                                borderRadius:"100px"
                            }}
                            inputStyle={{
                                backgroundColor: "rgb(250, 249, 255)",
                                border:"none",
                                borderRadius:"100px",
                                width:"100%"
                            }}
                            buttonStyle={{
                                borderRadius:"45px",
                                marginLeft: "7px"
                            }}
                            dropdownStyle={{
                                // width:"100%"
                            }}
                            />
                </div>
                <div className='formLine'>
                    <label style={{display: "block"}}>{t("yourSocials")}</label>
                    <StandartInput value={socialAccount} onChange={(e)=>setSocialAccount(e.target.value)} placeholder={t("yourSocials")}  tabIndex="6" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 7)} ref={input7Ref} enterKeyHint={"next"} />
                    {errorMessages.includes("socialAccount") && <ErrorMessage>{t("socialsErrorMessage")}</ErrorMessage>}
                </div>
            </div>
                 <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{width: "80%", height: 80}} >
                        <StandartButton title="Absenden" action={()=>submitForm()} loading={buttonLoading}/>
                    </div>
                </div>
        </div>
    )
}

export default CreatorApplicationForm
