import React from 'react'

function PrivacyPolicy() {
    return (
        <div>
              <h1>Datenschutzerklärung</h1>

<h2>1. Allgemeines</h2>
<p>Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. In dieser Datenschutzerklärung informieren wir Sie über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten auf unserer Plattform StorePin.</p>

<h2>2. Verantwortlicher</h2>
<p>Verantwortlicher für die Datenverarbeitung auf dieser Plattform ist:</p>
<p>
GetReadyCode UG (haftungsbeschränkt) <br/>
Campusallee 10, 51379 Leverkusen<br/>
mail@getreadycode.com<br />
</p>

<h2>3. Erhebung und Speicherung personenbezogener Daten</h2>

<h3>3.1 Beim Besuch der Website</h3>
<p>Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>
<ul>
    <li>IP-Adresse des anfragenden Rechners,</li>
    <li>Datum und Uhrzeit des Zugriffs,</li>
    <li>Name und URL der abgerufenen Datei,</li>
    <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
    <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
</ul>

<h3>3.2 Bei der Registrierung</h3>
<p>Bei der Registrierung auf unserer Plattform erheben wir folgende personenbezogene Daten:</p>
<ul>
    <li>Name,</li>
    <li>E-Mail-Adresse,</li>
    <li>Adresse,</li>
    <li>Zahlungsinformationen,</li>
    <li>Benutzername und Passwort.</li>
</ul>

<h2>4. Nutzung und Weitergabe personenbezogener Daten</h2>
<p>4.1 Wir verwenden die von Ihnen mitgeteilten Daten zur Erfüllung und Abwicklung Ihrer Anfragen und Bestellungen.</p>
<p>4.2 Eine Weitergabe Ihrer persönlichen Daten an Dritte erfolgt ausschließlich an die im Rahmen der Vertragsabwicklung beteiligten Dienstleistungspartner, wie z.B. das mit der Zahlungsabwicklung beauftragte Kreditinstitut.</p>

<h2>5. Verwendung von Cookies</h2>
<p>Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert werden und die bestimmte Einstellungen und Daten zum Austausch mit unserem System über Ihren Browser speichern. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.</p>

<h2>6. Analyse-Tools</h2>

<h3>6.1 Tracking-Tools</h3>
<p>Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Website sicherstellen.</p>

<h2>7. Betroffenenrechte</h2>
<p>Sie haben das Recht:</p>
<ul>
    <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.</li>
    <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen.</li>
    <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen.</li>
    <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
    <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen.</li>
    <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen.</li>
    <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.</li>
</ul>

<h2>8. Datensicherheit</h2>
<p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.</p>

<h2>9. Aktualität und Änderung dieser Datenschutzerklärung</h2>
<p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand [Datum]. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.</p>
</div>
    )
}

export default PrivacyPolicy
