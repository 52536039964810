import React, { useState, useRef, useEffect, forwardRef } from 'react'
import { Dimensions, View, Text } from 'react-native-web';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { MdMenu } from 'react-icons/md';
//import Carousel from 'react-elastic-carousel'

import { auth } from '../firebase';
import Slider from "react-slick";
import ReactPlayer from 'react-player'

import { useNavigate } from 'react-router-dom'
import StandartButton from '../components/StandartButton'
import ReactModal from '../components/ReactBottomModal'
import h_and_m from '../assets/h_and_m.png'
import {FaAngleRight} from 'react-icons/fa'
import BuyButton from '../components/BuyButton';
import { STANDARD_OUTFIT_PRICE } from '../constants';
//import { set } from 'immer/dist/internal';

document.ontouchmove = function(event) {
  event.preventDefault();
}

const maxIndex = 2;
let urlIndex = 2;

function parseURL(url) {
  if (window.location.hostname === "mac.local") {
    url = url.replace("localhost", "mac.local")
  }
  return url
}

const OutfitItem = ({item, videoId, _extraInfo=null}) => {

  const [purchaseState, setPurchaseState] = useState(false)
  const [extraInfo, setExtraInfo] = useState(_extraInfo)
  const [loading, setLoading] = useState(true)


  return (
    // <a href={extraInfo?.url ? extraInfo.url : ""} target="_blank" style={{textDecoration: "none", color: "black", width: "100%"}}>
    <div style={{width: "100%", paddingLeft: 10, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
      <div>
      <div style={{width: "100%", display: "flex", alignItems: "center"}}>
      {/* <img src={h_and_m} style={{width: 20, height: 20, objectFit: "contain", marginRight: 10}}/> */}
    <p style={purchaseState === false ? {filter: "blur(4px)"} : {}}>{purchaseState === false ? "akd234zl34 " : extraInfo?.brand ? extraInfo.brand : ""}</p>
      </div>
    <p style={{textTransform: "uppercase", fontWeight: "bold", textAlign: "left", marginTop:0, paddingTop: 0, marginBottom: 25}}>{item.typeOfCloth}</p>

      </div>
    {purchaseState === false ?
    
    <div style={{width: 100}}>
    <BuyButton title={"1 Coin"} style={{padding: 0}} videoId={videoId} videoInfoItemId={item.id} onResolve={(_data)=>{setExtraInfo(_data); setPurchaseState(true)}} /> 
    </div> :
    <FaAngleRight color='black' size={16} />
}  
    </div>
    // </a>
  )
}
  

const VideoCard = forwardRef(({url, user, index, inFocus, videoId, video}, ref) => {

  url = parseURL(url)

  const [modalIsOpen, setIsOpen] = useState(false);
  const [videoInfoData, setVideoInfoData] = useState(video.videoInfo)

  const navigate = useNavigate()
  return (
    <div className="mainSlide">
    <video src={url} autoPlay={true} controls={false} muted={true} loop={true} playsInline={true} className="slideContent" ref={ref} style={{"objectFit": "cover"}}/>
         
         {modalIsOpen && <>
         <div onClick={() => setIsOpen(false)} style={{width: "100%", height: "100%", backgroundColor: "black", opacity: 0.5, position: "absolute", top: 0}}></div>
          <div className="outfit-info">
            {/* {videoInfoData && videoInfoData.map((item, index) => {
             return <OutfitItem item={item} index={index} videoId={videoId} key={"itemIndex"+index}/>
            })} */}
           
           <BuyButton price={STANDARD_OUTFIT_PRICE} inFocus={inFocus} title={`Complete Outfit:    ${STANDARD_OUTFIT_PRICE} Coins`} style={{margin: 0, marginTop: 32}} videoId={videoId} onResolve={(_videoInfoData)=>{setVideoInfoData(_videoInfoData); setIsOpen(true)}}/> 
          </div>
          </>}
          
          <div onClick={()=>navigate("menu")} style={{position: "absolute", top: 15, left: 20}}><MdMenu color={"white"} size={30} /></div>
        <div onClick={() => navigate(`userProfile/${user.id}`)}><p style={{position: "absolute", bottom: 120, left: 20, color: "white", fontWeight: "bold", fontSize: 17}}>{user.displayName}</p></div>
     <div onClick={() => navigate(`userProfile/${user.id}`)}><div style={{position: "absolute", bottom: 120, right: 30, width: 75, height: 75, borderRadius: 50, borderColor: "white", borderWidth: 5, borderStyle: "solid", backgroundSize: "cover", backgroundImage: `url("${parseURL(user.profileImageDownloadURL)}")`}}></div></div>
<div style={{position: "absolute", bottom: 30, width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
<div style={{width: "87%"}}>

{video.videoInfo && video.videoInfo.length > 0 && <StandartButton title="GET INFO" style={{height: 52}} action={() => setIsOpen(true)}></StandartButton>}
{/* <BuyButton inFocus={inFocus} videoId={videoId} onResolve={(videoInfoData)=>{setVideoInfoData(videoInfoData); setIsOpen(true)}}/> */}

</div>
</div>

{/* <ReactModal openModal={() => setIsOpen(!modalIsOpen)} closeModal={() => setIsOpen(false)} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} >
    {[{name: "Figurbetontes Jerseyshirt", color: "white"}, {name: "Straight High Jeans", color: "white"}, {name: "Flauschiges Off-Shoulder-Kleid", color: "white"}, {name: "Kleid aus Jacquardstrick", color: "white"}].map((item, index) => {
              return (
                <p style={{}} key={"itemIndex"+index}>{item.name}</p>
              )
            })}
            </ReactModal> */}
    </div>
  )
})


const VideoEntry = (props) => {

  const videoRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videoStack, setVideoStack] = useState([])
  const navigate = useNavigate()


  // const playVideo = (event: any) => {
  //   video.current && video.current.play();
  // };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true
  };

  useEffect(() => {
    setVideoStack(props.videoStack)
  }, [props.videoStack])

 

  // const [vedio, loadVedio] = useState("");
  //   const vedioLoader = info => {
        
  //       let url = "http://mac.local:9199/v0/b/outfitcheck-staging.appspot.com/o/10.mp4?alt=media&token=63b35155-91ad-4a7d-910c-4f23c4033af4"
            
  //           var xhr = new XMLHttpRequest();
  //           xhr.responseType = 'blob';
  //           xhr.onload = function (event) {
  //               var blob = xhr.response;
  //           };
  //           xhr.open('GET', url);
  //           xhr.send();
  //           loadVedio(url)
  //           console.log("loaded url", url)
        
  //   }
  //   useEffect(() => {
  //     vedioLoader()
  //   }, [])
  //"https://www.youtube.com/watch?v=L3GZo3UNt3A"
 
function handleUnmuteButtonClicked() {    

    for (let i = 0; i < videoRefs.length; i++) {
      let videoRef = videoRefs[i];
      if (videoRef && videoRef.current) {
        videoRef.current.play();
        videoRef.current.pause();
      }
    }
  }

  function handleSliderChange(index) {
 //   videoRefs[1].current.play();

    let lastVideoRef = videoRefs[currentIndex]
    if (lastVideoRef) {
      lastVideoRef.current && lastVideoRef.current.pause();
    }
    
    let videoRef = videoRefs[index];
    videoRef.current.play();
    if (videoRef && videoRef.current) {
      videoRef.current.play()
     // videoRef.current.muted = false;
    }
    setCurrentIndex(index);
    console.log(index);


    // let nextIndex = index < maxIndex ? index + 1 : 0;
    // let nextVideoRef = videoRefs[nextIndex];
    // urlIndex = urlIndex < videoStack.length ? urlIndex + 1 : 0;
    // nextVideoRef.current.src = videoStack[urlIndex].downloadURL;
  }


  return (
    <div style={{height: "100%", backgroundColor: "gray"}}>
         {/* <video src={"http://mac.local:9199/v0/b/outfitcheck-staging.appspot.com/o/10.mp4?alt=media&token=63b35155-91ad-4a7d-910c-4f23c4033af4"} autoPlay={true} controls muted={true}/>
      <ReactPlayer url={"http://mac.local:9199/v0/b/outfitcheck-staging.appspot.com/o/10.mp4?alt=media&token=63b35155-91ad-4a7d-910c-4f23c4033af4"} controls playing={false} playsinline={false} muted={true} width='100%'
                            height='100%'/> */}
       {/* <video src={"https://firebasestorage.googleapis.com/v0/b/outfitcheck-staging.appspot.com/o/6.mp4?alt=media&token=b2e1a844-063c-4008-afd0-5b956a6b2449"} autoPlay={true} controls/> */}
 {/* <ReactPlayer url="https://firebasestorage.googleapis.com/v0/b/outfitcheck-staging.appspot.com/o/6.mp4?alt=media&token=b2e1a844-063c-4008-afd0-5b956a6b2449" />  */}
<div style={{height: "100%"}}>
  {/* <div style={{height: 50}}>
  <button style={{height: 20, margin: 10}} onClick={handleUnmuteButtonClicked}>Stummschaltung aufheben</button>

  </div> */}

    {videoStack.length > 3  ? 
    
    <>
    <Carousel axis="vertical" infiniteLoop={true} showIndicators={false} showArrows={false} showThumbs={false} dynamicHeight={false} width={"100%"} height={"100%"} showStatus={false}
      onChange={(index, item) => handleSliderChange(index) }
      >

      {/* <Carousel verticalMode={true} pagination={false} showArrows={false} onChange={(item, index) => handleSliderChange(index) } style={{width: "100%", height: "100%", margin: 0}}
      > */}

{/* <VideoCard url={videoStack[1].downloadURL} user={videoStack[1].user} ref={videoRefs[1]} key={"videoKey"+1}/>
<VideoCard url={videoStack[2].downloadURL} user={videoStack[2].user} ref={videoRefs[2]} key={"videoKey"+2}/> */}
        {
          videoStack.length > 3 && [0, 1, 2, 3].map((element, index) => {
            // function logic here
            return <VideoCard video={videoStack[index]} videoId={videoStack[index].id} url={videoStack[index].downloadURL} index={index} user={videoStack[index].user} ref={videoRefs[index]} key={"videoKey"+index} inFocus={index === currentIndex}/>;
          })
        }

            </Carousel>
            </>
          :
          <div style={{height: "100vh", position: "relative", backgroundColor: "lightgray", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div onClick={()=>navigate("menu")} style={{position: "absolute", top: 15, left: 20}}><MdMenu color={"white"} size={30} /></div>

          <div>{"Not enough videos"}</div>  
          </div>
          }
     


            </div>
    {/* </div> */}
   
    </div>
  );
};

export default VideoEntry
  ;
