import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { checkIfUserProfileComplete } from '../sideEffects'
import { useSelector } from 'react-redux'
import { MdClose } from 'react-icons/md'
import { BsFillPersonFill } from 'react-icons/bs'
import {MdOutlineNavigateNext} from 'react-icons/md'
import {AiFillLock} from 'react-icons/ai'
import {BsShop} from 'react-icons/bs'
import {BsPatchCheckFill} from 'react-icons/bs'
import {FaTshirt} from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { Link } from 'react-router-dom';
import { BsFillQuestionCircleFill } from "react-icons/bs";

import { t } from 'i18next'
import { DarkButton, LightButton } from './Settings/Style'

function Menu() {
    const navigate = useNavigate()
    //get user from redux with use selecotor
    const user = useSelector(state => state.user.user)

    function handleCreator() {
        if (user.creatorStatus === "active") {
            navigate("/userProfile")
            return
        } 
        else if (user.creatorStatus === "approved") {
            navigate("/updateProfile")
        }
        else if (user.creatorStatus === "pending") {
            navigate("/creatorapplicationintro")
        } 
        else {
            navigate("/creatorapplicationintro")
        }

        // if (!checkIfUserProfileComplete()) {
        //     console.log("user profile complete", checkIfUserProfileComplete())
        //     navigate("/updateProfile")
        // } 
        // else {
        //     navigate("/creatorapplication")
        // }
    }

    function handleMenuClick(event, value) {
        event.preventDefault(); // Prevent the default behavior (navigation)
        // Your custom logic here
        if (value === "login") {
            navigate("/login")
        } 
        if (value === "register") {
            navigate("/register")
        } 

        if (value === "creator") {
            handleCreator()
        } 
        else if (value === "coinshop") {
            navigate("/coinshop")
        }
        else if (value === "subscriptions") {
            navigate("/subscriptions")
        }
        else if (value === "faq") {
            navigate("/faq")
        }
        else if (value === "outfitlibrary") {
            navigate("/outfitlibrary")
        }
        else if (value === "settings") {
            navigate("/settings")
        }
      }
      
      function displayCreatorStatus(user) {
        if (user.creatorStatus === "active") {
            return t("myAccount")
        } 
        else if (user.creatorStatus === "approved") {
            return t("completeCreatorProfile")
        }
        else if (user.creatorStatus === "pending") {
            return t("creatorApplicationSent")
        } 
        else {
            return t("becomeACreator")
        }
      }

    return (
        <>
        <div style={{height: "100%", display: "flex", width: "100%", flexDirection: "column"}}>
            <h1>Menu</h1>
            <div onClick={() => navigate("/")} className={"closeButton"}><MdClose /></div>
            <div style={{marginBottom: 50}}></div>
            {user ? (<>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: 1}}>
                <div>
                    <a onClick={(e) => handleMenuClick(e, "creator")} style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#F3F0FF", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <BsFillPersonFill color={"#6949FF"} size={26}/>
                        </div>
                        <div style={{width: 200, textAlign: "left"}}>
                        {user && <h3 style={{textAlign: "left"}} className="menuEntryText">{displayCreatorStatus(user)}</h3>}

                        </div>
                        
                    
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                    <a onClick={(e) => handleMenuClick(e, "outfitlibrary")} style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#FEC6DA", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <FaTshirt color={"#FC1568"} size={22}/>
                        </div>
                        <h3 className="menuEntryText">{t("outfitLibrary")}</h3>
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                    <a onClick={(e) => handleMenuClick(e, "coinshop")} style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#FFF7EB", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <BsShop color={"#FD9F1C"} size={26}/>
                        </div>
                        <h3 className="menuEntryText">{t("coinShop")}</h3>
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                    {/* <a onClick={(e) => handleMenuClick(e, "subscriptions")} style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#EDF8F1", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <BsPatchCheckFill color={"#2CBB5B"} size={24}/>
                        </div>
                        <h3 className="menuEntryText">{t("subscriptions")}</h3>
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a> */}

                    <a onClick={(e) => handleMenuClick(e, "faq")} style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#F3F0FF", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <BsFillQuestionCircleFill  color={"#6949FF"} size={24}/>
                        </div>
                        <h3 className="menuEntryText">FAQ</h3>
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                </div>

                <a onClick={(e) => handleMenuClick(e, "settings")} style={{marginBottom: 30, display: "block"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{backgroundColor: "#EDF3FF", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                        <FiSettings color={"#3B7BFE"} size={25}/>
                    </div>
                    <h3 className="menuEntryText">{t("settings")}</h3>
                    </div>
                
                    <MdOutlineNavigateNext/>
                </div>
                </a>
            </div>
            </>):(<>
                <div style={{display:"flex", marginBottom:"25px", flexDirection:"row", gap:"5px", justifyContent:"center", alignItems:"center", textAlign:"center", width:"100%"}}>
                    <DarkButton style={{width:"40%", fontSize: 18}} onClick={() => navigate("/register")}>{t("signUp")}</DarkButton>
                    <LightButton style={{width:"40%", fontSize: 18}} onClick={() => navigate("/login")}>{t("login")}</LightButton>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: 1}}>
                <div>
                    <a style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#dbdbdb", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <AiFillLock color={"#ababab"} size={26}/>
                        </div>
                        <div style={{width: 200, textAlign: "left"}}>
                        <h3 style={{textAlign: "left"}} className="menuEntryText">Creator werden</h3>

                        </div>
                        
                    
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                    <a style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#dbdbdb", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <AiFillLock color={"#ababab"} size={22}/>
                        </div>
                        <h3 className="menuEntryText">{t("outfitLibrary")}</h3>
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                    <a style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#dbdbdb", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <AiFillLock color={"#ababab"} size={26}/>
                        </div>
                        <h3 className="menuEntryText">{t("coinShop")}</h3>
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                    <a style={{marginBottom: 30, display: "block"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{backgroundColor: "#dbdbdb", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                            <AiFillLock color={"#ababab"} size={24}/>
                        </div>
                        <h3 className="menuEntryText">{t("subscriptions")}</h3>
                        </div>
                    
                        <MdOutlineNavigateNext/>
                    </div>
                    </a>

                </div>

                <a style={{marginBottom: 30, display: "block"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{backgroundColor: "#dbdbdb", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                        <AiFillLock color={"#ababab"} size={25}/>
                    </div>
                    <h3 className="menuEntryText">{t("settings")}</h3>
                    </div>
                
                    <MdOutlineNavigateNext/>
                </div>
                </a>
            </div>
            </>)}
        </div>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", color: "var(--dark-gray, #8d8f91)", fontSize:"14px", alignItems: "flex-start", height:"5%", marginTop: 30}}>
            <Link to="/imprint" style={{display: "flex", textDecoration: "none", justifyContent: "center", color: "var(--dark-gray, #8d8f91)"}}>
                <p>{t("impressum")}</p>
            </Link>
            <Link to="/privacy-policy" style={{display: "flex", textDecoration: "none", justifyContent: "center", color: "var(--dark-gray, #8d8f91)"}}>
                <p>{t("privacyPolicy")}</p>
            </Link>
            <Link to="/terms-of-service" style={{display: "flex", textDecoration: "none", justifyContent: "center", color: "var(--dark-gray, #8d8f91)"}}>
                <p>{t("termsAndConditions")}</p>
            </Link>
        </div>

        </>
    )
}

export default Menu
