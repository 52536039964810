import React, {useState, useEffect} from 'react'
import StandartButton from './StandartButton'
import { useSelector } from 'react-redux'
//import { useNavigate } from 'react-router-dom'
import { buyInfo } from '../sideEffects'
import store from '../redux/store'
import { customHistory } from '../CustomBrowserRouter'
import { STANDARD_OUTFIT_PRICE } from '../constants'
import { STANDARD_ITEM_PRICE } from '../constants'
import { auth } from '../firebase'
import { updateCoins } from '../redux/userSlice'

// action={() => setIsOpen(true)}

function BuyButton(props) {
    const [buttonClicked, setButtonClicked] = useState(false)
    const [loading, setLoading] = useState(false)
    const user = store.getState().user.user
    const video = props.video
    const price = props.price
    const videoInfoItemId = props.videoInfoItemId
    //const navigate = useNavigate()
    const title = props.title
    const inFocus = props.inFocus
    const style = props.style
    
    // useEffect(() => {
    //     if (!inFocus) {
    //         setButtonClicked(false)
    //     }
    // }, [inFocus])

    function checkIfUser(){
      if(auth.currentUser){
        if(auth.currentUser.uid){
          return true
        }
        return false
      }
      return false
    }

    async function buyClicked() {
        setLoading(true)
        if(checkIfUser()){
            if(!price){
                if (user.coins >= STANDARD_OUTFIT_PRICE) {
                    // await buyInfo(video, videoInfoItemId)
                    const coins = await buyInfo(video, videoInfoItemId)
                    store.dispatch(updateCoins(coins))
                    //props.onResolve(retrievedVideoInfo)
                } else {
                    console.log("not enough coins, navigating to coinshop")
                    setLoading(false)
                    customHistory.push("/coinshop")
                }
            } else {
                if (user.coins >= price) {
                    // await buyInfo(video, videoInfoItemId)
                    const coins = await buyInfo(video, videoInfoItemId)
                    store.dispatch(updateCoins(coins))
                    //props.onResolve(retrievedVideoInfo)
                } else {
                    console.log("not enough coins, navigating to coinshop")
                    setLoading(false)
                    customHistory.push("/coinshop")
                }
            }
        } else {
            setLoading(false)
            customHistory.push("/register")
        }
    }

    return (
        <>
        <StandartButton title={title} loading={loading} style={{height: 52, ...style}} image={props.image} action={
            // !buttonClicked ? 
            // () => setButtonClicked(true)
            // : 
            () => buyClicked()
            }></StandartButton>
        </>  
    )
}

export default BuyButton
