import React from 'react'
import { t } from 'i18next'

import { useNavigate } from 'react-router-dom'


import CreatorApplicationImg2 from '../assets/creatorApplication2.png'
import { useTranslation } from 'react-i18next'
import StandartButton from '../components/StandartButton'
function CreatorApplicationWaiting() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <div>
                <img src={CreatorApplicationImg2} style={{width: 160}}/> 

                <div style={{width: "100%", padding: 20}}>
                    <h1>{t("creatorApplicationWaitingHeading")}</h1>
                    <p className='infoText'>
                        {t("creatorApplicationWaitingText")}
                    </p>
                </div>
            </div>  
            <div style={{position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{width: "80%", height: 80}} >
                    <StandartButton title={t("goToStartPage")} action={()=>navigate("/")} />
                </div>
            </div>
        </div>
    )
}

export default CreatorApplicationWaiting
