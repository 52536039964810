import bg from '../../assets/profilebg.png'
import styled from 'styled-components'
import { colors } from '../../theme/Colors'

export const ProfileContainerHeader = styled.div`
 padding: 20px;
 display: flex;
 align-items: center;
 justify-content: space-between 
`
export const ProfileContainerHeaderRight = styled.div`
 display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40% 
`

export const ProfileContainerTop = styled.div`
 height: 250px;
 display: flex;
 align-items: flex-end;
 justify-content: center;
 position: relative;
 background-image:${props => props.image ? `url(${props.image})` : `url(${bg})`};
 background-position: center;
 background-repeat: no-repeat; 
 background-size: cover; 
 width: 100%
`
export const ProfileContainerTopMain = styled.div`
  box-shadow: 1px 0px 7px 1px rgb(0 0 0 / 26%);
  height: 170px;
  background: white;
  width: 80%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: -70px;
  text-align: center ;
`
export const ProfileContainerTopUserImageContainer = styled.div`
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 130px;
  border: 5px solid white;
  border-radius: 50%;
  margin-top: -70px

`
export const ProfileContainerTopUserImage = styled.img`
height:100%;
 width: 100%;
 object-fit: cover;
 border-radius: 100px;
 position: relative;
`
export const ProfileContainerTopUserImageEdit = styled.div`
 position: absolute;
  top: 70px;
  right: 0px;
  background-color: ${colors.primary};
  border-radius: 5px;
  height: 25px;
  width: 25px;
   display: flex;
   justify-content: center;
   align-items: center 
`


export const ProfileCardWrapper = styled.div`
margin-top: 90px;
padding: 10px;
gap:7px;
padding-bottom: 150px;
display:flex;
flex-wrap:wrap;
justify-content:flex-start;
overflow-y: scroll;
min-height: 50%;
`

export const ProfileCardContainer = styled.div`
 height: 200px;
  background: gray;
  border-radius: 14px;
  display: flex;
  width:32%;
  justify-content: space-between;
  align-items: flex-end;
  background-image:${props => props.image ? `url(${props.image})` : `url(${bg})`};
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover; 
  margin-top:10px;
`
export const ProfileCardContainerBottom = styled.div`
 display: flex;
 justify-content: space-between;
 width: 100%;
 padding: 10px 
`
export const ProfileCardContainerBottomLeft = styled.div`
display: flex;
 align-items: center
`
export const ProfileCardContainerBottomLeftText = styled.p`
 padding-left: 5px;
  font-weight: 600;
  font-size: 14px;
  color: white;
  alingItems: flex-start;
  line-height: 0px
`
export const ProfileCardContainerBottomRight = styled.div`
background-color: ${colors.primary};
 height: 30px;
 width: 30px;
 border-radius: 100px;
 display: flex;
 justify-content: center;
 transform: rotate(-20deg);
  align-items: center 
`
export const ModalContainer = styled.div`
 display: flex;
  flex-direction: column;
  justify-content: center 
`
export const ModalTop = styled.div`
display: flex;
 align-items: center;
 margin-top: 20px
`