import React from 'react'
import Back from '../components/Back'
import { Accordion } from '@mantine/core';
import { t } from 'i18next'
import StandartHeader from '../components/StandartHeader'

// ICONS
import { BsPatchCheckFill } from 'react-icons/bs'
import { FaTshirt } from 'react-icons/fa'
import { FaChevronDown } from "react-icons/fa";

export default function Faq() {
    // Array of questions and answers for the FAQ
    const range = Array.from({ length: 9 }, (_, i) => i + 1);

    // Map over the range to create Accordion items
    const items = range.map((item) => (
        <Accordion.Item key={item.toString()} value={`item${item}`}>
            <Accordion.Control 
                style={{ 
                    color: "#6949FF", 
                    fontWeight: "bolder",
                    lineHeight: "1.5", 
                    padding: "10px 20px", 
                    wordBreak: "break-word"  // This ensures words break to the next line if they are too long
                }} 
                chevron={<FaChevronDown />}
            >
                <p style={{ 
                    fontWeight: "bolder", 
                    margin: 0, 
                    whiteSpace: "normal"  // Allows text to wrap normally
                }}>
                    {t(`q${item}`)}
                </p>
            </Accordion.Control>
            <Accordion.Panel style={{ textAlign: "left" }}>
                {t(`a${item}`)}
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <div style={{ height: "100%", display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
            <StandartHeader title={t("faq")} />
            <div style={{ marginBottom: 50 }}></div>
            <div style={{ width: "95%" }}>
                <Accordion radius="lg" variant="filled" defaultValue="item1" chevronSize={15}>
                    {items}
                </Accordion>
            </div>
        </div>
    )
}
