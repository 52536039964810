import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MdClose } from 'react-icons/md'
import { BsFillPersonFill } from 'react-icons/bs'
import {MdOutlineNavigateNext} from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCreatorName } from '../sideEffects'
import { useState } from 'react'
import Back from '../components/Back'
export default function Subscriptions() {
    const navigate = useNavigate()
    const user = useSelector(state => state.user.user)
    const subscripedIds = useSelector(state => state.user.user.userSubscribed)
    // console.log(subscripedIds)
    const [nameMap, setNameMap] = useState({});

    useEffect(() => {
      async function fetchNames() {
        // console.log("JAA", subscripedIds)
        if(subscripedIds){
          const subscribedIdsKeys = Object.keys(subscripedIds);
    
          if (subscribedIdsKeys.length > 0) {
            const fetchedNames = {};
            for (const id of subscribedIdsKeys) {
              const result = await getCreatorName(id);
              fetchedNames[id] = result;
            }
            setNameMap(fetchedNames);
          }
        }
      }
      fetchNames();
    }, [subscripedIds]);

  return (
    <>
    { subscripedIds ? (
          <div style={{height: "100%", display: "flex", width: "100%", flexDirection: "column"}}>
           <Back/>
          <h1>Subscriptions</h1>
          <div style={{marginBottom: 50}}></div>
          <div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: 1}}></div>
              {
              Object.keys(subscripedIds).map((id, index) => (
                <a key={index} onClick={() => navigate(`/subscription-info?sub=${subscripedIds[id]}&name=${nameMap[id]}`)} style={{ marginBottom: 30, display: "block" }}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                      <div style={{ width: 200, textAlign: "left" }}>
                        {user && <h3 style={{ textAlign: "left" }} className="menuEntryText">@{nameMap[id]}</h3>}
                      </div>
                    </div>
                    <MdOutlineNavigateNext />
                  </div>
                </a>
              ))
              }
            </div>
        </div>
    ) : (
      <>
      <div style={{height: "100%", display: "flex", width: "100%", flexDirection: "column"}}>
        <Back/>
        <h1>Subscriptions</h1>
        <div style={{marginBottom: 50}}></div>
        <p>Du hast niemanden Abonniert</p>
      </div>
      </>
    )}
    </>
  )
}
