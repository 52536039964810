import React from 'react'

function TermsOfService() {
    return (
        <div>
              <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
    <h2>1. Geltungsbereich</h2>
    <p>Diese Allgemeinen Geschäftsbedingungen (AGB) regeln das Vertragsverhältnis zwischen der Plattform [Name der Plattform] und den Nutzern, die Videos veröffentlichen, in denen Marken und Produkte dargestellt werden, und diese über Affiliate-Links vermarkten (im Folgenden "Content Creator").</p>

    <h2>2. Registrierung und Konto</h2>
    <ul>
        <li>2.1 Um die Dienste der Plattform zu nutzen, müssen sich Content Creator registrieren und ein Konto erstellen.</li>
        <li>2.2 Der Content Creator ist verpflichtet, bei der Registrierung wahrheitsgemäße und vollständige Angaben zu machen.</li>
        <li>2.3 Die Zugangsdaten sind vertraulich zu behandeln und dürfen nicht an Dritte weitergegeben werden.</li>
    </ul>

    <h2>3. Leistungen der Plattform</h2>
    <ul>
        <li>3.1 Die Plattform bietet Content Creators die Möglichkeit, Videos zu veröffentlichen, in denen Marken und Produkte vorgestellt werden.</li>
        <li>3.2 Content Creators können Affiliate-Links in ihren Videos platzieren, um Provisionen für Verkäufe zu erhalten, die über diese Links getätigt werden.</li>
    </ul>

    <h2>4. Rechte und Pflichten der Content Creator</h2>
    <ul>
        <li>4.1 Content Creator sind dafür verantwortlich, dass ihre Videos keine Rechte Dritter verletzen und im Einklang mit geltendem Recht stehen.</li>
        <li>4.2 Es ist untersagt, rechtswidrige, beleidigende, gewaltverherrlichende, oder pornografische Inhalte zu veröffentlichen.</li>
        <li>4.3 Content Creator müssen alle notwendigen Informationen zur Transparenz und Kennzeichnung von Werbung und Affiliate-Links bereitstellen.</li>
    </ul>

    <h2>5. Vergütung und Abrechnung</h2>
    <ul>
        <li>5.1 Content Creators erhalten eine Provision für jeden Verkauf, der über ihre Affiliate-Links generiert wird.</li>
        <li>5.2 Die Höhe der Provision wird im Konto des Content Creators angezeigt und monatlich abgerechnet.</li>
        <li>5.3 Die Auszahlung erfolgt nach den auf der Plattform angegebenen Bedingungen.</li>
    </ul>

    <h2>6. Haftung</h2>
    <ul>
        <li>6.1 Die Plattform haftet nicht für Inhalte, die von Content Creators veröffentlicht werden.</li>
        <li>6.2 Die Plattform übernimmt keine Gewähr für die ununterbrochene Verfügbarkeit der Dienste.</li>
        <li>6.3 Für Schäden, die aus der Nutzung der Plattform entstehen, haftet die Plattform nur bei Vorsatz oder grober Fahrlässigkeit.</li>
    </ul>

    <h2>7. Kündigung</h2>
    <ul>
        <li>7.1 Content Creators können ihr Konto jederzeit ohne Angabe von Gründen kündigen.</li>
        <li>7.2 Die Plattform behält sich das Recht vor, das Konto eines Content Creators bei Verstoß gegen diese AGB oder gesetzliche Bestimmungen zu sperren oder zu kündigen.</li>
    </ul>

    <h2>8. Änderungen der AGB</h2>
    <p>Die Plattform behält sich das Recht vor, diese AGB jederzeit zu ändern. Änderungen werden den Content Creators per E-Mail mitgeteilt. Widerspricht der Content Creator den Änderungen nicht innerhalb von 14 Tagen, gelten die geänderten AGB als akzeptiert.</p>

    <h2>9. Schlussbestimmungen</h2>
    <ul>
        <li>9.1 Es gilt das Recht der Bundesrepublik Deutschland.</li>
        <li>9.2 Sollten einzelne Bestimmungen dieser AGB unwirksam sein, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.</li>
        <li>9.3 Gerichtsstand ist der Sitz der Plattform, soweit gesetzlich zulässig.</li>
    </ul>
</div>
    )
}

export default TermsOfService
