import React, { useState, useEffect, forwardRef } from 'react'
import { FaAngleRight } from 'react-icons/fa';
import BuyButton from './BuyButton';
import { Navigate, useNavigate } from 'react-router-dom';
import { urlParser } from '../utils'
import OutfitItem from './OutfitItem';
import { MdMenu } from 'react-icons/md';
import StandartButton from './StandartButton';
import { customHistory } from '../CustomBrowserRouter';
import { BsArrowLeft } from 'react-icons/bs';
import shirtFull from "../assets/shirtFull.png"
import shirtEmpty from "../assets/shirtEmpty.png"
import { useSelector } from 'react-redux';
import { PiDotsThreeOutlineVerticalDuotone } from 'react-icons/pi';
import { t } from 'i18next'
import { auth } from '../firebase';
import { IoSearch } from "react-icons/io5";

const VideoCard = forwardRef(({video, muted=false, inFocus, type="feed"}, ref) => {
 

    function editVideoInfos(){
      customHistory.push("/addvideo?id=" + video.id)
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [videoInfoData, setVideoInfoData] = useState(video?.videoInfo)
    // console.log(video.id)

    const [showAllBuyButton, setShowAllBuyButton] = useState(false)
    // const boughtVideo = useSelector((state) => state.user.boughtVideos?.find((_boughtVideo)=> _boughtVideo.id === video?.id))

    const subscribedVideo = useSelector((state) => state.user.subscribedVideos?.find((_subscribedVideo)=> _subscribedVideo.id === video?.id))
    const distinctlyBoughtVideo = useSelector((state) => state.user.boughtVideos?.find((_boughtVideo)=> _boughtVideo.id === video?.id))

    function checkIfOwnVideo(){
      if(auth.currentUser){
        const userId = auth.currentUser.uid
        const videoCreator = video?.user?.id
        const videoCreatorOwnFeed = video?.userId
        if(videoCreator === userId || videoCreatorOwnFeed === userId){
          // console.log("videoInfos userId: ", userId)
          // console.log("videoInfos videoCreator: ", videoCreator)
          return true
        }
        return false
      }
      return false
    }

    function checkIfUser(){
      if(auth.currentUser){
        if(auth.currentUser.uid){
          return true
        }
        return false
      }
      return false
    }

    let boughtVideo = null
    if (subscribedVideo) {
      boughtVideo = subscribedVideo
    } else { 
      boughtVideo = distinctlyBoughtVideo
    }

    const boughtVideoInfo = boughtVideo?.videoInfo
    // console.log("boughtVideoInfo", boughtVideoInfo)
    const [boughtStatus, setBoughtStatus] = useState(checkBoughtStatus())
    function checkBoughtStatus() {
     if (!boughtVideoInfo) {
      return "none"
     }
    //  let boughtItems = 0
    //  if (!videoInfoData) {
    //   return
    //  }
    //  videoInfoData.forEach(item => {
    //     if (item.name || item.name === "") {
    //       boughtItems++
    //     }
    //   })
    //   if (boughtItems === videoInfoData.length) {
    //     return "all"
    //   } else if (boughtItems > 0 && boughtItems < videoInfoData.length) {
    //     return "some"
    //   } else {
    //     return "none"
    //   }
      if (boughtVideoInfo.length >= videoInfoData.length) {
        return "all"
      } else if (boughtVideoInfo.length > 0 && boughtVideoInfo.length < videoInfoData.length) {
        return "some"
      } else {
        return "none"
      }
    }

    useEffect(() => {
      if (videoInfoData !== undefined) {
        setBoughtStatus(checkBoughtStatus())
      }
    }, [videoInfoData, boughtVideo])

    if (!video) {
      return (
        <div></div>
      )
    }
    const url = urlParser(video.downloadURL)

  //  const navigate = useNavigate()
    return (
<>
      <video data-id={video.id} src={url} poster={video.previewImageDownloadURL} autoPlay={true} controls={false} muted={true} loop={true} playsInline={true} className="slideContent" ref={ref} style={{"objectFit": "cover"}}/>
           
           {modalIsOpen && <>
           <div onClick={() => setIsOpen(false)} style={{width: "100%", height: "100%", backgroundColor: "black", opacity: 0.5, position: "absolute", top: 0}}></div>
            <div className="outfit-info">
              {videoInfoData && videoInfoData.map((item, index) => {
                // console.log("boughtVideoInfoaadsd", boughtVideoInfo)
                const foundBoughtVideoInfo = boughtVideoInfo?.find((_boughtVideoInfo) => _boughtVideoInfo.id === item.id)
                // console.log("foundBoughtVideoInfo", foundBoughtVideoInfo)
               return <OutfitItem item={item} index={index} video={video} key={"itemIndex"+index} setShowAllBuyButton={setShowAllBuyButton} foundBoughtVideoInfo={foundBoughtVideoInfo}/>
              })}
             
             {boughtStatus === "none" && 
             /*onResolve={(_videoInfoData)=>{setVideoInfoData(_videoInfoData); setIsOpen(true)}}*/
             <>
             {checkIfUser() ? <>
              <BuyButton inFocus={inFocus} title={"Complete Outfit:    3 Coins"} style={{margin: 0, marginTop: 32}} video={video} />
             </>:<>
              <BuyButton inFocus={inFocus} title={"Complete Outfit:    3 Coins"} style={{margin: 0, marginTop: 32}} video={video} />
             </>}
             </>
             }
            </div>
            </>}
            {type === "feed" ?
            <>
                        <div onClick={()=>customHistory.push("menu")} style={{position: "absolute", top: 15, left: 20}}>
                          <MdMenu color={"white"} size={30} />
                        </div>
                        {checkIfOwnVideo() && <>
                          <div onClick={()=>editVideoInfos()} style={{position: "absolute", top: 60, right: 20}}>
                            <PiDotsThreeOutlineVerticalDuotone color={"white"} size={30} />
                          </div>
                        </>}
                        <div onClick={()=>customHistory.push("search")} style={{position: "absolute", top: 15, right: 20}}>
                            <IoSearch   color={"white"} size={30} />
                          </div>
                        
            </>
            :
            <>
            <div style={{position: "absolute", left: 16, top: 19}}>
                <BsArrowLeft onClick={() =>customHistory.back()} color={"white"} size={25} />
            </div>
            {checkIfOwnVideo() && <>
            <div onClick={()=>editVideoInfos()} style={{position: "absolute", top: 15, right: 20}}>
                                    <PiDotsThreeOutlineVerticalDuotone color={"white"} size={30} />
                                  </div>
                                  </>}
            </>
          }
          (<div onClick={() => customHistory.push(`/userProfile/${video.user.id}`)}><p style={{position: "absolute", bottom: 120, left: 20, color: "white", fontWeight: "bold", fontSize: 17}}>{video.user.displayName}</p></div>)
          {type === "feed" ?
          <>
          <div onClick={() => customHistory.push(`/userProfile/${video.user.id}`)}><div style={{position: "absolute", bottom: 120, right: 30, width: 75, height: 75, borderRadius: 50, borderColor: "white", borderWidth: 5, borderStyle: "solid", backgroundSize: "cover", backgroundImage: `url("${urlParser(video.user.profileImageDownloadURL)}")`}}></div></div>
          </>
          :
          <>
          <div onClick={() => customHistory.push(`/userProfile/${video.user.id}`)}><div style={{position: "absolute", bottom: 120, right: 30, width: 75, height: 75, borderRadius: 50, borderColor: "white", borderWidth: 5, borderStyle: "solid", backgroundSize: "cover", backgroundImage: `url("${urlParser(video.user.profileImageDownloadURL)}")`}}></div></div>
          </>}
  <div style={{position: "absolute", bottom: 30, width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
  <div style={{width: "87%"}}>
  {/* {video.videoInfo && video.videoInfo.length > 0 && <StandartButton title="GET INFO" style={{height: 52}} action={() => customHistory.push("/menu")}></StandartButton>} */}
  
  {/*Das ist die alte Variante für die Coins funktioniert gut: */}
  {/* {video.videoInfo && video.videoInfo.length > 0 && <StandartButton title={!boughtVideoInfo ? t("getItems").toUpperCase() : t("showInfo").toUpperCase()} image={boughtStatus === "some" && shirtEmpty || boughtStatus === "all" && shirtFull} style={{height: 52}} action={() => setIsOpen(true)}></StandartButton>} */}
  
  {video.videoInfo && video.videoInfo.length > 0 && 

  <StandartButton title={t("showInfo").toUpperCase()} image={shirtFull} style={{height: 52}} action={() => setIsOpen(true)}></StandartButton>


  }

  
  {/* <BuyButton inFocus={inFocus} videoId={videoId} onResolve={(videoInfoData)=>{setVideoInfoData(videoInfoData); setIsOpen(true)}}/> */}
  
  </div>
  </div>
  
  {/* <ReactModal openModal={() => setIsOpen(!modalIsOpen)} closeModal={() => setIsOpen(false)} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} >
      {[{name: "Figurbetontes Jerseyshirt", color: "white"}, {name: "Straight High Jeans", color: "white"}, {name: "Flauschiges Off-Shoulder-Kleid", color: "white"}, {name: "Kleid aus Jacquardstrick", color: "white"}].map((item, index) => {
                return (
                  <p style={{}} key={"itemIndex"+index}>{item.name}</p>
                )
              })}
              </ReactModal> */}
              </>

    )
  })

export default VideoCard

