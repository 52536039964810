import { useEffect, useState, createContext } from "react"
import { Routes, Route, Navigate} from "react-router-dom"
import { useSelector } from 'react-redux'

import {CustomBrowserRouter, customHistory} from "./CustomBrowserRouter"
//mainRun
import "./mainRun"

//screens
import Login from './screens/Login'
import Register from './screens/Register'
import Entry from './screens/Entry'
import VideoPlayer from './screens/VideoPlayer'
import EmailConfirmation from './screens/EmailConfirmation/EmailConfirmation'
import EmailConfirmationComplete from './screens/EmailConfirmation/EmailConfirmationComplete'
import UserProfileOld from "./screens/UserProfile/UserProfile"
import UserProfile from "./screens/UserProfile"
import UpdateProfile from "./screens/UpdateProfile/UpdateProfile"
import AddVideo from "./screens/AddVideo/AddVideo"
import ShareVideo from "./screens/ShareVideo/ShareVideo"
import Settings from "./screens/Settings/Settings"
import CoinShop from "./screens/CoinShop"
import PaymentSetup from "./screens/PaymentSetup"
import PaymentSetupReturnPage from "./screens/PaymentSetupReturnPage"
import CreatorApplication from "./screens/CreatorApplication"
import AddVideoInfo from "./screens/AddVideoInfo"
import Menu from "./screens/Menu"
import OutfitLibrary from "./screens/OutfitLibrary"
import PaymentMethods from "./screens/PaymentMethods"
import ProfileVideoPlayer from "./screens/ProfileVideoPlayer"
import OutfitLibraryVideoPlayer from "./screens/OutfitLibraryVideoPlayer"
import SubscriptionIntro from "./screens/SubscriptionIntro"
import SubscriptionThankYou from "./screens/SubscriptionThankYou"
import CreatorApplicationIntro from "./screens/CreatorApplicationIntro"
import CreatorApplicationWaiting from "./screens/CreatorApplicationWaiting"
import CreatorApplicationForm from "./screens/CreatorApplicationForm"
import ConnectAccountReturn from "./screens/ConnectAccountReturn"
import ConnectAccountRefresh from "./screens/ConnectAccountRefresh"
import AddPaymentMethodSuccess from "./screens/AddpaymentMethodSuccess"
//import ChangeAccountInformation from "./screens/ChangeAccountInformation"
import ForgotPassword from "./screens/ForgotPassword"
import Subscriptions from "./screens/Subscriptions"
import SubscriptionInfo from "./screens/SubscriptionInfo"
import CancelSubscriptionSuccess from "./screens/CancelSubscriptionSuccess"
import invoices from "./screens/Invoices"
import Invoices from "./screens/Invoices"
import store from "./redux/store"
import ReactModal from "./components/ReactModal"
import { LightButton } from "./screens/Settings/Style"
import { DarkButton } from "./screens/Settings/Style"
import SearchPage from "./screens/SearchPage"
import Faq from "./screens/Faq"
import MyEarnings from "./screens/MyEarnings"

import CookieConsent from "react-cookie-consent";

import Imprint from "./screens/Legal/Imprint"
import PrivacyPolicy from "./screens/Legal/PrivacyPolicy"
import TermsOfService from "./screens/Legal/TermsOfService"

import { setNavigation } from "./redux/navigationSlice"
import { colors } from "./theme/Colors"

export const MyContext = createContext();

function NavigationCentral() {
    const baseUrl = window.location.origin;
    const user = useSelector(state => state.user.user)
   
    const [historyLocations, setHistoryLocations] = useState([])

    const [password, setPassword] = useState("")
    const [curtain, setCurtain] = useState(false)
    const currentCurtainPassword = "sunshine"
    useEffect(() => {
        const ls_curtainPassword = localStorage.getItem("curtainPassword")
        if (ls_curtainPassword === currentCurtainPassword) {
            setCurtain(false)
        }
    }, [])

    useEffect(()=>{ 
        if (password === currentCurtainPassword) {
            setCurtain(false)
            localStorage.setItem("curtainPassword", password)
        }
    }, [password])

    const scrollCount = useSelector((state) => state.user.scrollCounter);
    // console.log(scrollCount)
    
    useEffect(() => {
        const ls_historyLocations = JSON.parse(localStorage.getItem("historyLocations"))
        if (ls_historyLocations) {
            setHistoryLocations(ls_historyLocations)
        }

        const unlisten =  customHistory.listen((location, action) => {
            setHistoryLocations(prev => {
                let newHistory = [location.location.pathname, ...prev].slice(0, 10);
                store.dispatch(setNavigation(newHistory))
                localStorage.setItem("historyLocations", JSON.stringify(newHistory))
                return newHistory
            })
        })
        console.log(historyLocations)
        return () => unlisten();
    }, [])

    if (curtain) {
        return (
            <div style={{width: "100%", height: "100%", backgroundColor: "gray", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <input style={{width: 200, backgroundColor: "white", borderRadius: 2, border: "none"}} type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            </div>
        )
    }

    return (
        <MyContext.Provider value={historyLocations}>
        <CustomBrowserRouter>
            <Routes>
                {/* <Route path="/" element={<Entry />} /> */}
                {/* <Route path="/login" element={<Login />} /> */}
                {/* <Route path="/register" element={<Register />} /> */}
                {/* <Route path="/videoplayer" element={<VideoPlayer />} /> */}
                {/* <Route path="/userProfile/:id" element={<UserProfile />} /> */}
                {/* <Route path="/userProfile/videos" element={<ProfileVideoPlayer />} /> */}
                {/* <Route path="/menu" element={<Menu />} /> */}
                {/* <Route path="/connect-account-return" element={<ConnectAccountReturn />} /> */}
                {/* <Route path="/connect-account-refresh" element={<ConnectAccountRefresh />} /> */}
                {/* <Route path="/subscriptionintro" element={<SubscriptionIntro />} /> */}

                {user &&
                    <>
                    {user.emailConfirmed ?
                        <>
                        <Route path="/" element={<Entry />} />
                        {/* <Route path="/" element={<EmailConfirmation />} /> */}
                        <Route path="/videoplayer" element={<VideoPlayer />} />
                        <Route path="/userProfile/:id?" element={<UserProfile />} />
                        <Route path="/userProfile/videos" element={<ProfileVideoPlayer />} />
                        <Route path="/updateProfile" element={<UpdateProfile />} />
                        <Route path="/addvideo" element={<AddVideo />} />
                        <Route path="/yourEarnings" element={<MyEarnings />} />
                        <Route path="/sharevideo" element={<ShareVideo />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/coinshop" element={<CoinShop />} />
                        <Route path="/paymentsetup" element={<PaymentSetup />} />
                        <Route path="/paymentsetupreturn" element={<PaymentSetupReturnPage />} />
                        <Route path="/creatorapplication" element={<CreatorApplication />} />
                        <Route path="/addvideoinfo" element={<AddVideoInfo />} />
                        <Route path="/menu" element={<Menu />} />
                        <Route path="/outfitlibrary" element={<OutfitLibrary />} />
                        <Route path="/outfitlibrary/videos" element={<OutfitLibraryVideoPlayer />} />
                        <Route path="/connect-account-return" element={<ConnectAccountReturn />} />
                        <Route path="/connect-account-refresh" element={<ConnectAccountRefresh />} />
                        <Route path="/paymentmethods" element={<PaymentMethods />} />
                        <Route path="/subscriptionintro" element={<SubscriptionIntro />} />
                        <Route path="/subscriptionthankyou" element={<SubscriptionThankYou />} />
                        <Route path="/creatorapplicationintro" element={<CreatorApplicationIntro />} />
                        <Route path="/creatorapplicationwaiting" element={<CreatorApplicationWaiting />} />
                        <Route path="/creatorapplicationform" element={<CreatorApplicationForm />} />
                        <Route path="/add-paymentmethod-success" element={<AddPaymentMethodSuccess />} />
                        {/* <Route path="/userProfileOld/:id" element={<UserProfileOld />} /> */}
                        {/* <Route path="/change-account-information" element={<ChangeAccountInformation />} /> */}
                        <Route path="/subscriptions" element={<Subscriptions />} />
                        <Route path="/subscription-info" element={<SubscriptionInfo />} />
                        <Route path="/cancel-subscription-success" element={<CancelSubscriptionSuccess />} />
                        <Route path="/invoices" element={<Invoices />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/search" element={<SearchPage/>} />
                        <Route path="/faq" element={<Faq/>} />
                        </>
                        
                        :
                        <>
                        <Route path="/register" element={<EmailConfirmation />} />
                        <Route path="/" element={<EmailConfirmation />} />
                        <Route path="/email-confirmation-complete/:uid" element={<EmailConfirmationComplete />} />
                        </>
                    }
                    </>  
                }
                <Route path="/" element={<Entry />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/videoplayer" element={<VideoPlayer />} />
                <Route path="/userProfile/:id?" element={<UserProfile />} />
                <Route path="/userProfile/videos" element={<ProfileVideoPlayer />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/connect-account-return" element={<ConnectAccountReturn />} />
                <Route path="/connect-account-refresh" element={<ConnectAccountRefresh />} />
                   {/* <Route path="/userProfileOld/:id" element={<UserProfileOld />} /> */}
                {/* <Route path="/userProfile/videos" element={<ProfileVideoPlayer />} /> */}
                <Route path="/subscriptionintro" element={<SubscriptionIntro />} />
                <Route path="/email-confirmation-complete/:uid" element={<EmailConfirmationComplete />} />
                <Route path="/search" element={<SearchPage/>} />
                <Route path="/imprint" element={<Imprint/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>
            {scrollCount > 4 && !user && (
                <div style={{position: "fixed", flexDirection:"column", padding:"10px", gap:"10px", top: "0px", left: "0px", width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.75)", display:"flex", justifyContent:"center", alignItems:"center", zIndex:"9000"}}>
                    <p style={{color:"white"}}>Um weiter Video angucken zu können benötigst du einen Account!</p>
                    <div style={{width: "100%", display:"flex", gap:"15px", flexDirection: "row", justifyContent:"center", alignItems:"center"}}>
                        <DarkButton style={{width:"40%"}} onClick={() => window.location.href = `${window.location.origin}/register`}>Registrieren</DarkButton>
                        <LightButton style={{width:"40%"}} onClick={() => window.location.href = `${window.location.origin}/login`}>Einloggen</LightButton>
                    </div>
                </div>
            )}
            
            <CookieConsent
            buttonText="Akzeptieren"
            declineButtonText="Ablehnen"
            buttonStyle={{ color: "#fff", backgroundColor: colors.primary}}
            declineButtonStyle={{ color: "#fff", backgroundColor: colors.primary}}
            enableDeclineButton
            >Diese Website verwendet Cookies, um die Erfahrung zu verbessern</CookieConsent>
        </CustomBrowserRouter>
        </MyContext.Provider>
    )
}

export default NavigationCentral
