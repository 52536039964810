import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCreatorProfile, getCreatorVideos } from '../sideEffects';
import ReactModal from '../components/ReactBottomModal'
import { ModalContainer, ModalTop } from './UserProfile/Style'
import { BiLink} from 'react-icons/bi'
import { auth } from '../firebase'

import './UserProfile.css';
import Back from '../components/Back';
import Home from '../components/Home';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import FloatingButton from '../components/FloatingButton'
import { AiFillHeart, AiFillEye, AiOutlineSetting, AiOutlinePlus } from 'react-icons/ai'
import { BsBookmarkDash } from 'react-icons/bs'

import { useNavigate } from 'react-router-dom';
import { FaChartLine } from "react-icons/fa";
import { FiEdit3 } from 'react-icons/fi'
import { CgMoreO } from 'react-icons/cg'
import { t } from 'i18next';
import storePinBG from '../assets/storepin-bg.jpg'
import { colors } from '../theme/Colors';
import toast, { Toaster } from 'react-hot-toast'
let ownCreatorProfile = false;
let id;

function UserProfile() {
    const { id: paramId } = useParams();
    const firebaseUser = useSelector((state) => state.user.firebaseUser);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate()
    id = paramId;

    const [creatorData, setCreatorData] = useState({
        profileImageDownloadURL: '',
        username: '',
        displayName: '',
        bio: '',
        website: '',
    });
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastDocId, setLastDocId] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [editProfile, setEditProfile] = useState(true)
    const [hasMore, setHasMore] = useState(true);
    const { ref, inView } = useInView();

    useEffect(() => {
        //if id is not present, get the id from firebase auth
        if (!id || id === firebaseUser?.uid) {
            //get the id from firebase auth
            //if id is not present, redirect to home page
            console.log('id not present')
            id = firebaseUser.uid
            console.log(id)
            ownCreatorProfile = true;
        }

        async function loadCreatorProfile() {
            try {
                const creatorProfileData = await getCreatorProfile(id);
                console.log(creatorProfileData);
                setCreatorData(creatorProfileData.data);
            } 
            catch (error) {
                console.log(error);
                navigate('/')
            }
        }
         
        loadCreatorProfile();
    }, []);

    function getBaseURL() {
        const currentURL = window.location.href;
        const urlObject = new URL(currentURL);
        const baseURL = urlObject.origin + '/';
        return baseURL;
      }

    function copyProfileLink(){
        console.log("copyProfileLink clicked")
        const profileId = auth.currentUser.uid
        const url = getBaseURL()
        const profileLink = url + "userProfile/" + profileId
        navigator.clipboard.writeText(profileLink).then(function() {
            toast.success('Profil-Link kopiert',
            {
                style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                },
            }
            );
          }, function(err) {
            toast.error('Fehler beim kopieren',
            {
                style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                },
            }
            );
          });
    }

    const loadCreatorVideos = async () => {
        if (loading || !hasMore) return;

        setLoading(true);

        const creatorVideosData = await getCreatorVideos(id, lastDocId);
        const { videos, lastDocId: newLastDocId } = creatorVideosData.data;
        console.log("myVideos", videos);
        if (videos.length > 0) {
            setVideos((prevVideos) => [...prevVideos, ...videos]);
            setLastDocId(newLastDocId);
            if (videos.length < 9) {
                setHasMore(false);
            }
        } else {
            setHasMore(false);
        }

        setLoading(false);
    };

    useEffect(() => {
        loadCreatorVideos();
    }, [id]);

    useEffect(() => {
        if (inView) {
            loadCreatorVideos();
        }
    }, [inView]);

    function openModal() {
        setIsOpen(!modalIsOpen);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const selectEdit = () => {
        setEditProfile(!editProfile)
        navigate('/updateProfile')
        // setTimeout(() => {
        //     navigate('/updateProfile')
        // }, 1000)
    }

    return (
        <div className="profile-card">
            <Back />
            <Home />
            {
                ownCreatorProfile &&
           
            <div style={{zIndex: 999, position: "absolute", right: 17, top: 18, display: "flex", alignItems: "center"}}>
            <div onClick={copyProfileLink} style={{display: "flex", justifyContent:"center", alignItems: "center", height:"30px", width:"45px", backgroundColor:'#6949FF', borderRadius:"12px", marginRight: 13}}>
                <BiLink size="1.5em" style={{color:'white'}}></BiLink>
            </div>
            <FiEdit3 onClick={selectEdit} size={25} color={colors.primary} style={{marginRight: 10}}/>
                {/* <CgMoreO onClick={openModal} size={25}  color={colors.primary}/> */}
            </div>
             }
            {
                creatorData?.username &&
                <>
                <div style={{zIndex: 1}}>
                <img src={storePinBG} className="profile-bg-img" />
                </div>
                <div className="profile-content">
                <div className="profile-header">
                    {creatorData.profileImageDownloadURL ? (
                        <img src={creatorData.profileImageDownloadURL} alt="Profile" className="profile-image" />
                    ) : (
                        <div className="profile-placeholder"></div>
                    )}
                    <div className="creator-info">
                    <h2>{creatorData?.displayName}</h2>
                    <h3>@{creatorData?.username}</h3>

                    { ownCreatorProfile &&
                    <h3 onClick={() => navigate('/yourEarnings')} className="yourEarnings" style={{color: colors.primary}}><FaChartLine style={{marginRight: 5}}/>{t("yourEarnings")}</h3>
                    }
                    </div>
                </div>
                    <div className="profile-gallery">
                        {videos?.length > 0 && (
                            videos?.map((video, index) => (
                                // <div key={video.id} className="gallery-image" onClick={() => navigate('/userProfile/videos', {state: {contentPosition: index, videos: videoStack}})}>
                                <div key={video.id} className="gallery-image" onClick={() => navigate('/userProfile/videos', {state: {contentPosition: index, videos: videos}})}>

                                    {video.previewImageDownloadURL ? (
                                        <img src={video.previewImageDownloadURL} alt={`Additional ${index}`} />
                                    ) : (
                                        <div className="gallery-placeholder"></div>
                                    )}
                                </div>
                            ))
                        )}

                    </div>
                </div>
                {user?.creatorStatus === "active" && ownCreatorProfile && <FloatingButton action={() => navigate('/addvideo')} />}
            </>}
            <ReactModal openModal={openModal} closeModal={closeModal} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} >
                <ModalContainer >
                    <ModalTop>
                        <AiOutlineSetting size={20} />
                        <p onClick={() => navigate('/settings')} style={{ paddingLeft: '10px', cursor: 'pointer' }} >Settings</p>
                    </ModalTop>
                    <ModalTop>
                        <BsBookmarkDash size={20} />
                        <p onClick={() => navigate('/saved')} style={{ paddingLeft: '10px', cursor: 'pointer' }}>Saved</p>
                    </ModalTop>
                </ModalContainer>
            </ReactModal>
            <Toaster
            position="bottom-center"
            reverseOrder={false}
            />
        </div>
    );
}

export default UserProfile;

