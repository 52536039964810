import { t } from 'i18next'
import React, { useRef, useState, useEffect } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { UpdateProfileContainerHeader, UpdateProfileContainerHeaderRight, BackgroundImageWrapper, UpdateIconWrapper, UpdateProfileContainerTopUserImage, ProfileImageWrapper, UpdateProfileInputContainer, UserInput, UserInputIcon, UserInputSelect, UserInputWithIconWrapper, FormError } from './Style'
import user from '../../assets/noImage.png'
import { MdEdit } from 'react-icons/md'
//import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import StandartInput from '../../components/StandartInput'
import { InputIcon, InputWithIconWrapper } from '../../components/StandartStyles'
import { CgCalendarDates } from "react-icons/cg";
import { AiOutlineMail } from "react-icons/ai";
import { colors } from '../../theme/Colors'
import StandartButton from '../../components/StandartButton'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/userSlice'
import { useNavigate } from 'react-router-dom'
import BottomNavigation from '../../components/BottomNavigation'
import { uploadProfileImage } from '../../sideEffects'
import { uploadImage, updateProfile, checkUsernameAvailability } from '../../sideEffects'
import { useSelector } from 'react-redux'
import userPlaceholder from '../../assets/user.png';
import backgroundPlaceholder from '../../assets/mountain.png';
import { urlParser } from '../../utils'
import StandartHeader from '../../components/StandartHeader';

const UpdateProfile = () => {
  //get user from redux
  const user = useSelector(state => state.user.user)

  const [currentProfileImage, setCurrentProfileImage] = useState(null);
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);

  const [currentBackgroundImage, setCurrentBackgroundImage] = useState(null);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState("")
  const [userNameUp, setUserNameUp] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")
  const [gender, setGender] = useState("")

  const [errors, setErrors] = useState([])
  const backgroundImageRef = useRef()
  const profileImageRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const input2Ref = useRef(null);

  useEffect(() => {
    let timeoutId;

    async function checkUsername() {
      const _username = userNameUp
      let result = await checkUsernameAvailability(_username)
      if (result.data.available === false) {
        setErrors(["usernameTaken"])
      } else {
        setErrors([])
      }
    
    }

    const handleTypingFinished = () => {
      clearTimeout(timeoutId);
      checkUsername();
    };

    const handleKeyPress = () => {
      clearTimeout(timeoutId);
    };

    const handleKeyUp = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleTypingFinished, 1000);
    };

    document.addEventListener('keypress', handleKeyPress);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
      document.removeEventListener('keyup', handleKeyUp);
      clearTimeout(timeoutId);
    };
  }, [userNameUp]);


  let destination = "profile"
  if (user.stripeConnectedAccountId && user.stripeConnectedAccountStatus !== "active") {
    destination = "refresh"
  } else if (user.creatorStatus === "approved") {
    destination = "creatorapplication"
  }

useEffect(() => {
  
  if (user) {
    setDisplayName(user.displayName)
    setUserNameUp(user.userName)

    setCurrentProfileImage(urlParser(user.profileImageDownloadURL))
    setCurrentBackgroundImage(urlParser(user.backgroundImageDownloadURL))
  }
}, [user])

  const handleBackgroundImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
     setSelectedBackgroundImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  async function checkUsername2() {
    const _username = userNameUp
    let result = await checkUsernameAvailability(_username)
    if (result.data.available === false) {
      setErrors(["usernameTaken"])
      return false
    } else {
      setErrors([])
      return true
    }
  
  }

  const handleProfileImageUpload = (e) => {

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedProfileImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  async function validate() {
    
    if (displayName === "" || !displayName) {
      setErrors(["noDisplayName"])
      return false
    } else {
      const newErrors = errors.filter(error => error !== "noDisplayName")
      setErrors(newErrors)
    }

    if(checkUsername2()==false){
      setErrors(["noDisplayName"])
      return false
    }

    if (userNameUp === "" || !userNameUp) {
      setErrors(["noUserName"])
      return false
    } else {
      const newErrors = errors.filter(error => error !== "noUserName")
      setErrors(newErrors)
    }

    if(userNameUp){
      const _username = userNameUp
      let result = await checkUsernameAvailability(_username)
      if (result.data.available === false) {
        setErrors(["usernameTaken"])
        return false
      }
    }

    if(errors==["usernameTaken"]){
      return false
    }

    console.log("currentProfileImage", currentProfileImage)
    console.log("selectedProfileImage", selectedProfileImage)
    if (!currentProfileImage && !selectedProfileImage) {
      setErrors(["noProfileImage"])
      return false
    } else {
      const newErrors = errors.filter(error => error !== "noProfileImage")
      setErrors(newErrors)
    }

    if (!currentBackgroundImage && !selectedBackgroundImage) {
      setErrors(["noBackgroundImage"])
      return false
    } else {
      const newErrors = errors.filter(error => error !== "noBackgroundImage")
      setErrors(newErrors)
    }

    return true
  }

  async function submit() {
    setLoading(true)
    if (!(await validate())) {
      setLoading(false);
      return;
    }
    

    let thisBackgroundImageDownloadURL = user.backgroundImageDownloadURL
    let thisProfileImageDownloadURL = user.profileImageDownloadURL

    let thisBackgroundImageId = user.backgroundImageId
    let thisProfileImageId = user.profileImageId

    if (selectedBackgroundImage) {
      let backgroundImageResult = await uploadImage(selectedBackgroundImage)
      thisBackgroundImageId = backgroundImageResult.id
      thisBackgroundImageDownloadURL = backgroundImageResult.url
    } 
    
    if (selectedProfileImage) {
      let profileImageResult = await uploadImage(selectedProfileImage)
      thisProfileImageId = profileImageResult.id
      thisProfileImageDownloadURL = profileImageResult.url
    }

    const userName = userNameUp.toLowerCase()
    const newUser = {
      displayName,
      userName,
      dateOfBirth,
      gender,
      backgroundImageId: thisBackgroundImageId,
      profileImageId: thisProfileImageId,
      profileImageDownloadURL: thisProfileImageDownloadURL,
      backgroundImageDownloadURL: thisBackgroundImageDownloadURL
    }

    updateProfile(newUser).then((result) => {
      if (destination === "profile") {
      navigate("/userProfile/me")
    } else if (destination === "refresh") {
      navigate("/connect-account-refresh")
    } else if (destination === "creatorapplication") {
      navigate("/creatorapplication")
    }})

    //dispatch(setUser({ banner: selectedBannerImage, profile: selectedUserImage }))
    //navigate("/userProfile")
  }

  function handleBackgroundImageClick(e) {
    e.stopPropagation()
    backgroundImageRef.current.click();
  }

  function handleProfileImageClick(e) {
    e.stopPropagation()
    profileImageRef.current.click();
  }

  // useEffect(() => {
  //   if (userPlaceholder) {
  //     setProfileImage(userPlaceholder)
  //   }
  //   if (backgroundPlaceholder) {
  //     setBackgroundImage(backgroundPlaceholder)
  //   }
  // }, [])

const displayProfileImage = () => {
  if (selectedProfileImage) {
    return selectedProfileImage
  } else if (currentProfileImage) {
    return currentProfileImage
  } else {
    return userPlaceholder
  }
}

const displayBackgroundImage = () => {
  if (selectedBackgroundImage) {
    return selectedBackgroundImage
  } else if (currentBackgroundImage) {
    return currentBackgroundImage
  } else {
    return backgroundPlaceholder
  }
}

const handleSubmit = (e, inputIndex) => {
  console.log('handleSubmit', inputIndex);
  e.preventDefault();

  switch (inputIndex) {
    case 1:
      input2Ref.current.focus();
      break;
    case 2:
      submit()
      break;
    default:
      break;
  }
};

const handleInputChangeDisplayName = (event) => {
  // Begrenze die Eingabe auf 20 Zeichen
  const inputValue = event.target.value.slice(0, 20);
  setDisplayName(inputValue);
};

const handleInputChangeUserNameUp = (event) => {
  // Begrenze die Eingabe auf 20 Zeichen
  const inputValue = event.target.value.slice(0, 20);
  setUserNameUp(inputValue);
};

  return (
    <div style={{overflowY: "scroll", height: "100%", overflowX: "hidden"}}>
      <StandartHeader title={t("fillProfile")} />

      <BackgroundImageWrapper>
        <ProfileImageWrapper style={{ position: 'relative' }} >
          <UpdateProfileContainerTopUserImage onClick={handleProfileImageClick} src={displayProfileImage()} />
          <UpdateIconWrapper style={{bottom: 10, top: "auto"}} onClick={handleProfileImageClick}>
            <MdEdit color='white' />
          </UpdateIconWrapper>
        </ProfileImageWrapper>
      </BackgroundImageWrapper>

      <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", padding: "100px 20px 20px 20px"}}>

      {errors.includes("noProfileImage") && <FormError>{t("noProfileImage")}</FormError>}
      {errors.includes("noBackgroundImage") && <FormError>{t("noBackgroundImage")}</FormError>}

        <StandartInput placeholder={t("displayName")} value={displayName} onChange={handleInputChangeDisplayName} tabIndex="0" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 1)} enterKeyHint={"next"}/>
        {errors.includes("noDisplayName") && <FormError>{t("noDisplayName")}</FormError>}

        <StandartInput placeholder={t("username")} value={userNameUp} onChange={handleInputChangeUserNameUp}  style={{marginTop: 20}} tabIndex="1" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 2)} ref={input2Ref} enterKeyHint={"done"}/>
        {errors.includes("usernameTaken") && <FormError>{t("usernameTaken")}</FormError>}
        {errors.includes("noUserName") && <FormError>{t("noUserName")}</FormError>}
      </div>
      <div style={{ padding: '20px' }} >
        <StandartButton action={submit} title={destination === "profile" ? t("finish") : t("continue")} loading={loading}/>
      </div>
        <input
          style={{ display: 'none' }}
          type="file"
          ref={backgroundImageRef}
          onChange={handleBackgroundImageUpload}
   
        />
        <input
          style={{ display: 'none' }}
          type="file"
          ref={profileImageRef}
          onChange={handleProfileImageUpload}
        />
    </div>
  )
}

export default UpdateProfile