import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { colors } from '../theme/Colors'

export const Input = styled.input`
    background-color: ${colors.lightAccent};
    border: none;
    border-radius: 16px;
    height: 40px;
    width: 100%;
    height: 45px;
    border-radius: 100px;
    outline: none;
    box-sizing:border-box;
    padding-left: 30px;
    font-size: inherit;
    ::placeholder { 
  color: ${colors.inputPlaceHolder};
  opacity: 1;
  padding-left: 0;
}
`
const StandartInput = forwardRef(({onChange, placeholder, tabIndex, onKeyDown, enterKeyHint, style, value}, ref) =>{
    return (
        <Input ref={ref} value={value} onChange={onChange} placeholder={placeholder} tabIndex={tabIndex} onKeyDown={onKeyDown} enterKeyHint={enterKeyHint} style={style}>

        </Input>
    )
})

export default StandartInput
