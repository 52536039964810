import styled from 'styled-components'
import { colors } from '../../theme/Colors'

export const UpdateProfileContainerHeader = styled.div`
 padding: 20px;
 display: flex;
 align-items: center;
`
export const UpdateProfileContainerHeaderRight = styled.div`
  margin-left:10px;
  font-weight:bold
`

export const BackgroundImageWrapper = styled.div`
 height: 150px;
 display: flex;
 align-items: flex-end;
 justify-content: center;
 position: relative;
 background-color:#ffffff;
 background-image:${props => props.image ? `url(${props.image})` : ``};
 background-position: center top;
 background-repeat: no-repeat; 
 background-size: cover; 
 width: 100%;
 position:relative
`
export const ProfileImageWrapper = styled.div`
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  border: 2px solid white;
  border-radius: 100px;
  margin-bottom: -70px

`
export const UpdateProfileContainerTopUserImage = styled.img`
height: 90%;
 width: 90%;
 object-fit: cover;
 border-radius: 100px;
 position: relative;
`

export const UpdateIconWrapper = styled.div`
 position: absolute;
  top: 10px;
  right: 5px;
  background-color: ${colors.primary};
  border-radius: 5px;
  height: 25px;
  width: 25px;
   display: flex;
   justify-content: center;
   align-items: center 
`

export const UpdateProfileInputContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center ;
  padding:100px 20px 20px 20px

`

export const UserInput = styled.input`

width: 100%;
outline: none;
margin-top:10px;
height:100%;
background-color: ${colors.lightAccent};
border:none;
outline:none:;
box-sizing:border-box;
padding-left:10px;
::placeholder { 
color: ${colors.inputPlaceHolder};
opacity: 1;
}
`
export const UserInputSelect = styled.select`
width: 100%;
outline: none;
margin-top:10px;
height:100%;
background-color: ${colors.lightAccent};
border:none;
outline:none:;
box-sizing:border-box;
padding-left:10px;
::placeholder { 
color: ${colors.inputPlaceHolder};
opacity: 1;
}
`


export const UserInputIcon = styled.div`
padding-right:10px
`
export const UserInputWithIconWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background-color: ${colors.lightAccent};
border: none;
border-radius: 16px;
height: 45px;
width: 100%;
outline: none;
margin-top:20px;
box-sizing:border-box;
padding-left:20px;
::placeholder { 
color: ${colors.inputPlaceHolder};
opacity: 1;
`
export const FormError = styled.p`
color:red;
font-size: 12px;
margin-top: 12px;
text-align: left;
padding-left: 60px;
width: 100%;
`