import React from 'react'
import { t } from 'i18next'
import StandartButton from '../components/StandartButton'
import CreatorApplicationImg2 from '../assets/creatorApplication2.png'
import BottomButtonContainer from '../components/BottomButtonContainer'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { customHistory } from '../CustomBrowserRouter'
function SubscriptionThankYou() {
    const navigate = useNavigate()
    const location = useLocation();
    const creatorId = new URLSearchParams(location.search).get("id");
    return (
        <div style={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center", padding:""}}>
            <div style={{width:"90%", height:"60%"}}>
                <img src={CreatorApplicationImg2} style={{width: 160}}/>
                <h1>{t("subscriptionThankYouHeading")}</h1>
                <p>{t("subscriptionThankYouText")}</p>
                <BottomButtonContainer>
                    <StandartButton title={"Go to Profile page"} action={()=>customHistory.replace(`/userProfile/${creatorId}`)}></StandartButton>
                </BottomButtonContainer>
            </div>
        </div>
    )
}

export default SubscriptionThankYou