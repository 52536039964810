import styled from "styled-components";
import { colors } from "../../theme/Colors";

export const AddVideoContainerHeader = styled.div`
padding: 20px;
display: flex;
align-items: center;
justify-content: space-between 
`
export const ShareVideoContainerHeader = styled.div`
padding: 20px;
display: flex;
align-items: center;
justify-content: space-between 
`
export const ShareVideoBottom = styled.div`
 padding: 20px;
 position: fixed;
 bottom: 10px;
 width: 90%
`

export const ShareVideoTopContainer = styled.div`
 display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-around;
  height: 100% 
`
export const VideoPlayer = styled.video`
 border-radius: 20px;
  height: 100%;
  width: 100px;
  object-fit: cover 
`
export const ShareVideoTopRightContainer = styled.div`
 margi-left: 10px;
  background-color: colors.lightAccent;
  padding: 15px;
  border-radius: 10px 
`

export const VideoText = styled.textarea`
width: 100%;
outline: none;
margin-top:10px;
height:200px;
background-color: ${colors.lightAccent};
outline:none:;
box-sizing:border-box;
padding-left:10px;
margin-left: 14px;
padding: 19px;
font-size: 16px;
line-height: 20px;
font-family: sans-serif;
// border: 0.4px solid #dedede;
border: none;
border-radius: 10px;
::placeholder { 
color: ${colors.inputPlaceHolder};
opacity: 1;

}
`