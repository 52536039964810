import React, {useEffect} from 'react'
import { createConnectedAccount } from '../sideEffects'
import { useNavigate } from 'react-router-dom'
import { SpinnerCircularFixed } from 'spinners-react';

function CreatorApplication() {

    const navigate = useNavigate()

    async function startCreateConnectedAccount() {
        const result = await createConnectedAccount()
        if (result.status === "alreadyConnected") {
            navigate("/userProfile/me")
        } else if (result.status === "success") {
            window.location = result.url
        }
        console.log({result})
    }

    useEffect(() => {
        startCreateConnectedAccount()
    }, [])

    return (
        <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <SpinnerCircularFixed color="#000000" size={50} thickness={100} speed={200} />
        </div>
    )
}

export default CreatorApplication
