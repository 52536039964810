import { t } from 'i18next'
import React, { useRef, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { AddVideoContainerHeader } from './Style'
import StandardButton from '../../components/StandartButton'
import { ShareVideoBottom, ShareVideoContainerHeader, ShareVideoTopContainer, ShareVideoTopRightContainer, VideoPlayer } from './Style'
import ProgressBar from "@ramonak/react-progress-bar";
import { colors } from '../../theme/Colors'
import { MdClose } from 'react-icons/md'
import { VideoText } from './Style'
import { manageVideoInfo } from '../../sideEffects';
import Lottie, { useLottie } from "lottie-react";
import uploadingAnimation from "../../assets/uploading-animation.json";
import videoUploadImg from "../../assets/videoupload.png";
import BottomButtonsContainer from '../../components/BottomButtonContainer'
import { useSelector } from 'react-redux'
//uploading-animation.json
import ReactModal from '../../components/ReactModal'
import { LightButton } from '../Settings/Style'
import { DarkButton } from '../Settings/Style'

import { useNavigate } from 'react-router-dom'
import ShareVideo from '../ShareVideo/ShareVideo'
import { uploadVideo, updateVideoInfo } from '../../sideEffects'
import StandartInput from '../../components/StandartInput'
import { useEffect } from 'react';
import { deleteVideo } from '../../sideEffects'
import toast, { Toaster } from 'react-hot-toast';
import { auth } from '../../firebase'
import StandartHeader from '../../components/StandartHeader'
let globalItems = [];

function ItemCard({ item, index, onInputChange, onRemove }) {

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onInputChange(name, value);
  };


  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);

  const handleSubmit = (e, inputIndex) => {
    e.preventDefault();

    switch (inputIndex) {
      case 1:
        input2Ref.current.focus();
        break;
      case 2:
        input3Ref.current.focus();
        break;
      case 3:
        input4Ref.current.focus();
        break;
      case 4:
        input5Ref.current.focus();
        break;
      case 5:
        input6Ref.current.focus();
        break;
        break;
      default:
        break;
    }
  };

  return (
    <div className={"itemCard"} style={{margin: 20, backgroundColor: colors.lightAccent}}>
       <div className="itemEntry">
        <p style={{color: colors.primary}}>{index}. item</p>
       </div>

      <div className="itemEntry">
      <label htmlFor="typeOfCloth">
        Kleidungsstück</label>
        <input type="text" name="typeOfCloth" placeholder={"Pants"} value={item.typeOfCloth} onChange={handleInputChange} tabIndex="0" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 1)} 
         enterKeyHint="next"/>      
      </div>

      <div className="itemEntry">
          <label htmlFor="brand">
          Brand</label>
          <input type="text" name="brand" placeholder={"Zara"} value={item.brand} onChange={handleInputChange} tabIndex="2" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 3)}  enterKeyHint="next" ref={input3Ref}/>
      </div>

      <div className="itemEntry">
      <label htmlFor="codeNumber">
        Code Number</label>
        <input type="text" placeholder={"97895465"} name="codeNumber" value={item.codeNumber} onChange={handleInputChange} tabIndex="3" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 4)}  enterKeyHint="next" ref={input4Ref}/>
      </div>

      <div className="itemEntry">
      <label htmlFor="size">
          Size</label>

        <input type="text" name="size" placeholder={"M"} value={item.size} onChange={handleInputChange} tabIndex="4" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 5)} enterKeyHint="next" ref={input5Ref}/>
        </div>

        <div className="itemEntry">
      <label htmlFor="shopLink">
          Shop Link</label>

        <input type="text" name="shopLink" placeholder={"www.hm.com/pants/1313213"} value={item.shopLink} onChange={handleInputChange} tabIndex="5" onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e, 6)} enterKeyHint="next" ref={input6Ref}/>
        </div>
      
      {/* <div className="itemEntry">
      <label htmlFor="price">
        Price:</label>
        <input type="text" name="price" value={item.price} onChange={handleInputChange} />
      </div> */}

      <button onClick={onRemove} className="itemDeleteButton"><MdClose /></button>
    </div>
  );
}

const ItemGenerator = (props) => {
  const [items, setItems] = useState([{ brand: '', name: '', codeNumber: '', size: '', price: '' }]);
  // const [updateItemsStatus, setUpdateItemsStatus] = useState(false);
  
  const videoID = props.videoID

  const videoInfos = useSelector((state) => {
    const user = state.user;
    const videoArray = user.videos;
    return videoArray.find((temp) => temp.id === videoID);
  });

  // if(videoInfos && !updateItemsStatus){
  //   setItems(videoInfos.videoInfo)
  //   setUpdateItemsStatus(true)
  // }

  useEffect(() => {
    if(videoID){
      setItems([...videoInfos.videoInfo])
    }
  }, [videoInfos, videoID]);

  const handleItemInputChange = (index, name, value) => {
    const newItems = [...items];
    // newItems[index][name] = value;
    newItems[index] = { ...newItems[index], [name]: value };
    setItems(newItems);
    globalItems = newItems;
  };

  const handleAddItem = () => {
    const newItems = [...items, { description: '', brand: '', name: '', codeNumber: '', size: '', price: '' }]
    setItems(newItems);
    globalItems = newItems;
  };

  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    globalItems = newItems;
  };

  return (
    <>
        <h2>{t("whatAreYouWearing")}</h2>
        {items.map((item, index) => (
          <ItemCard
            key={index}
            item={item}
            index={index + 1}
            onInputChange={(name, value) => handleItemInputChange(index, name, value)}
            onRemove={() => handleRemoveItem(index)}
          />
        ))}
        <button onClick={handleAddItem} className="addItemButton" style={{backgroundColor: colors.lightAccent, color: colors.primary}}>Add Item</button>
    </>
  )
}

const AddVideo = () => {
  const [videoID, setVideoID] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const myUser = useSelector(state => state.user.user)

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
      setIsOpen(false);
  }
  const userId = auth.currentUser.uid
  const deleteVideoConfirm = () => {
    deleteVideo(videoID).then((result) => {
      if(auth.currentUser){
        navigate(`/userProfile/${userId}`)
        // toast.success('E-Mail-Adresse bestätigt.', {  position: 'bottom-center',duration: 5000,})
        // toast.error('Failed to call Firebase Cloud Function');
        // console.log("TOASTER")
      }
    })
  }


  function initializePage() {
    const queryParameters = window.location.search;
    const urlParams = new URLSearchParams(queryParameters);
    const videoId = urlParams.get("id");
    setVideoID(videoId)
  }
  
  useEffect(() => {
    initializePage();
  }, []);

  const navigate = useNavigate()
  const inputRef = useRef()
  const videoRef = useRef(null);
  const textRef = useRef(null);
  //const animationRef = useRef();
  const [selectedVideo, setSelectedVideo] = useState()
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [videoMaxLengthWarning, setVideoMaxLengthWarning] = useState(false);

  const [progress, setProgress] = useState(0);

  const submit = (e) => {
    
    const file = e.target.files[0]

    const videoElement = document.createElement('video');
    
    videoElement.src = URL.createObjectURL(file);
    
    videoElement.onloadedmetadata = () => {
      if (videoElement.duration > 30) {
        setVideoMaxLengthWarning(true)
        return
      } else {
        setVideoMaxLengthWarning(false)
        setSelectedVideo(file)
      }
    };

    
  }

  const update = async () => {
    if (globalItems.length === 0) {
      alert("Please add at least one item")
      return
    } else if (globalItems[0].description === "") {
      alert("Please add at least one item")
      return
    }

    try {
      // setUploading(true)
      await updateVideoInfo(setProgress, videoID, globalItems).then((result) => {
        const userId = auth.currentUser.uid
        navigate(`/userProfile/${userId}`)
      })
    } catch (error) {
      console.log("error from AddVideo", error)
    }
  }

  const share = async () => {
    if (globalItems.length === 0) {
      alert("Please add at least one item")
      return
    } else if (globalItems[0].description === "") {
      alert("Please add at least one item")
      return
    }


    //old big previewImage
    // const video = videoRef.current
    //   const canvas = document.createElement("canvas");
    //   canvas.width = video.videoWidth;
    //   canvas.height = video.videoHeight;
    //   const ctx = canvas.getContext("2d");
    //   ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    //   const previewImage = canvas.toDataURL();

    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    
    // Set canvas dimensions to match the video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
    // Create a second canvas for the scaled down image
    const scaledCanvas = document.createElement("canvas");
    scaledCanvas.width = 270;
    scaledCanvas.height = 480;
    
    const scaledCtx = scaledCanvas.getContext("2d");
    
    // Draw the image from the first canvas onto the second canvas, scaling it down
    scaledCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, scaledCanvas.width, scaledCanvas.height);
    
    // Get the data URL of the scaled-down image with reduced quality (0.7 in this case)
    const quality = 0.7; // Adjust the quality between 0 and 1 (1 being highest quality)
    const previewImage = scaledCanvas.toDataURL("image/jpeg", quality);
    
    console.log(previewImage);

console.log(previewImage)



      //const text = textRef.current.value
      try {
        setUploading(true)
        const videoId = await uploadVideo(selectedVideo, "", previewImage, setProgress, globalItems)
        //await manageVideoInfo(videoId, globalItems, "add")
        // console.log("videoId from AddVideo", videoId)
        //         //navigate('/userProfile/me')
        //         navigate('/addvideoinfo', {state: {videoId}})
        navigate('/userProfile', {state: {videoId}})
        
      } catch (error) {
        console.log("error from AddVideo", error)
      }
  }

  const remove = async () => {
    
  }

  const removeImage = () => {
    // console.log(videoID)
    if(!videoID){
      setSelectedVideo(null)
    }
    else{
      navigate(-1)
    }
  }

if (uploading) {
  return (
    <div style={{height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>

            <h1 style={{ paddingLeft: '10px' }} >{t("uploading")}</h1>
            <Lottie
        animationData={uploadingAnimation}
        loop={true}
        autoplay={true}
        //lottieRef={animationRef}
        //onComplete={handleComplete}
      />
            <div style={{ padding: '20px' ,borderBottom:'1px solid #EEEEEEw'}} >
<div>
  <div><p style={{color: colors.primary, marginBottom: 17, fontSize: "1.5em"}}>{Math.round(progress)}%</p></div>
<ProgressBar bgColor={colors.primary} customLabelStyles={{display:'none'}} completed={progress} />
</div>
</div>
    </div>
  )
}

  return (
    <div style={{height: "100%", overflowY: "scroll"}}>
      {selectedVideo || videoID ? <>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: '700', justifyContent: 'space-around', width: "100%" }} >
              
                    <BsArrowLeft onClick={removeImage} size={25} />
                    <h1>{t("outfit")}</h1>
              <div></div>
           </div>
            <div style={{height: "auto", overflowY: "scroll"}}>
              {!videoID && <ShareVideoTopContainer>
                <div style={{ height: '200px' }} >
                    {selectedVideo && 
                    <video
                    autoPlay={true}
                    muted={true}
                    ref={videoRef}
                    playsInline={true}
                    loop={true}
                    id="videoPlayer"
                    style={{ 
                      width: '100px', 
                      height: '200px', 
                      objectFit: 'cover', 
                      borderRadius: '20px' 
                    }} 
                    src={URL.createObjectURL(selectedVideo)}
                  ></video>
                    
                    }
                </div>
               
                {/* <VideoText ref={textRef} placeholder="My video description..."></VideoText> */}

                
            </ShareVideoTopContainer>}
            
           <ItemGenerator videoID={videoID}/>
            <div style={{width: "100%",  display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
              {videoID ? (
                <>
                <div style={{width: "90%", marginBottom: 20}}>
                <StandardButton title={"Update"} action={update}/>
              </div>
                <div style={{width: "90%", marginBottom: 20}}>
                <p style={{color:"red"}} onClick={openModal}>Löschen</p>
              </div>
                </>
              ):(
                <div style={{width: "90%", marginBottom: 20}}>
                <StandardButton title={t("share")} action={share}/>
              </div>
              )}
              
            </div>
          </div>
          
        </> 
        : 
        <div style={{height: "100%", width: "100%", display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'space-between'  }}>
         <StandartHeader title={t("addVideo")} />
          <img src={videoUploadImg} alt="add video" style={{width: 160, opacity: 1}}/>
          <div style={{ padding: '20px', width: "100%" }} >
            {videoMaxLengthWarning && 
              <p style={{color: "red", width: 200, textAlign: "center", margin: "0 auto 20px auto", lineHeight: 1.5}}>{t("uploadVideoMaxLength")}</p>
            }       
            <StandardButton action={() => inputRef.current.click()} title={t("selectVideo")} />
            <input style={{ opacity: 0, pointerEvents: 'none' }} ref={inputRef} onChange={submit} type="file" accept="video/mp4,video/x-m4v,video/*" />
            {/* {selectedVideo && <video ref={videoRef} autoPlay  src={URL.createObjectURL(selectedVideo)}></video>} */}
          </div>
        </div>}

        <ReactModal openModal={openModal} closeModal={closeModal} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
                <div style={{ textAlign: 'center' }} >

                    <p style={{ paddingBottom: '20px', fontWeight: '700', color: '#F75555' }} >Löschen</p>
                    <p style={{ fontWeight: '700', paddingBottom: '20px' }}>Bist du dir sicher, dass du das Video löschen willst?</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <LightButton onClick={closeModal} >{t("Cancel")}</LightButton>
                        <DarkButton onClick={deleteVideoConfirm} >
                            Ja, löschen
                        </DarkButton>

                    </div>
                </div>
            </ReactModal>
            <Toaster/>
      </div>
  )
}

export default AddVideo