import React, {useState, useEffect, useRef} from 'react'
import EmailImg from '../assets/emailConfirmation.svg'
import { t } from 'i18next'
import { ConfirmEmailText, EmailContainer, EmailContainerBottom, EmailContainerBottomSpan, EmailContainerBottomText, EmailContainerBottomTextContainer, EmailContainerTop } from './EmailConfirmation/Style'
import StandartButton from '../components/StandartButton';
import { functions } from '../firebase';
import { httpsCallable } from "firebase/functions";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Lottie, { useLottie } from "lottie-react";
import cicularConfetti from "../assets/circular-confetti.json";
import { getOwnUser } from '../sideEffects';
import StandartContainer from '../components/StandartContainer';
import { useLocation } from "react-router-dom";
import { setPaymentMethodAsDefault } from '../sideEffects';
import { customHistory } from '../CustomBrowserRouter';
import { navigateBack } from '../utils';
const AddPaymentMethodSuccess = () => {
    const navigate = useNavigate()
    //get user from redux with useSelector
    const user = useSelector(state => state.user.user)
    const [isPlaying, setIsPlaying] = useState(true);
    const [loading, setLoading] = useState(false);
    const [canProgress, setCanProgress] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const animationRef = useRef();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const setup_intent_id = searchParams.get('setup_intent');

  useEffect(() => {
    if (canProgress && buttonClicked) {
        setLoading(false)
        setButtonClicked(false)
        navigate("/")
    }
    getPaymentMethodId();
  }, [canProgress])

  async function getPaymentMethodId(){
    setPaymentMethodAsDefault(setup_intent_id)
}


    const handleComplete = () => {
        setIsPlaying(false);
      };

    function handleButtonAction() {
        // navigate(navigateBack())
        customHistory.go(navigateBack());
        // customHistory.replace("/coinshop")
        // if (!user) {
        //     navigate("/coinshop")
        // }
        // if (!canProgress) {
        //     setLoading(true)
        //     setButtonClicked(true)
        // } else {
        //     navigate("/coinshop")
        // }
    }

    return (
            <StandartContainer>
            <EmailContainerTop >
            <div style={{height: 300, width: "100%", position: "relative"}}>
            <div style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    display: isPlaying ? 'flex' : 'none',
                    justifyContent: "center",
                    alignItems: "center"
            }}>
                <Lottie
                        animationData={cicularConfetti}
                        loop={true}
                        autoplay={true}
                        lottieRef={animationRef}
                        onComplete={handleComplete}
                       // onEnterFrame={handleEnterFrame}
                    />
            </div>
                <div style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                </div>
                </div>

                <div>
                    <h1>{t('paymentmethodConfirmationCompleteText')}</h1>
                </div>      
            </EmailContainerTop>
            <div style={{position: "absolute", bottom: 30, left: 0, width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{width: "90%"}} >
                    <StandartButton  title="Fertig" loading={loading} action={ () => handleButtonAction() } />
                </div>
            </div>
            </StandartContainer>
    
    )
}

export default AddPaymentMethodSuccess