import React, { useState, useEffect } from 'react';
import { colors } from '../../theme/Colors';

const EmailTimer = ({ initialSeconds }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(timerId);
    }
  }, [seconds]);

  return (
    <div style={{color: colors.primary}}>
      {seconds > 0 ? (
        <>
            <p>Du kannst die E-Mail erneut senden in</p>
            <p>{seconds} Sekunden</p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmailTimer;
