import React from 'react'
import EmailImg from '../../assets/emailConfirmation.svg'
import { t } from 'i18next'
import { ConfirmEmailText, EmailContainer, EmailContainerBottom, EmailContainerBottomSpan, EmailContainerBottomText, EmailContainerBottomTextContainer, EmailContainerTop } from './Style'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { auth } from '../../firebase'
import { resendRegistrationMail, signOut } from '../../sideEffects'
import toast, { Toaster } from 'react-hot-toast'
import StandartContainer from '../../components/StandartContainer'
import StandartEmailInput from '../../components/StandartEmailInput'
import StandartButton from '../../components/StandartButton'
import { useState } from 'react'
import { ErrorMessage } from '../../components/StandartStyles'
import { submitEmailChange } from '../../sideEffects'
import EmailTimer from './EmailTimer';

const EmailConfirmation = () => {
    const navigate = useNavigate()
    //get user from redux with useSelector

    const [showEmailInput, setShowEmailInput] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const user = useSelector(state => state.user.user)
   // console.log("auth", auth.currentUser.email)
    const userEmail = auth.currentUser?.email
    console.log("userEmail", userEmail)

    function changeRegisterEmail(){
        setLoading(true)
        submitEmailChange(newEmail).then(() => {
            console.log("UPDATE ERFOLGREICH")
            resendRegistrationMail()
            setLoading(false)
            setShowEmailInput(false)
            setNewEmail('')
            navigate("/")
        }).catch((error) => {
            console.log(error)
            setLoading(false)
            setErrorMessage(t(error.code))
        })
    }

   


            function resendEmailClicked() {
                resendRegistrationMail()
                toast.success('E-Mail erneut gesendet',
                {
                    duration: 30000,
                    style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    },                }
                );
            }

            function changeEmailClicked() {
                changeRegisterEmail()
                toast.success('E-Mail an neue Adresse gesendet',
                {
                    duration: 30000,
                    style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    },                }
                );
            }
    
    return (
        <StandartContainer >
         
         <EmailContainerTop >
                <div>
                    <img src={EmailImg} />
                </div>

                <div>
                    <h1>{t('pleaseConfirmYourEmail')}</h1>
                </div>
                </EmailContainerTop>
                <div style={{ fontSize: '18px' }} >
                    <p style={{ fontWeight: '500' }} >
                        {t("emailSend")}
                    </p>
                    <p>
                        {userEmail}
                    </p>
                </div>
                <EmailTimer initialSeconds={10} />
                <p>{t("emailNotReceived")} <a style={{textDecoration: 'underline'}} onClick={()=>resendEmailClicked()}>{t("sendAgain")}</a> </p>
                <p>{t("wrongEmail")} <a style={{textDecoration: 'underline'}} onClick={()=>setShowEmailInput(true)}>{t("changeEmail")}</a> </p>
                {showEmailInput&&
                <>
                    <br/>
                    <StandartEmailInput handleChange={setNewEmail} value={newEmail}/>
                    <div>
                        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    </div>
                    <br/>
                    <StandartButton title={t("sendToNewEmail")} action={changeEmailClicked} loading={loading} />
                    <br/>
                </>}
           

            {/* <EmailContainerBottom>


                <EmailContainerBottomTextContainer >
                    <EmailContainerBottomText > {t('emailNotReceived')}</EmailContainerBottomText>
                    <EmailContainerBottomSpan onClick={()=>navigate('/search')} >
                        {t('sendAgain')}
                    </EmailContainerBottomSpan>
                </EmailContainerBottomTextContainer>

                <EmailContainerBottomTextContainer>
                    <EmailContainerBottomText> {t('wrongEmail')}</EmailContainerBottomText>
                    <EmailContainerBottomSpan>

                        {t('changeEmail')}
                    </EmailContainerBottomSpan>
                </EmailContainerBottomTextContainer>
            </EmailContainerBottom> */}
            <Toaster/>
        </StandartContainer>
    )
}

export default EmailConfirmation