import styled from "styled-components";

export const ShareVideoContainerHeader = styled.div`
padding: 20px;
display: flex;
align-items: center;
justify-content: space-between 
`
export const ShareVideoBottom = styled.div`
 padding: 20px;
 position: fixed;
 bottom: 10px;
 width: 90%
`

export const ShareVideoTopContainer = styled.div`
 display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-around;
  height: 100% 
`
export const VideoPlayer = styled.video`
 border-radius: 20px;
  height: 100%;
  width: 100px;
  object-fit: cover 
`
export const ShareVideoTopRightContainer = styled.div`
 margi-left: 10px;
  background-color: colors.lightAccent;
  padding: 15px;
  border-radius: 10px 
`