import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme/Colors'
import { LuPlus } from "react-icons/lu";

const MyFloatingButton = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: ${colors.primary};
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    background: rgb(84,58,204);
background: linear-gradient(184deg, ${colors.gradientStart} 0%, ${colors.gradientEnd} 100%);
    &:hover {
        transform: scale(1.1);
    };
    box-shadow: rgb(43 43 43 / 73%) 0px 2px 4px 0px;
   
`

function FloatingButton(props) {
    return (
        <MyFloatingButton onClick={props.action}>
            <LuPlus size={30} color={"white"} />
        </MyFloatingButton>
    )
}

export default FloatingButton
