import { configureStore, combineReducers } from '@reduxjs/toolkit'

import userReducer from './userSlice'
import creatorReducer from './creatorSlice'
import navigationReducer from './navigationSlice'

import {
    persistStore,
    persistReducer,
    createMigrate,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const migrations = {
    4: (state) => {
      // migration clear out device state
      return {
        ...state,
        user: {...state.user, boughtVideos: []}
      }
    },
    5: (state) => {
      // migration clear out device state
      return {
        ...state,
        user: {...state.user, subscribedVideos: []}
      }
    },
    6: (state) => {
      // migration clear out device state
      return {
        ...state,
        user: {...state.user, scrollCounter: []}
      }
    },
    7: (state) => {
      // migration clear out device state
      return {
        ...state,
        navigation: {...state.navigation, history: []}
      }
    },
    8: (state) => {
      // migration clear out device state
      return {
        ...state,
        user: {...state.user, currentVideos: []}
      }
    },
    9: (state) => {
      // migration clear out device state
      return {
        ...state,
        user: {...state.user, globalVideoStack: []}
      }
      }
  }

const persistConfig = {
    key: 'root',
    version: 9,
    storage,
    migrate: createMigrate(migrations, { debug: false }),

}

const rootReducer = combineReducers({ user: userReducer, creator: creatorReducer, navigation : navigationReducer });
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export default store
export const persistor = persistStore(store)