import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { MyContext } from '../NavigationCentral'
import { navigateBack } from '../utils'
import { useSelector } from 'react-redux'

function Back({color="black"}) {
    const navigate = useNavigate()
    const history = useSelector(state => state.navigation.history)
    return (
        <MyContext.Consumer>
            {(context) => {
    return (
      <div
        style={{
          position: "fixed",
          left: 20,
          top: 23,
          width: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          zIndex: 999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
       {/* <BsArrowLeft onClick={() => navigate((context[0] === "/paymentsetup" && context[1] === "/coinshop") ? -3 : -1)} size={25} color={color}/> */}
       <BsArrowLeft onClick={() => navigate(navigateBack())} size={25} color={color}/>

      </div>
    );
  }}
    </MyContext.Consumer>
    )
}

export default Back

