import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { MyContext } from '../NavigationCentral'
import { navigateBack } from '../utils'
import { useSelector } from 'react-redux'
import { TiHome } from "react-icons/ti";
import { colors } from '../theme/Colors'

export default function Home() {
    const navigate = useNavigate()
  return (
    <div
        style={{
          position: "fixed",
          marginTop:"50px",
          left: 16,
          top: 19,
          width: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          zIndex: 999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
       <TiHome  onClick={() => navigate("/")} size={25} color={colors.primary}/>

      </div>
  )
}
