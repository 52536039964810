import React, { useEffect, useState } from 'react'
import { SettingContainer, SettingContainerHeader, SettingContainerHeaderRight, SettingContainerTop, LinkStyle, LightButton, DarkButton } from './Style'
import { BsArrowLeft } from 'react-icons/bs'
import { t } from 'i18next'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { HiOutlineLockClosed } from 'react-icons/hi'
import { IoWalletSharp, IoSettingsSharp } from 'react-icons/io5'
import { FaFileInvoice } from 'react-icons/fa6'
import ReactModal from '../../components/ReactModal'
import { useDispatch } from 'react-redux'
import { removeUser } from '../../redux/userSlice'
import { useNavigate } from 'react-router-dom'
import { signOut } from '../../sideEffects'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { navigateBack } from '../../utils'
import { checkIfUserIsLogin } from '../../utils'
import StandartHeader from '../../components/StandartHeader'
const Settings = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // if(!checkIfUserIsLogin()){
    //     navigate("/register")
    // }
    
    const logout = () => {
        signOut()
        navigate('/')
    }

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>

          <StandartHeader title={t("settings")} />

            {/* <SettingContainer >
                <SettingContainerTop>
                    <HiOutlineLockClosed size={20} />
                    <p style={{ paddingLeft: '10px' }} >{t("Setting")}</p>
                </SettingContainerTop>
                <SettingContainerTop>
                    <AiOutlineInfoCircle size={20} />
                    <p style={{ paddingLeft: '10px' }}>{t("Saved")}</p>
                </SettingContainerTop>
            </SettingContainer> */}

            <div style={{ padding: 20, width: "100%" }}>

            {/* <LinkStyle onClick={()=>navigate('/selectPayment')} >{t("AddAccount")}</LinkStyle>
            <LinkStyle onClick={openModal}>{t("logoutUser")}</LinkStyle> */}

            <a onClick={(e) => navigate("/paymentmethods")} style={{marginBottom: 30, display: "block"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{backgroundColor: "#EDF3FF", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                        <IoWalletSharp color={"#3D7CFE"} size={22}/>
                    </div>
                    <h3 className="menuEntryText">{t("paymentMethods")}</h3>
                    </div>
                
                    <MdOutlineNavigateNext/>
                </div>
            </a>

            <a onClick={(e) => navigate("/change-account-information")} style={{marginBottom: 30, display: "block"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{backgroundColor: "#EDF3FF", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                        <IoSettingsSharp color={"#3D7CFE"} size={22}/>
                    </div>
                    <h3 className="menuEntryText">{t("changeAccountInformation")}</h3>
                    </div>
                
                    <MdOutlineNavigateNext/>
                </div>
            </a>

            <a onClick={(e) => navigate("/invoices")} style={{marginBottom: 30, display: "block"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{backgroundColor: "#EDF3FF", width: 60, height: 60, borderRadius: 30, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 30}}>
                        <FaFileInvoice color={"#3D7CFE"} size={22}/>
                    </div>
                    <h3 className="menuEntryText">{"Rechnungen"}</h3>
                    </div>
                
                    <MdOutlineNavigateNext/>
                </div>
            </a>

            <LinkStyle onClick={openModal} >{t("logout")}</LinkStyle>

            <ReactModal openModal={openModal} closeModal={closeModal} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
                <div style={{ textAlign: 'center' }} >

                    <p style={{ paddingBottom: '20px', fontWeight: '700', color: '#F75555' }} >{t("logout")}</p>
                    <p style={{ fontWeight: '700', paddingBottom: '20px' }}>{t("areYouShoureYouWantToLogout")}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <LightButton onClick={closeModal} >{t("Cancel")}</LightButton>
                        <DarkButton onClick={logout} >
                            {t("confirmLogout")}
                        </DarkButton>

                    </div>
                </div>
            </ReactModal>
            </div>
        </div>
    )
}

export default Settings