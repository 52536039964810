import React, {useState, useEffect, useRef} from 'react'
import EmailImg from '../../assets/emailConfirmation.svg'
import { t } from 'i18next'
import { ConfirmEmailText, EmailContainer, EmailContainerBottom, EmailContainerBottomSpan, EmailContainerBottomText, EmailContainerBottomTextContainer, EmailContainerTop } from './Style'
import StandartButton from '../../components/StandartButton';
import { functions } from '../../firebase';
import { httpsCallable } from "firebase/functions";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Lottie, { useLottie } from "lottie-react";
import cicularConfetti from "../../assets/circular-confetti.json";
import { getOwnUser } from '../../sideEffects';
import StandartContainer from '../../components/StandartContainer';

const EmailConfirmationComplete = () => {
    const navigate = useNavigate()
    //get user from redux with useSelector
    const user = useSelector(state => state.user.user)
    const [isPlaying, setIsPlaying] = useState(true);
    const [loading, setLoading] = useState(false);
    const [canProgress, setCanProgress] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const animationRef = useRef();
   
    let { uid } = useParams();

    useEffect(() => {
        async function activateEmail() {
            if (uid === undefined) {
                //navigate("/")
            } else {
                const verifyEmail = httpsCallable(functions, 'emailServices-verifyEmail');
        
                verifyEmail({uid: uid})
                .then(async (result) => {
                try {
                    //const user = result.user
                    await getOwnUser()
                    setCanProgress(true)
                } catch (error) {
                    console.log(error)
                }

                })
            }
        }
        activateEmail()
    }, [uid])

   // function loadOwnUser 

  useEffect(() => {
    if (canProgress && buttonClicked) {
        setLoading(false)
        setButtonClicked(false)
        navigate("/")
    }
  }, [canProgress])


    const handleComplete = () => {
        setIsPlaying(false);
      };

    //   const handleEnterFrame = (event) => {
    //     if (!animationRef.current) return
    //     const progress = (event.currentTime / event.totalTime) * 100;
    //     console.log("progress: ", progress, "%")
    //     if (progress >= 30) {
    //         console.log("no I have to pause")
    //         animationRef.current.pause();
    //     }
    //   };

    function handleButtonAction() {
        if (!user) {
            navigate("/login")
        }
        if (!canProgress) {
            setLoading(true)
            setButtonClicked(true)
        } else {
            navigate("/")
        }
    }

    return (
            <StandartContainer style={{zIndex:"9001"}}>
            <EmailContainerTop >
            <div style={{height: 300, width: "100%", position: "relative", zIndex:"9001"}}>
            <div style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    display: isPlaying ? 'flex' : 'none',
                    justifyContent: "center",
                    alignItems: "center"
            }}>
                <Lottie
                        animationData={cicularConfetti}
                        loop={true}
                        autoplay={true}
                        lottieRef={animationRef}
                        onComplete={handleComplete}
                       // onEnterFrame={handleEnterFrame}
                    />
            </div>
                <div style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src={EmailImg} />
                </div>
                </div>

                <div>
                    <h1>{t('emailConfirmationCompleteText')}</h1>
                </div>

                {/* <div style={{ fontSize: '18px' }} >
                    <p style={{ fontWeight: '500' }} >
                        {t("emailSend")}
                    </p>
                    <p>
                        {user.email}
                    </p>
                </div> */}
                
            </EmailContainerTop>

            {/* <EmailContainerBottom>


                <EmailContainerBottomTextContainer >
                    <EmailContainerBottomText > {t('emailNotReceived')}</EmailContainerBottomText>
                    <EmailContainerBottomSpan onClick={()=>navigate('/search')} >
                        {t('sendAgain')}
                    </EmailContainerBottomSpan>
                </EmailContainerBottomTextContainer>

                <EmailContainerBottomTextContainer>
                    <EmailContainerBottomText> {t('wrongEmail')}</EmailContainerBottomText>
                    <EmailContainerBottomSpan>

                        {t('changeEmail')}
                    </EmailContainerBottomSpan>
                </EmailContainerBottomTextContainer>
            </EmailContainerBottom> */}
            <div style={{position: "absolute", bottom: 30, left: 0, width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{width: "90%"}} >
                    <StandartButton  title={t("goToStartPage")} loading={loading} action={ () => handleButtonAction() } />
                </div>
            </div>
            </StandartContainer>
    
    )
}

export default EmailConfirmationComplete